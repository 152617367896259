import React from 'react';

class Odometre extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        data: {
          titre:'Récupération des données Odometre',
          donnees: []
        }
      }

      this.session = props.session
      this.fonctions = props.fonctions
  
      this.code_emetteur = props.code_emetteur;
      //alert(this.code_emetteur);
  
      this.donPage = {
        code_emetteur : this.code_emetteur,
        //dateDebut : '2023-10-10',
        //dateFin : '2023-11-30',
  
      }
  
      this.newOdometre = (e)=>{
        e.preventDefault();
        this.fonctions.myFetch({url: `/newOdometre/${this.code_emetteur}`, cibleElt: document.getElementById('root'), fctSuccess: (jsn)=>{
          this.refreshPage(this.state.data.numPage);
        }})
      }
  
      this.session.onOdometreChange =(don)=>{
        
        if(this.code_emetteur == don?.code_emetteur){
          //console.log('onOdometreChange -> ', don);
          this.state.data.dateAjour = don.obj.dateAjour;
          this.state.data.odoOne = don.obj.odoDon;
          //this.state.data.donnees[0] = don.obj.odoDon;
          this.setState({data: this.state.data});
        }
      };
  
  
      this.refreshPage = (numPage)=>{
        this.fonctions.refreshPageGlob('odometre', this, numPage);
      }
  
      
      this.gotoPage = (e)=>{
        //e.preventDefault()
        this.fonctions.gotoPageGlob(e, this);
      }

      this.toElt = ({don, data, actu=false, idx})=>{
        return <tr className={`${actu?"table-primary":""}`}>
              <th scope="row">{actu?("Actu"):((data.numPage-1)*data.donParPage+idx)}</th>
              <td>{this.fonctions.formatDateTime(new Date(don.date))}</td>
              <td>{don.duree}</td>
              <td>{don.carb}</td>
              <td>{don.km}</td>
              <td>{don.vit_m}</td>
              
            </tr>
      }
  
      this.refreshPage(1);
  
      
    }
  
    
  
    render(){
  
      const{data} = this.state
  
      console.log('data.odoOne -> ', data.odoOne);
      console.log('data.donnees[0] -> ', data.donnees[0]);
  
      return <>
      <div className="pagetitle mt-3 ms-3">
        <h5>
          <button onClick={this.newOdometre} type="button" className={`btn btn-icon btn-${data.odoOne?"primary":"secondary"} me-2`} disabled={!data.odoOne} >
            <span className="fa fa-plus"></span>
          </button>
          <span className='card-title'>{data.titre}</span>
          <span className="card-title mx-2">{data.dateAjour?this.fonctions.formatDateTime(new Date(data.dateAjour)):""}</span>
        </h5>
        
      </div>
      <table className="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">Durée</th>
        <th scope="col">Fuel</th>
        <th scope="col">km</th>
        <th scope="col">vitesse max</th>
      </tr>
    </thead>
    <tbody>
      { (function({toElt}) {
        //var totDonnees = [...data.donnees];
        var content = []
        if(data.odoOne) content.push(toElt({don: data.odoOne, data: data, actu:true, idx:0}));//totDonnees = [data.odoOne, ...totDonnees];
        
        var don
        for(var i=0; i<data.donnees.length; i++){
          don = data.donnees[i];
          content.push(toElt({don: don, data: data, actu:false, idx:(i+1)}))
        }
        return content
      }
      )({toElt: this.toElt})
      }
    
      
    </tbody>
  </table>
  <div className="d-flex justify-content-center">
   
      <nav aria-label="Page">
      {this.fonctions.pagination(data.numPage, data.nbrPage, this.gotoPage)}
      </nav>
    
    
    </div>
  </>
    }
  }

  export default Odometre