import React from 'react'

class EtatSpecial extends React.Component{
    constructor(props){
      super(props);
  
      this.state={
        etat_special: props.etat_special,
      }
  
      let etat_entries = Object.entries(this.state.etat_special);
      let etat_keys = Object.keys(this.state.etat_special);
      //console.log(Object.entries(this.state.etat_special));
      
      this.prevent = (e)=>{
        e.preventDefault();
      }
      
  
      //this.navRef.querySelectorAll('.sub-menu ul').forEach
      
    }
  
    structure({etat, niveau=0, indice_parent= 0}){
     
      let keys = Object.keys(etat);
      //let elt = [];
  
      let eltObj=[];
      let eltVal=[];
  
      let indice = 0;
      for(let key of keys){
        indice++;
        
        let val = etat[key];
        if(typeof(val) === "object"){
  
          eltObj.push(<li role="button" className="nav-item" onClick={(e)=>{
             
              let parent =  e.currentTarget.parentElement;
              let lesChilds = parent.children;
  
              for(let child of lesChilds){
                let fc =child.firstChild;
                let exp = fc.getAttribute('aria-expanded');
  
                if(exp != null){
                  let folder = fc.querySelector('i.icon_folder')
                  if(exp=="true"){
                    folder.classList.remove('bi-folder');
                    folder.classList.add('bi-folder2-open');
                  }else{
                    folder.classList.remove('bi-folder2-open');
                    folder.classList.add('bi-folder');
                  }
                }
                
                
                //console.log(fc.getAttribute('data-bs-target'), ' -> ', fc.getAttribute('aria-expanded'));
              }
              
              
              
              //console.log(fc.getAttribute('data-bs-target'), ' -> ', fc.getAttribute('aria-expanded')); /*['aria-expanded']*/
            }}>
              <span className="nav-link collapsed" data-bs-target={"#sidebar-nav-"+(niveau+1)+"-"+(indice)} data-bs-toggle="collapse">
                <i className="icon_folder bi bi-folder"></i><span>{key}</span><i className="bi bi-chevron-down ms-auto"></i>
              </span>
              <ul id={"sidebar-nav-"+(niveau+1)+"-"+(indice)} className="collapse sidebar-nav ms-3" data-bs-parent={"#sidebar-nav-"+(niveau)+"-"+(indice_parent)}>
                  {this.structure({etat: val, niveau: niveau+1, indice_parent: indice})
                  }
  
              </ul>
            </li>);
        }else{
  
          eltVal.push(
            <li role="button" className="nav-item">
              <span className="nav-link collapsed">
                <i className="bi bi-arrow-right"></i>
                <span>{key} : {val}</span>
              </span>
            </li>
            
                  
        );
        }
        
        
      }
  
      return [...eltObj, ...eltVal];
    }
  
    render(){
      const {etat_special} = this.state;
   
      return <>
  
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Etat Spécifique</h5>
  
          
          <ul className="sidebar-nav" id={"sidebar-nav-0-0"}>
            {this.structure({etat: etat_special})}
          </ul>
  
        </div>
      </div>
  
      </>
    }
  }

  export default EtatSpecial