import {useRef, useState} from 'react';

function NousContacter({fonctions, session}){
    //let userRef(null);
    let nom = session.user?.nom;
    let mail = session.user?.mail;
  
    let refNom = useRef(null);
    let refMail = useRef(null);
    let refSujet = useRef(null);
    let refMessage = useRef(null);
    
  
    let handlerEnvoyer = (e)=>{
      e.preventDefault();
      let jsnMsg = {
        nom: refNom?.current?.value,
        mail: refMail?.current?.value,
        sujet: refSujet?.current?.value,
        message: refMessage?.current?.value,
      }
      //console.log(jsnMsg);
      fonctions.myFetch({url:'/message', method:'POST', body:JSON.stringify(jsnMsg), cibleElt: document.getElementById('root'), fctSuccess: (val)=>{
        refNom.current.value = "";
          refMail.current.value = "";
          refSujet.current.value = "";
          refMessage.current.value = "";
      }})
    }
  
    return(
      <>
          <div className="pagetitle d-flex mt-3 ms-3 justify-content-center">
            <h5 className='card-title'>Nous Contacter</h5>
            
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-8">
              <div className="card p-4">
                <form action="nous-contacter" method="post" className="php-email-form">
                  <div className="row gy-4">
  
                    <div className="col-md-6">
                      <input type="text" name="name" className="form-control" placeholder="Nom" value={nom} ref={refNom} required />
                    </div>
  
                    <div className="col-md-6 ">
                      <input type="email" className="form-control" name="email" placeholder="Email" value={mail} ref={refMail} required />
                    </div>
  
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="subject" placeholder="Sujet" ref={refSujet} required />
                    </div>
  
                    <div className="col-md-12">
                      <textarea className="form-control" name="message" rows="6" placeholder="Message" ref={refMessage} required></textarea>
                    </div>
  
                    <div className="col-md-12 text-center">
                      <button className="btn btn-primary" type="submit" onClick={handlerEnvoyer}>Envoyer</button>
                    </div>
  
                  </div>
                </form>
              </div>
  
            </div>
          </div>
          
      </>
    )
  }

  export default NousContacter