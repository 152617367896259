import React from 'react';
import ReactDOM from 'react-dom/client';

import Odometre from "./odometre"
import Fuel from "./fuel"
import EtatSpecial from './etat_special';

class Moteur{
    constructor(){}
  
    couperAllumerMoteur(){
      alert("yes");
    }
}

class AsideNavEtat extends React.Component{
    constructor(props){
      super(props);

      this.session = props.session
      this.fonctions = props.fonctions
  
      this.moteur = new Moteur();
  
      this.alertCouperMoteurRoot = ReactDOM.createRoot(document.getElementById('alerte_couper_moteur'))
  
      this.reset(props);
      this.setApres(props);
   
      this.session.conxAsideNavEtat.reset = (props)=>{
      this.reset(props);
       this.setStateGlob(props);
  
  
      }
  
      this.odometreEvolution = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        document.body.click();
        
          this.fonctions.carteHide(()=>{this.fonctions.root.render(<Odometre session={this.session} fonctions={this.fonctions} code_emetteur={this.state.code_emetteur} />)})
        }
  
        this.fuelEvolution = (e)=>{
          e.preventDefault();
          e.stopPropagation();
          document.body.click();
  
          this.fonctions.carteHide(()=>{this.fonctions.root.render(<Fuel fonctions={this.fonctions} code_emetteur={this.state.code_emetteur} />);});
        }
  
        this.carteEvolution = (e)=>{
          e.preventDefault();
          e.stopPropagation();
          document.body.click();
  
          this.fonctions.App();
        }
  
        this.etat_specialVoir = (e)=>{
          e.preventDefault();
          e.stopPropagation();
          document.body.click();
  
          this.fonctions.carteHide(()=>(this.fonctions.root.render(<EtatSpecial etat_special={this.state.etat_special} />)))
        }
  
        
        this.moteur.couperAllumerMoteur = ()=>{
          this.fonctions.myFetch({url: `/couperAllumerMoteur/${this.state.code_emetteur/*session.code_emetteur_actuel*/}`, fctSuccess: (jsn)=>{
            console.log("requette de moteur marche aboutit");
          }, cibleElt: this.moteur_marcheRef});
  
        }
  
      }
  
      setApres(props){
        this.renderAlert();
      }
  
      reset(props){
        this.state={
          carburant_qte: props.etat.carburant_qte,
          vitesse_km_h: props.etat.vitesse_km_h,
          moteur_marche: props.etat.moteur_marche,
          etat_special: props.etat.etat_special,
  
          code_emetteur: props.code_emetteur,
        }
        
        //this.setApres(props);
        
      }
  
      setStateGlob(props){
        this.setState({
          carburant_qte: props.etat.carburant_qte,
          vitesse_km_h: props.etat.vitesse_km_h,
          moteur_marche: props.etat.moteur_marche,
          etat_special: props.etat.etat_special,
  
          code_emetteur: props.code_emetteur,
        })
  
        this.setApres(props);
  
        //console.log("change etat : ", this.state);
      }
  
      renderAlert(){
        this.alertCouperMoteurRoot.render(<div className="modal fade" id="couperMoteurModal" tabIndex="-1" aria-labelledby="couperMoteurModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          
          
          <div className="modal-content">
            <div className="alert alert-danger alert-dismissible fade show m-0 px-3" role="alert">
              Êtes-vous sûr de vouloir <strong>{this.state.moteur_marche?'couper':'allumer'} le moteur</strong> ?
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    
              <div className="mt-3 justify-content-end d-flex p-0 m-0">
                <button type="button" className="btn btn-danger mx-2" data-bs-dismiss="modal">Non</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e)=>{this.moteur.couperAllumerMoteur()}}>Confirmer</button>
              </div>
    
            </div>
  
          </div>
        
        </div>
      </div>)
      }
  
      
  
    render(){return <>{this.state.code_emetteur?<li id="carteBt" className="nav-item" onClick={this.carteEvolution} >
        <a href="#" className="nav-link collapsed">
          <i className="bi bi-geo-alt"></i><span>Carte</span>
        </a>
      </li>:<></>}
  
      {this.state.vitesse_km_h?<li className="nav-item" onClick={this.odometreEvolution}>
        <a href="#" className="nav-link collapsed">
        <i className="bi bi-speedometer"></i><span>{this.state.vitesse_km_h}km/h</span>
        </a>
      </li>:<></>}
      
  
      {this.state.moteur_marche!=null?<li ref={(ref)=>{this.moteur_marcheRef = ref}} className="nav-item" data-bs-toggle="modal" data-bs-target="#couperMoteurModal">
        <a href="#" className="nav-link collapsed">
          <i className="bi bi-key"></i><span>{this.state.moteur_marche?'Couper':'Allumer'} Moteur</span>
        </a>
      </li>:<></>}
      
      {this.state.carburant_qte!=null?<li id="fuelEvolutionBt" className="nav-item" onClick={this.fuelEvolution}>
        <a className="nav-link collapsed" href="#">
          <i className="bi bi-fuel-pump"></i><span>{this.state.carburant_qte}l</span><i></i><span className="badge bg-danger badge-number rounded-pill">3</span>
        </a>
        
        
      </li>:<></>}
  
      {this.state.etat_special?<li className="nav-item" onClick={this.etat_specialVoir}>
        <a href="#" className="nav-link collapsed">
        <i className="bi bi-display"></i><span>Etat</span>
        </a>
      </li>:<></>}
  
      
      </>;
  
    }
  }

  export {Moteur}
  export default AsideNavEtat