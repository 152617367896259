import { Overlay, Map, View, Feature } from 'ol';
import {Tile as TileLayer, Vector as ol_layer_Vector} from 'ol/layer'
import {OSM, Vector as ol_source_Vector} from 'ol/source'
import {fromLonLat, transform as ol_transform} from 'ol/proj'
//import {animation} from 'ol'
import {LineString, Circle} from 'ol/geom'
import {Style as ol_style_Style, Fill as ol_style_Fill, Stroke as ol_style_Stroke} from 'ol/style'

//import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
//import { render } from '@testing-library/react';
import {useRef, useState} from 'react';

import {io} from 'socket.io-client';

import faIconsObj from './json/font-awesome-v5.0.1.json';
import colorsObj from './json/500_couleurs.json';

import CreerCompte from './classes/creer_compte'
import AsideNavEtat from './classes/aside_nav_etat'
import NousContacter from './classes/nous_contacter'
//import Odometre from './classes/odometre'
//import Fuel from './classes/fuel'


let faIcons = faIconsObj.icons;
let couleurs = colorsObj.couleurs;

window.addEventListener("focus", (event)=>{
  session.windowFocus();
});

window.addEventListener("offline", (event) => {
  session.conServShow.internet();
});

window.addEventListener("online", (event) => {
  session.conServHide.internet();
});

window.addEventListener("DOMContentLoaded", (event)=>{
  if(navigator.onLine) session.conServHide.internet();
  else session.conServShow.internet();
});

window.addEventListener("beforeunload", (event)=>{
  try{
    localStorage.setItem("sizeIconMap", session.sizeIconMap);
  }catch(err){}
  
});

let alerte_message_paragraphe = ReactDOM.createRoot(document.getElementById('alerteMessageModalContentParagraphe'));//document.getElementById('alerteMessageModalContentParagraphe'); // = 
let alerteMessageModalButton = document.querySelector('#alerteMessageModalButton');
let alerteMessageTitre = document.getElementById('alerteMessageModalContentTitre');
function alertMessage({titre = "Système", message, boutons =[]}){
  let lesBoutons = [];
  for(let id in boutons){
    lesBoutons.push(<button type="button" className={`btn btn-${boutons[id].color??"primary"} ms-1`} data-bs-dismiss="modal" onClick={(boutons[id].fct)??((e)=>{})} >{boutons[id].titre}</button>);
  }
  alerte_message_paragraphe.render(<><div>{message}</div><div className={`${lesBoutons.length>0?"d-flex":"d-none"} justify-content-end p-0 mt-1`} >{lesBoutons}</div></>);
  //alerte_message_paragraphe.textContent = message;
  alerteMessageModalButton.click();

  alerteMessageTitre.textContent = titre;
}

//alertMessage(`Grand Monsieur`);


class Session{
  constructor(){
    this.init_0();
    this.init_1();

    this.onGpsChange = (don)=>{console.log('onGpsChange. just a console log');};
    this.onEtatChange = (don)=>{console.log('onEtatChange. just a console log');};
    this.onOdometreChange =(don)=>{console.log('onOdometreChange. just a console log');};
    this.onNewOdometreChange =(don)=>{console.log('onNewOdometreChange. just a console log');};
    this.onCarburantChange = (don)=>{console.log('onCarburantChange. just a console log');};

    this.stateOfCarteshow = {};
    this.onNotificationChange = [];
    this.onNotificationAdd = [];
    this.onMsgToUserChange = []; 
    this.vectorLineLayerTrace = {};

    this.pointsTraceGpsChange = {};

    this.conxAsideNavEtat = {
      reset(props){}
    }

    this.conxProfil = {
      reset(props){}
    }

    this.conxNotifications = {
      Notifications: (props)=>{},
      MsgToUsers: (props)=>{}
      
    }

    this.conxListeNotifications = {
      reset(props){}
    }
    this.spinner= {};
    this.spinner.elt_spin = (()=>{
      let elt_spin = document.createElement('div');
      elt_spin.className = "lds-spinner elt_spin";
      elt_spin.role = "status";
      for(let i=0; i<12; i++){
        elt_spin.appendChild(document.createElement('div'));
      }
      
      return elt_spin;
    })();
    
    this.spinner.elt = (()=>{
      let elt = document.createElement('div');
      //let rootElt = ReactDOM.createRoot(elt);
      
      //elt.style="background: #0004; border-radius:1em;  position: absolute; top:0px; width:100%; height:100%;";
      
      elt.className= "spinner-elt d-flex align-items-center justify-content-center"
      elt.appendChild(this.spinner.elt_spin);
      //rootElt.render(<div className="spinner-border text-primary" role="status"></div>);

      return elt;
    })();

    this.progress= {};
    
    this.progress.elt_prog_text = (()=>{
      let elt_prog_text = document.createElement('div');
      elt_prog_text.className= "elt_prog_text";
      return elt_prog_text;
    })();
    
    this.progress.elt_prog_child = (()=>{
      let elt_prog_child = document.createElement('div');
      elt_prog_child.className = "progress-bar progress-bar-striped progress-bar-animated";
      elt_prog_child.role = "progressbar";
      elt_prog_child.ariaValueMin="0";
      elt_prog_child.ariaValueMax ="100";

      return elt_prog_child;
    })();

    this.progress.elt_prog = (()=>{
      let elt_prog = document.createElement('div');
      elt_prog.className = "progress elt_prog";
      
      elt_prog.appendChild(this.progress.elt_prog_child);
      elt_prog.appendChild(this.progress.elt_prog_text);
      
      return elt_prog;
    })();

    
    
    this.progress.elt = (()=>{
      let elt = document.createElement('div');

      elt.className= "spinner-elt"
      elt.appendChild(this.progress.elt_prog);
      //elt.appendChild(this.progress.elt_prog_text);

      return elt;
    })();

    this.parametreCompte = ()=>{
      myFetch({url:'/parametre_compte', cibleElt: document.getElementById('root'), fctSuccess: (dataNet)=>{
        modifierCompteShow({dataCompte:dataNet.dataCompte});
      }});
    };

    this.windowFocus = ()=>{};

    this.conServ = document.getElementById("warningConnexionServeur");
    this.noteConServ = document.getElementById("note_wConnexionInternet");
    
    this.conServVar = {
      internet : false,
      socketIo : false,
      fetch : false,
      fctNote : (reason = null)=>{
        let vReason = reason?` : (${reason})`:"";
        let strNote = this.conServVar.internet?`Pas de connexion internet${vReason}`:
          this.conServVar.socketIo?`Socket : Impossible de se connecter au serveur${vReason}`:
          this.conServVar.fetch?`Echec lors de la connexion au serveur${vReason}`:null;

        if(strNote){
          this.noteConServ.innerText = strNote;
          this.conServ.classList.remove("d-none");
          this.conServ.classList.add("d-flex");
        }else{
          this.noteConServ.innerText = "";
          this.conServ.classList.remove("d-flex");
          this.conServ.classList.add("d-none");
        }
          

      },
    };

    this.conServHide = {
      
      internet : ()=>{
        this.conServVar.internet = false;
        this.conServVar.fetch = false;
        this.conServVar.fctNote();
      },
      socketIo : ()=>{
        //this.conServVar.internet = false;
        this.conServVar.fetch = false;
        this.conServVar.socketIo = false;
        this.conServVar.fctNote();
      },
      fetch : ()=>{
        //this.conServVar.internet = false;
        this.conServVar.fetch = false;
        this.conServVar.fctNote();
      }

    };
    
    this.conServShow={
      
      internet : ()=>{
        this.conServVar.internet = true;
        this.conServVar.fetch = false;
        this.conServVar.fctNote();
        
      },
      socketIo : (reason = null)=>{
        this.conServVar.socketIo = true;
        this.conServVar.fetch = false;
        this.conServVar.fctNote(reason);
        
      },
      fetch : ()=>{
        this.conServVar.fetch = true;
        this.conServVar.fctNote();
        
      }
      
    }

    this.bodyClickEvent = (e)=>{}
    this.bodyBaseClickEvent = (e)=>{}
  }

  init_0(){
    this.socket = null;
    this.connected = false;

    
    this.sizeIconMap = 28;
    try{
      let sizeIconMapTry = parseFloat(localStorage.getItem("sizeIconMap"));
      if(!isNaN(sizeIconMapTry)) this.sizeIconMap = sizeIconMapTry;
    }catch(err){}

    
    this.g_minute = 20; //groupement des minutes dans le playback 1h -> 20 tableaux de minutes

    this.eltFullScreen = document.getElementById("body-child");
    document.body.addEventListener('click', (e)=>{
      this.bodyClickEvent(e);
    })

    document.querySelector("#body-base").addEventListener("click", (e)=>{
      this.bodyBaseClickEvent(e);
    });

  }

  init_1(){
    this.token = "";
    this.user = {};

    this.code_emetteur_actuel = null;
    
    this.calendarGps={};
    this.playbackGps={};
    this.playbackGpsPointeur = [0, 0, 0];

  }

  init(){
    this.init_1();
    if(this.socket){
      this.socket.disconnect();
      this.connected = false;
    }
    
  }

  reconnect(){
    if(this.socket){
      console.log("reconnect()");
      this.socket.connect();
      this.connected = true;
    }else{
      this.connectionSocket();
      //updateNetPortAndConnect();
    }
    
  }

  setSpinner(cible){
    let bound = cible.getBoundingClientRect();
    let taille = Math.min(bound.width, bound.height);
    let scale = Math.floor(taille)/100;
    if(scale >2.5) scale = 2.5;

    this.spinner.elt_spin.style.transform = `scale(${scale})`;
    this.spinner.cible = cible;
    this.spinner.cible.classList.add("spinner-cible");
    cible.appendChild(this.spinner.elt);
  }

  removeSpinner(){
    this.spinner.cible.classList.remove("spinner-cible");
    let elt = this?.spinner?.elt;
    if(elt) if(elt.parentElement) elt.parentElement.removeChild(elt);
    
  }

  setProgress(cible){
    
    this.setProgressEtat({color: "primary"})
    this.setProgressValue(0);
    
    this.spinner.cible = cible;
    this.spinner.cible.classList.add("spinner-cible");
    cible.appendChild(this.progress.elt);
  }

  removeProgress(){
    this.spinner.cible.classList.remove("spinner-cible");
    let elt = this?.progress?.elt;
    if(elt) if(elt.parentElement) elt.parentElement.removeChild(elt);
  }

  setProgressValue(value){
    this.progress.elt_prog_child.ariaValueNow = `${value}`;
    this.progress.elt_prog_child.style.width = `${value}%`;
    this.progress.elt_prog_text.textContent = `${value}%`;
    this.setProgressEtat({text: `${value}%`});
  }

  setProgressEtat({text=null, color=null}){
    if(color != null){
      this.progress.elt_prog_child.classList.remove("bg-primary");
      this.progress.elt_prog_child.classList.remove("bg-danger");
      this.progress.elt_prog_child.classList.remove("bg-success");

      this.progress.elt_prog_child.classList.add("bg-"+color);
    }
    
    if(text != null) this.progress.elt_prog_text.textContent = `${text}`;

  }

  progressAddExitOnBodyBaseClick(){
    this.bodyBaseClickEvent = (e)=>{
      this.removeProgress();
      this.bodyBaseClickEvent = (e)=>{};
    };
  }

  changeToken(newToken){
    this.token = newToken;
    if(this.connected){
      if(this.token?.length>0) this.socket?.emit('give_token', this.token);
    }else{
      this.reconnect();
    }
  }

  connectionSocket(){
    console.log("dom:", domaine.host());
    let options = {rejectUnauthorized: false,
      transports: ["websocket"]};
    if(domaine.protocol === "https:"){
      //options.transports = ['xhr-polling', 'websocket', 'polling', 'htmlfile'];
      options.secure = true;
      options.reconnect = true;
    }
    this.socket = io.connect(domaine.host(), 
      options);
    this.connected = true;

    //teste
    this.socket.prependAny((nameEvent, ...args)=>{
      console.log(`prependAny(${nameEvent}) : ${JSON.stringify(args)}`);
    });

    this.socket.on('ask_give_token', (vide)=>{

      console.log("from server ask_give_token");
      if(this.token) if(this.token?.length>0) this.socket.emit('give_token', this.token);
      
    })

    this.socket.on('connect', ()=>{
      session.conServHide.socketIo();
      console.log('socket : connect');
    });

    this.socket.on('disconnect', (reason)=>{
      session.conServShow.socketIo(reason);
      //session.conServShow.socketIo();
      console.log('socket - disconnect : ', reason);
    })

    this.socket.on('connect_error', (err)=>{
      session.conServShow.socketIo(err);
      //session.conServShow.socketIo();
      console.log('socket - connect_error : ', err);
      console.log(err.req);      // the request object
      console.log(err.code);     // the error code, for example 1
      console.log(err.message);  // the error message, for example "Session ID unknown"
      console.log(err.context);
    })
  
    this.socket.on('change', (don)=>{
      switch(don.change){
        case 'gps':
          session.onGpsChange(don);
          break;
        
        case 'etat':
          //console.log('reception emit(etat) : ', don);
          session.onEtatChange(don);
          break;
        
        case 'odometre':
          session.onOdometreChange(don);
          break;

        case 'newOdometre':
          session.onNewOdometreChange(don);
          break;
        
        case 'carburant':
          session.onCarburantChange(don);
          break;

        case 'notification':
          for(let fct of session.onNotificationChange){
            fct(don);
          }
          break;

        case 'notificationAdd':
          for(let fct of session.onNotificationAdd){
            fct(don);
          }
          break;

          
        
        case 'msgToUser':
          console.log('case msgToUser -> ', don);
          for(let fct of session.onMsgToUserChange){
            fct(don);
          }
          break;
      }

      
      
    })
  };

  
}

let session = new Session();

let fonctions = {}





//var token = "";
//var user = {};
//var socket = null;
//var code_emetteur_actuel = null;

function bearerToken(){return `Bearer ${session.token??""}`}

function getNomMois(i){
  return (['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 
  'Septembre', 'Octobre', 'Novembre', 'Décembre'])[i];
}


function inFullScreen(elt){

  if(elt.requestFullscreen)
    try{return elt.requestFullscreen();}catch(err){}
  
  if(elt.mozRequestFullScreen)
    try{return elt.mozRequestFullScreen();}catch(err){}
  
  if(elt.webkitRequestFullScreen)
    try{return elt.webkitRequestFullScreen();}catch(err){}
    
  if(elt.msRequestFullScreen){
    try{return elt.msRequestFullScreen();}catch(err){}
  }
}

function outFullScreen(){
  //console.log("outFullScreen");

  if(document.exitFullscreen)
    try{return document.exitFullscreen();}catch(err){}
  
  if(document.mozCancelFullScreen)
    try{return document.mozCancelFullScreen();}catch(err){}
  
  if(document.webkitExitFullscreen)
    try{return document.webkitExitFullscreen();}catch(err){}
    
  if(document.msExitFullscreen){
    try{return document.msExitFullscreen();}catch(err){}
  }
}

function isOnFullScreen(){
  var full_screen_elt = document.fullscreenElement || 
    document.webkitFullscreenElement || 
    document.mozFullScreenElement || 
    document.msFullscreenElement || null;

    //console.log('full_screen_elt : ', full_screen_elt);

    if(full_screen_elt === null)
      return false;
    else
      return true;
}

function kinRdCoo(){
  let minLat = -4.322447, minLng = 15.297045;
  let maxLat = -4.303056, maxLng = 15.303333;

  let rd = [Math.random(), Math.random()];
  let lngLat = [rd[0]*(maxLng-minLng)+minLng, 
  rd[1]*(maxLat-minLat)+minLat];

  return lngLat;
}

function logoRdFa(){
  return faIcons[Math.floor(Math.random()*faIcons.length)];
}

function colorRd(){
  return couleurs[Math.floor(Math.random()*couleurs.length)];
}

function formatDateTime(date){
    //return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    return `${date.toLocaleString('fr-FR',{
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }

    )}`
}

fonctions.formatDateTime = formatDateTime;


function toFixedHHMMSS(sec){
    
  //const sec_num = parseInt(milli/1000)
  //const days = Math.floor(sec_num/24/3600)
  const hours = Math.floor(sec/3600)%24
  const minutes = Math.floor(sec/60)%60
  const seconds = sec%60

  return `${[hours, minutes, seconds]
      .map(v=> v<10?"0"+ v: v)
      //.filter((v,i)=> v !=="00" || i>0)
      .join(":")}`
}

function LogoFaIconCarte(props){
  
  return  <div className ={`${props.kCenter?"kCenter":""}`} style={{position: "relative"}} role="button"
            onClick={props.logo_exec_carte?(e)=>props.logo_exec_carte(props.id):(e)=>{e.stopPropagation();  }} >
            <div className={`inner_border ${props.circle?"rounded-circle":""}`} style={{opacity: 0, border: `solid 2px ${props.color}`}}></div>
            <span className={`btn btn-secondary p-1 p-md-2 p-lg-1 logo_create ${props.circle?"rounded-circle":""}`} 
              
              style={{position:"relative", fontSize:"1.8em", backgroundColor:props.color}} >
                <i style={{fontSize:"1.3em"}} className={`${props.circle?"d-block fas fa-location":props.faIcon} text-white`} ></i>
            </span>
          </div>;
}

class PointCarte{
  constructor(props){
    this.nom = props.nom;
    this.lngLat = props.lngLat;
    this.logo = props.logo;
    this.couleur = props.couleur;
    this.code_emetteur = props.code_emetteur;

    this.etat = {...props.etat};
    this.fantome = props.fantome?true:false;
  }
  getNom(){return this.nom}
  getLngLat(){return this.lngLat}
  getPoint(){
    if(this.lngLat[0] !=null && this.lngLat[1] != null){
      return fromLonLat(this.getLngLat());
    }else{
      return undefined;
    }
    
  }
  getLogo(){return this.logo}
  getCouleur(){return this.couleur}
  getCodeEmetteur(){return this.code_emetteur}
  getEtat(){return this.etat}
  getFantome(){return this.fantome;}

  setLngLat(lngLat){this.lngLat = lngLat;}
  setEtat(etat){this.etat = {...this.etat, ...etat};}
  setFantome(fantome){this.fantome = fantome;}
}

function removeAllChildNodes(parent){
  while(parent.firstChild){
    parent.removeChild(parent.firstChild)
  }
}


class KKey{
  static cle = 0;
  static newCle(){
    //console.log("KKey : ", KKey.cle)
    return KKey.cle++;
  }
}

let varIterThrow = {}

function testeIterThrow({id, taille, fct}){
  if(isNaN(varIterThrow[id])) varIterThrow[id] = 0;
  if(varIterThrow[id]<taille){
    fct();
    varIterThrow[id]++;
  }else{
    delete varIterThrow[id];
    throw new Error("Fin Du Teste Iteration Throw");
  } 
  
}

function alertFetchFail(jsn){
  if(jsn.msg != null){
    alertMessage({
      titre: "Serveur",
      message: jsn.msg, 
      //boutons :[{titre: "Ok", color:"primary" ,fct: (e)=>{}}]
    });
  }
   
}

fonctions.alertFetchFail = alertFetchFail;



function myFetch({url, body = null, method = 'GET', headers = {
  'Authorization': bearerToken(),
  'Content-Type': 'application/json;charset=utf-8',
  
}, timeout = 10_000, cibleElt=null, fctSuccess=null, fctFail=null, fctCatch=null}){
  //console.log('Authorization: ', bearerToken())
  
  if(cibleElt) session.setSpinner(cibleElt);
  //let conServ = document.getElementById("warningConnexionServeur");
  const controller = new AbortController();
  const idAbort = setTimeout(()=>controller.abort(), timeout);
  fetch(domaine.host()+url, {
      credentials: 'include',
      method: method, 
      headers: headers,
      body: body,
      signal: controller.signal,
      
    }
  
  ).then(rep=>{
    clearTimeout(idAbort);
    rep.json()
    .then(jsn=>{
      session.conServHide.fetch();

      if(cibleElt) session.removeSpinner();
      if(fctSuccess) {
        if(jsn.success) fctSuccess(jsn);
        else{
          if(fctFail) fctFail(jsn)
          else alertFetchFail(jsn);
          
        } 
      }
    })
  }).catch(err => {
    clearTimeout(idAbort);
    let btToggle = document.getElementById("connexionEchecModalButton");
    btToggle.click();
    if(cibleElt) session.removeSpinner();
    session.conServShow.fetch();

    if(fctCatch) fctCatch(err);
      
  });


  
}

fonctions.myFetch = myFetch;


const hostname = window.location.hostname;
const protocol = document.location.protocol;
const localport = protocol==="https:"?3002:3001;
var netport = null;

//var updateNetPortAndConnect = ()=>{session.connectionSocket();};

const domaineLocal = ()=> ({host: ()=>{return `${protocol}//${hostname}:${localport}`}, protocol});// [`http://${hostname}:3001`, `ws://${hostname}:3001`];
const domaineNet = ()=> ({host: ()=>{return `${protocol}//gps.sunreyz.com`}, protocol});

const domaine = domaineNet(); 
//const domaine = domaineLocal();
//console.log(domaine);

const root = ReactDOM.createRoot(document.getElementById('root'));
const profilRoot = ReactDOM.createRoot(document.getElementById('profil'));
const asideNavEtatRoot = ReactDOM.createRoot(document.getElementById('aside_nav_etat'));
const notificationsRoot = ReactDOM.createRoot(document.getElementById('notificationsRoot'));
const msgToUserRoot = ReactDOM.createRoot(document.getElementById('msgToUserRoot'));

fonctions.root = root;

session.connectionSocket();
//updateNetPortAndConnect();


//asideNavEtatRoot.render(<AsideNavEtat/>);
asideNavEtatRoot.render(<AsideNavEtat fonctions={fonctions} session={session} etat={{}} />);


class CarteShow extends React.Component{
  constructor(props){
    super(props)

    //let nbrOverlay = 10;
    this.state = {
      user: props.user,
      listePointOpt: [],
      listePointFiltre: [],
      idxPointSelected: 1,
      idxPointSelectedMoi: 0,
      fantomeActu: false,
      locatAllways : true,
      detail_arrow_deg : 0,
      pin_arrow_deg : 0,
      gps_menu_arrow_deg: 0,
      encreLocatBtOn: {source: false, cible: false},
      pinLocatBtEtat: {source: 0, cible: 0},
      pointFixeCarte : {},
      pinCoords : [undefined, undefined],
      locatAllwaysToMoi : false,
      //filtreSelectAllStateBtOn: true,
      //filtreSelectTraceAllStateBtOn: false,
      deleteEmetteurShowedBtOn: false,
      isItineraire: false,
    }
    
    /*
    this.stateFct = {};

    this.setStateFct = (funcs)=>{
      this.stateFct = {...this.stateFct, ...funcs};
    };
    */

    this.emetteurEtranger = props.emetteur_etranger;

    this.code_emetteur_initial = props.code_emetteur;
    //session.code_emetteur_actuel = props.code_emetteur;

    
    //this.btFiltreSelectAllState = false;
    //this.state.filtreSelectAllStateBtOn
    this.listePointCarte = [];

    this.mapMouseDownPrec = false;

    this.mapMouseUpTimeOut = null;

    this.mapMouseDown = (e)=>{
      this.mapMouseDownPrec = true;
    };
    
    this.mapMouseUp = (e)=>{
      if(this.mapMouseDownPrec){
        this.mapMouseDownPrec = false;
        if(this.mapMouseUpTimeOut) clearTimeout(this.mapMouseUpTimeOut);
        this.mapMouseUpTimeOut = setTimeout(()=>{
          if(!this.mapMouseDownPrec){
            try{
              this.centerPointCarteIfLocat();
              this.mapMouseDownPrec = false;
            }catch(err){}
          }
        }, 2000);
      }
      
    };

    this.locatAllwaysBt = (e)=>{
      e.stopPropagation();
      document.body.click();
      if(!this.state.locatAllways) this.centerPointCarte(400);
      this.setState({locatAllways: !this.state.locatAllways});
      
    }

    document.body.addEventListener('pointerup', this.mapMouseUp);
    
    /*
    this.exec_point_fixe_carte = (pointFixeCarte)=>{
      
    }
    */

    this.onGpsChangeInitFct = ({id, lngLat}) =>{
      let lePoint = this.listePointCarte[id];
      let pointAvant = lePoint.getPoint();
      lePoint.setLngLat(lngLat);
      let ov = this.leMap.getOverlays().item(id);
      ov.getElement().classList.add("animated");
      
      //ov.className='animation_move';
      ov.setPosition(lePoint.getPoint()); 

      if(this.state.listePointFiltre[id].playingTrace){

        if(session.pointsTraceGpsChange[id] == null){
          if(pointAvant != null) session.pointsTraceGpsChange[id] = [pointAvant];
          else session.pointsTraceGpsChange[id] = [];
        }
        session.pointsTraceGpsChange[id].push(lePoint.getPoint());
        //console.log({pointsTraceGpsChange: session.pointsTraceGpsChange})

        
        //if(session.vectorLineLayerTrace[id] ==null) session.vectorLineLayerTrace[id] = [];
        if(session.pointsTraceGpsChange[id].length>=1) {
          
          if(session.vectorLineLayerTrace[id] != null){
            var vectorLine = this.getVectorLine(session.pointsTraceGpsChange[id]);
            session.vectorLineLayerTrace[id].setSource(vectorLine);
          }else{
            this.traceOnMap({id, pointsTrace: session.pointsTraceGpsChange[id], showOnMap: this.state.listePointFiltre[id].activeTrace, color: lePoint.getCouleur()});
          }
        }
      }

      if(lePoint.getFantome()){
        lePoint.setFantome(false);
        
      }

      if(this.state.playback);
      else {
        if(this.state.pointFixeCarte.cible == null && id == this.state.idxPointSelected){//lePoint.getCodeEmetteur() == session.code_emetteur_actuel){
          this.setState({idxPointSelected: id});
        }
      }
      
      if(this.state.moiLocatBtOn){
        if(this.state.pointFixeCarte.source == null && id == this.state.idxPointSelectedMoi){
          this.setState({idxPointSelectedMoi: id});
        } 
      }
      /*
      console.log({
        gpsChangeCible: this.state.pointFixeCarte.cible,
        gpsChangeSource: this.state.pointFixeCarte.source,
        gpsChangeId : id,
        gpsChangeIdxPointSelected : this.state.idxPointSelected,
        gpsChangeIdxPointSelectedMoi : this.state.idxPointSelectedMoi,
      });
      */

      
    }

    this.onGpsChangeInit = (don)=>{
      for(let id_s in this.listePointCarte){
        let id = parseInt(id_s);
        let lePoint = this.listePointCarte[id];
        if(lePoint.getCodeEmetteur()==don.code_emetteur){
          this.onGpsChangeInitFct({id, 
            lngLat: [
              parseFloat(don.obj.long),
              parseFloat(don.obj.lat)
            ],
          });

          break;
        }
        
      }
    }

    session.onGpsChange = this.onGpsChangeInit;

    session.windowFocus = ()=>{
      try{
        this.centerPointCarteIfLocat();
      }catch(err){}
      
    };

    session.onEtatChange = (don)=>{

      var nbrAAjouter = this.emetteurEtranger?2:1;
      

      console.log('onEtatChange : ', don);

      for(let id in this.listePointCarte){
        if(this.listePointCarte[id].getCodeEmetteur()==don.code_emetteur){
          let lePoint = this.listePointCarte[id];
          

          lePoint.setEtat(don.obj);

          console.log('changer etat : ', lePoint);

          console.log(lePoint.getCodeEmetteur(), ' == ', session.code_emetteur_actuel, ' : ', lePoint.getCodeEmetteur() == session.code_emetteur_actuel);

          if(lePoint.getCodeEmetteur() == session.code_emetteur_actuel){
            console.log('changer affichage : ', lePoint);

            session.conxAsideNavEtat.reset({
              etat: lePoint.getEtat(),
              code_emetteur: lePoint.getCodeEmetteur()
            });
          }
          

          
          //let ov = this.leMap.getOverlays().item(id);
          
          //ov.className='animation_move';
          //ov.setPosition(this.listePointCarte[id].getPoint())
          
          if((--nbrAAjouter)<=0) break;
        }
        
      }
    }
  
    this.osm = new TileLayer({
      source: new OSM()
    });
    
    this.leMap = null;

    this.logo_exec_carte_fct = (idNum)=>{
      if(this.state.locatAllwaysToMoi){
        this.setState({
          idxPointSelectedMoi: idNum,
          force_idxPointSelectedMoi: true,
          //collimCibleBtOn: false,
        })
      }else{
        this.setState({
          idxPointSelected: idNum,
          force_idxPointSelected: true,
        });
      }
      
    }

    this.logo_exec_carte= (id)=>{
      let idNum = parseInt(id.substring(11))
      this.logo_exec_carte_fct(idNum);
    }

    this.jsonFctInitState = {};

    this.initialState = (e)=>{
      e.stopPropagation();
      document.body.click();
      
      for(let key in this.jsonFctInitState){
        this.jsonFctInitState[key]();
      }
    }

    this.showDeleteButtons = (e)=>{
      e.preventDefault();
      e.stopPropagation();
       
      if(this.state.deleteEmetteurShowedBtOn){
      
        this.jsonFctInitState.showDeleteButtons = ()=>{
          
        }
      }else{
        
        this.jsonFctInitState.showDeleteButtons = ()=>{
          this.showDeleteButtons(e);
        }
      }
      
      
      //this.btDeleteEmetteurShowed = !this.btDeleteEmetteurShowed;
      /*
      for(let id in this.state.listePointFiltre){
        if(this.listePointCarte[id].getCodeEmetteur() != null) this.state.listePointFiltre[id].btDel = this.btDeleteEmetteurShowed;
      }
      */
      this.superSetState({deleteEmetteurShowedBtOn: !this.state.deleteEmetteurShowedBtOn});
    }

    this.selectPointOpt = (e)=>{
      e.preventDefault()
      let tg = e.target;
      let idx = parseInt(tg.options[tg.selectedIndex].value);
      this.setState({idxPointSelected: idx});
      if(this.state.playback){
        this.playbackBtClickRetour(null);
        this.playbackBtClickFct(idx);
      }
      
    }
    this.selectPointOptMoiLocat = (e)=>{
      e.preventDefault()
      let tg = e.target;
      this.setState({idxPointSelectedMoi: parseInt(tg.options[tg.selectedIndex].value)});
    } 

    this.getValueByEvent = (e)=>{
      e.preventDefault();
      e.stopPropagation();

      var tg = e.target;

      while(!tg.value){
        tg = tg.parentElement;
      }

      let id = parseInt(tg.value);

      return {tg, id};
    }

    this.deleteEmetteur = (e) =>{
      let {tg, id} = this.getValueByEvent(e);

      this.deleteEmetteurId(id);
    }

    this.playStopTraceEmetteurNoSet = ({e, id=null, playingTrace=true, stateBefore = true})=>{
      var idIci = id;
      if(e != null) {
        let {id: id_v} = this.getValueByEvent(e);
        idIci = id_v
      }

      let oldPlayingTraceIci = this.state.listePointFiltre[idIci].playingTrace;
      let playingTraceIci = stateBefore?!oldPlayingTraceIci: playingTrace;

      this.state.listePointFiltre[idIci].playingTrace = playingTraceIci;
      if(playingTraceIci){
        if(!oldPlayingTraceIci) this.removeTraceOnMap({id: idIci, deleteVector: true, setTraceBtOn:false});
        this.activeTrace({id: idIci, state: true});//this.state.listePointFiltre[idIci].activeTrace = true;
        this.state.listePointFiltre[idIci].closedTrace = false;
      }else{
        delete session.pointsTraceGpsChange[idIci];
      }
    }

    this.playStopTraceEmetteur = (e)=>{
      this.playStopTraceEmetteurNoSet({e});
      this.superSetState({listePointFiltre: this.state.listePointFiltre});
    }

    this.activeTrace = ({id, state})=>{
      let oldState = this.state.listePointFiltre[id].activeTrace;
      this.state.listePointFiltre[id].activeTrace = state;

      if(session.vectorLineLayerTrace[id] !=null){
        if(state && !oldState) this.showTraceOnMap({id, setTraceBtOn:false})
        else if(!state){
          this.removeTraceOnMap({id, deleteVector:false, setTraceBtOn:false});
          //delete session.pointsTraceGpsChange[id];
        } 
      }

      
    }

    this.closeTraceEmetteurNoSet = ({e, id = null, activeTraceState = true})=>{
      var idIci = id;
      if(e != null) {
        let {id: id_v} = this.getValueByEvent(e);
        idIci = id_v
      }
      //let closedTrace = this.state.listePointFiltre[id].closedTrace;
      this.removeTraceOnMap({id: idIci, deleteVector: true, setTraceBtOn:false});
      delete session.pointsTraceGpsChange[idIci];

      this.state.listePointFiltre[idIci].closedTrace = true;
      this.activeTrace({id: idIci, state: activeTraceState});
      this.state.listePointFiltre[idIci].playingTrace = false;
    }

    this.closeTraceEmetteur = (e, id =null)=>{

      this.closeTraceEmetteurNoSet({e, id, activeTraceState: false});
      this.superSetState({listePointFiltre: this.state.listePointFiltre});

      //this.checkAllSelectFilterTraceIf(false);
    }

    this.closeTraceEmetteurAll = (e)=>{
      
      for(let id in this.state.listePointFiltre){
        this.closeTraceEmetteurNoSet({e:null, id, activeTraceState: false});
      }
      this.superSetState({listePointFiltre: this.state.listePointFiltre});

      //this.checkAllSelectFilterTraceIf(false);

    }

    this.playTraceEmetteurAllSelected = (e)=>{

      for(let id in this.state.listePointFiltre){
        if(this.state.listePointFiltre[id].activeTrace) this.playStopTraceEmetteurNoSet({e:null, id, playingTrace:true, stateBefore:false});
      }
      this.superSetState({listePointFiltre: this.state.listePointFiltre});
    }

    this.playTraceEmetteurAll = (e)=>{

      for(let id in this.state.listePointFiltre){
        this.playStopTraceEmetteurNoSet({e:null, id, playingTrace:true, stateBefore:false});
      }
      this.superSetState({listePointFiltre: this.state.listePointFiltre});

      //alert("playTraceEmetteurAll");
      //this.checkAllSelectFilterTraceIf(true);
    }

    this.stopTraceEmetteurAll = (e)=>{
      for(let id in this.state.listePointFiltre){
        this.playStopTraceEmetteurNoSet({e:null, id, playingTrace:false, stateBefore:false});
      }
      this.superSetState({listePointFiltre: this.state.listePointFiltre});
    }

    
    
   //this.state.filtreSelectTraceAllStateBtOn

    this.getFctCheckAllSelectFilter = ({
      nameFiltre = null,
      nameActive = "activeTrace", 
      //nameStateAll = "filtreSelectTraceAllStateBtOn"
    })=>{
      
      let fctCheck = nameFiltre == null?
        (id)=> !this.state.listePointFiltre[id][nameActive]
        :(id)=>this.state.listePointFiltre[id][nameFiltre] && !this.state.listePointFiltre[id][nameActive]
      

      return ()=>{

        let checkAll = true;
        for(let id in this.state.listePointFiltre){
          if(fctCheck(id)){
            let exec = true;
            if(id == 0) if(!this.state.locatAllwaysToMoi) exec = false;
            if(exec) {
              checkAll = false;
              break;
            }
          }
        }

        //let stateAll = {};
        //stateAll[nameStateAll] = checkAll;

        //console.log(this.state.listePointFiltre);
        //this.superSetState(stateAll);
        return checkAll;
      
      }
    }

    /*
    this.getFctCheckAllSelectFilterIf = ({
        nameStateAll = "filtreSelectTraceAllStateBtOn",
        fctCheckAllSelectFilter = this.checkAllSelectFilterTrace,
      })=>{

      return (addedOrRemoved)=>{
        if(addedOrRemoved){
          if(!this.state[nameStateAll]){
            fctCheckAllSelectFilter();
            //alert("In getFctCheckAllSelectFilterIf -> added and !this.state[nameStateAll]")
          }
           
        }else{
          if(this.state[nameStateAll]){
            let stateAll = {};
            stateAll[nameStateAll] = false;
            this.superSetState(stateAll);
          }
            
        }
      }
    }
    */

    this.checkAllSelectFilter = this.getFctCheckAllSelectFilter({
      nameActive: "active", 
      //nameStateAll: "filtreSelectAllStateBtOn",
    });  

    /*
    this.checkAllSelectFilterIf = this.getFctCheckAllSelectFilterIf({
      //nameStateAll: "filtreSelectAllStateBtOn",
      fctCheckAllSelectFilter: this.checkAllSelectFilter,
    });
    */

    this.selectFilterCM = (e)=>{
      
      var tg = e.target;
      while(!tg.value){
        tg = tg.parentElement;
      }
      
      let id = parseInt(tg.value);

      if(tg.classList.contains("active")){
        this.filtreSelectRemove(id);
        //this.checkAllSelectFilterIf(false);
        
      }else{
        this.filtreSelectAdd(id);
        //this.checkAllSelectFilterIf(true);
      }

      //this.superSetState({filtreSelectAllStateBtOn: false});

    }

    this.checkAllSelectFilterTrace = this.getFctCheckAllSelectFilter({
      nameFiltre: "active",
      nameActive: "activeTrace", 
      //nameStateAll: "filtreSelectTraceAllStateBtOn",
    });  

    /*
    this.checkAllSelectFilterTraceIf = this.getFctCheckAllSelectFilterIf({
      nameStateAll: "filtreSelectTraceAllStateBtOn",
      fctCheckAllSelectFilter: this.checkAllSelectFilterTrace,
    });
    */

    this.selectFilterTrace = (e)=>{
      var tg = e.target;
      while(!tg.value){
        tg = tg.parentElement;
      }
      
      let id = parseInt(tg.value);

      if(tg.classList.contains("active")){
        this.filtreSelectTraceRemove(id)
        //this.checkAllSelectFilterTraceIf(false);
        
      }else{
        this.filtreSelectTraceAdd(id);
        //this.checkAllSelectFilterTraceIf(true);
      }

      //this.superSetState({filtreSelectTraceAllStateBtOn: false});
    }

    this.filtreSelectAll = (e)=>{
      e.preventDefault();
      var tg = e.target;
      while(!tg.classList.contains("kbtn-selectAll")){
        tg = tg.parentElement;
      }

      if(tg.classList.contains("coche")){
        for(let id in this.state.listePointFiltre){
          this.filtreSelectRemove(id);
        }
      }else{
        for(let id in this.state.listePointFiltre){
          this.filtreSelectAdd(id);
        }
      }

      /*
      if(this.state.filtreSelectAllStateBtOn){
        this.superSetState({filtreSelectAllStateBtOn: false});
        //tg.classList.remove("btn-primary");
        //tg.classList.add("btn-outline-primary");

        for(let id in this.state.listePointFiltre){
          this.filtreSelectRemove(id);
        }
      }else{
        this.superSetState({filtreSelectAllStateBtOn: true});
        //tg.classList.remove("btn-outline-primary");
        //tg.classList.add("btn-primary");

        for(let id in this.state.listePointFiltre){
          this.filtreSelectAdd(id);
        }
      }

      */
      //this.btFiltreSelectAllState = !this.btFiltreSelectAllState;
      //let liste = ;
      
      
    }

    this.filtreSelectTraceAll = (e)=>{
      e.preventDefault();
      var tg = e.target;
      while(!tg.classList.contains("kbtn-selectAll")){
        tg = tg.parentElement;
      }

      if(tg.classList.contains("coche")){
        for(let id in this.state.listePointFiltre){
          this.filtreSelectTraceRemove(id);
        }
      }else{
        for(let id in this.state.listePointFiltre){
          this.filtreSelectTraceAdd(id);
        }
      }
/*
      if(this.state.filtreSelectTraceAllStateBtOn){
        this.superSetState({filtreSelectTraceAllStateBtOn: false});
 
        for(let id in this.state.listePointFiltre){
          this.filtreSelectTraceRemove(id);
        }
      }else{
        this.superSetState({filtreSelectTraceAllStateBtOn: true});
        
        for(let id in this.state.listePointFiltre){
          this.filtreSelectTraceAdd(id);
        }
      }
*/
      
    }


    
    this.resizeIcons = (e)=>{
      e.preventDefault();
      let tg = e.target
      this.resizeIconsFct(tg.value);
      //console.log("value : ", tg.value/50)
    }

    this.playingId = null;

    this.stopPlayback = ()=>{
      this.pausePlaybackBoth();

      this.superSetState({playback:{
        ...this.state.playback, time:0,
      }})
    }

    this.stopPlaybackEvent = (e)=>{
      e.preventDefault();
      this.stopPlayback();
    }
    
    this.playbackRangeChangeInit = (e)=>{
      e.preventDefault();
      this.pausePlaybackBoth();
      let time = e.target.value*10;

      this.playbackGpsOnCarte({t0:time});
      this.superSetState({playback:{
        ...this.state.playback, time:time
      }})
      
    }

    this.playbackRangeChange = (e)=>{
      this.playbackRangeChangeInit(e);
    }

    this.pausePlayback = (positif = true)=>{
      //console.log('stop playback');
      if(positif){
        this.superSetState({onPlayingPositif : false});
      }else{
        this.superSetState({onPlayingNegatif : false});
      }

      if(this.playingId){
        clearInterval(this.playingId);
        this.playingId = null;
      }
    }

    this.pausePlaybackBoth = ()=>{
      this.pausePlayback(false);
      this.pausePlayback(true);
    }

    this.playPlayback = (positif= true)=>{
      //console.log('play playback');
      if(positif){
        this.superSetState({onPlayingPositif : true});
        this.pausePlayback(false);
      }else{
        this.superSetState({onPlayingNegatif : true});
        this.pausePlayback(true);
      }
      

      this.playingId = setInterval(()=>{
        let dt = (positif?1:-1)*this.select_vitesse_ref.value/10;
        this.playbackGpsOnCarte({t0:null ,dt: dt});

        let time = this.state.playback.time+dt;
        if(time<0){
          time=0;
          this.pausePlaybackBoth();
        }else if(time>86399){
          time=86399;
          this.pausePlaybackBoth();
        }
        //let valueRange = time
        this.superSetState({playback:{
          ...this.state.playback, time: time
        }});
        //this.playbackRange_ref.value = this.state.playback.time;
      }, 100)
    }

    

    this.playbackPlayOrPausePositif = (e)=>{
      if(e) e.preventDefault();
      if(this.state.onPlayingPositif){
        this.pausePlayback(true);
      }else{
        this.playPlayback(true);
      }
    }

    this.playbackPlayOrPauseNegatif = (e)=>{
      if(e) e.preventDefault();
      if(this.state.onPlayingNegatif){
        this.pausePlayback(false);
      }else{
        this.playPlayback(false);
      }
    }

    this.calendarSetDateEncours = (laDate)=>{
      this.calendarEtatDesJoursAnticipe({annee: laDate.getFullYear(), mois: laDate.getMonth()});

      this.superSetState({calendar:{
        ...this.state.calendar,
        dateEncours:laDate,
        annee: laDate.getFullYear(),
        mois: laDate.getMonth(),
      }});
    }

    this.calendarMoisPrecedentExistePas = ()=>{
      let mois=this.state?.calendar?.mois;
      let annee=this.state?.calendar?.annee;

      let date = session.calendarGps.date_min; 
      if(!date) return false;
      if(annee > date.getFullYear()){
        return false;
      }else if(annee == date.getFullYear()){
        if(mois>date.getMonth()){
          return false;
        }else{
          return true;
        }
      }else{
        return true;
      }

          
    }

    this.oldEltAnimeLogo = {};

    this.calendarMoisProchainExistePas = ()=>{
      let mois=this.state?.calendar?.mois;
      let annee=this.state?.calendar?.annee;

      let date = new Date(); 
      if(annee < date.getFullYear()){
        return false;
      }else if(annee == date.getFullYear()){
        if(mois<date.getMonth()){
          return false;
        }else{
          return true;
        }
      }else{
        return true;
      }

          
    }

    
    this.calendarSelectJourClick = {
      moisDiffFct : (e, moisDiff=0)=>{
        let jour = e.target.innerText;
        let calDate = this.state.calendar.dateEncours;
        let mois = calDate.getMonth();
        let annee = calDate.getFullYear();
        
        this.calendarSetDateEncours(new Date(annee, mois+moisDiff, jour));

      },
      moisEncours : (e)=>{
        this.calendarSelectJourClick.moisDiffFct(e);
      },
      moisPrecedent: (e)=>{
        this.calendarSelectJourClick.moisDiffFct(e, -1);
        //this.calendarSelectJourClick.moisEncours(e);
      },
      moisSuivant: (e)=>{
        this.calendarSelectJourClick.moisDiffFct(e, +1);
      },

    };

    this.calendarAjoutMois = (nbr)=>{
      let calendar = this.state.calendar;
      if(calendar){
        let nouvelleDate = new Date(calendar.annee, calendar.mois+nbr);
        this.calendarSetDateEncours(nouvelleDate);
      }
    }
    
    this.fetchCalendarEtatJours = ({code_emetteur, annee, decalage, fctAfter = null})=>{
      //console.log(code_emetteur, ",annee", " -> ", session.calendarGps[code_emetteur]??{}[annee]);
      if((session.calendarGps[code_emetteur]??{})[annee]){if(fctAfter){fctAfter();}}else myFetch({url: `/calendarGps/${code_emetteur}/${annee}/${decalage}`, fctSuccess: jsn=>{
        if(!session.calendarGps[code_emetteur]) session.calendarGps[code_emetteur] = {};
        session.calendarGps[code_emetteur][annee] = jsn.jsnDate;
        session.calendarGps.date_min = new Date(jsn.date_min);
        if(fctAfter) fctAfter();
        //console.log("C -> ", session.calendarGps);
      }, fctCatch: (err)=>{this.playbackBtClickRetour();}, cibleElt: fctAfter?document.getElementById('root'):null,});
    }

    this.calendarEtatDesJoursAnticipe = ({annee, mois, fctAfter = null})=>{
      let dateNow = new Date(annee, mois);
      let decalage = Math.round(dateNow.getTimezoneOffset()/60);
      //let lePointCarte = this.listePointCarte[this.state.idxPointSelected];
      let code_emetteur = session.code_emetteur_actuel;

      this.fetchCalendarEtatJours({annee: annee, code_emetteur: code_emetteur, decalage: decalage, fctAfter: fctAfter});
      if(mois<6){
        this.fetchCalendarEtatJours({annee: annee-1, code_emetteur: code_emetteur, decalage: decalage});
      }
      
      
    }
/*
    this.traceClick = (e)=>{
      if(this.state.playback) this.tracePlaybackClick(e);
      else this.traceNormalClick(e);
    }

    this.traceNormalClick = (e)=>{
      if(this.state.playbackTraceBtOn){
        this.removeTraceOnMap({id:'playback', inTab:true});
      }else{
        this.superSetState({playbackTraceBtOn: true});
      }
    }
    */
    this.tracePlaybackClick = (e)=>{
      if(this.state.playbackTraceBtOn){
        this.removeTraceOnMap({inTab: true, id: 'playback', deleteVector: false});
      }
      if(!this.state.playbackTraceBtOn && session.vectorLineLayerTrace['playback']!=null){
        this.showTraceOnMap({inTab: true, id: 'playback'});
        console.log({vectorLineLayerTrace: session.vectorLineLayerTrace})
      
      }
    }

    this.moiLocatClick = (e)=>{
      //let agmBt = document.getElementById("arrow-gps-menu-bt");
      //let cbMap = document.getElementById("contBoardMap");
      
      if(this.state.moiLocatBtOn){
        this.setState({
          moiLocatBtOn: false,
          locatAllwaysToMoi: false,
        });

        //if(this.state.gps_menu_arrow_deg==0) agmBt.click();
      }else{
        this.init_pin_arrow_deg();
        this.setState({
          moiLocatBtOn: true, 
          locatAllwaysToMoi: true,
        });
        
        
        //if(this.state.gps_menu_arrow_deg==180) agmBt.click();
      }
    }

    this.colorBootFromPinState = ({leQuel})=>{
      let state = this.state.pinLocatBtEtat[leQuel]
      switch(state){
        case 0 : return "secondary";
        case 1 : return "success border border-white ";
        case 2 : return "primary border border-white ";
        default : return "secondary";
      }
    }

    this.layerPointFixeCarte = {source: null, cible: null};
    
    this.fct_ol_style_pointFixeCarte = ({leQuel, width = 1, weight = 5}) => {
      let color = {source: "#008FFD", cible:"#14A44D"};

      return new ol_style_Style({
        fill: new ol_style_Fill({ color: (color[leQuel]??"#000000")+"88", weight}),
        stroke: new ol_style_Stroke({ color: color[leQuel]??"#000000", width, lineCap: "butt" })
      });
    }
    
    this.drawPointFixeCarte = ({leQuel, pointFixeCarte, refresh = false, actuZoom = this.leMap.getView().getZoom()})=>{
      
      if(this.layerPointFixeCarte[leQuel] !=null){
        //console.log(leQuel, "Removed layerPointFixeCarte");
        //test = false;
        if(!refresh){
          this.leMap.removeLayer(this.layerPointFixeCarte[leQuel]);
          this.layerPointFixeCarte[leQuel] =null;
        } 
      }
        
      
      if(pointFixeCarte[leQuel] !=null){
        
        var feature = new Feature(new Circle(pointFixeCarte[leQuel], this.widthVectorFromZoom({actuZ: 18+(18-actuZoom), x: [[0.5, 0], [0.00000000000000002, 14]] })));
        
        var sourceOl = new ol_source_Vector({
          features: [feature]
        });
  
        
        
        
        
        if(refresh){
          this.layerPointFixeCarte[leQuel].setSource(sourceOl);
        }else {
          this.layerPointFixeCarte[leQuel] = new ol_layer_Vector({
            source: sourceOl,
            style: this.fct_ol_style_pointFixeCarte({leQuel}),
          });

          this.leMap.addLayer(this.layerPointFixeCarte[leQuel]);
        }
        
      }
      
    }

    this.locatGlobBeforeState =({leQuel,
      encreState, pinState, 
      setPointFixeCarte = false, pointFixe = null, stateInit = {}})=>{
        
        let stateInitIci = {
          encreLocatBtOn: stateInit.encreLocatBtOn??{...this.state.encreLocatBtOn},
          pinLocatBtEtat: stateInit.pinLocatBtEtat??{...this.state.pinLocatBtEtat},
          pointFixeCarte: stateInit.pointFixeCarte??{...this.state.pointFixeCarte}
        }

      let {encreLocatBtOn, pinLocatBtEtat, pointFixeCarte} = stateInitIci;
      
      console.log({leQuel, pinState});

      let pinStateAvant = pinLocatBtEtat[leQuel];

      encreLocatBtOn[leQuel] = encreState;
      pinLocatBtEtat[leQuel] = pinState;
      if(pinState ==1){
        for(let leQuelAutre in pinLocatBtEtat){
          if(leQuelAutre != leQuel && pinLocatBtEtat[leQuelAutre]==1) pinLocatBtEtat[leQuelAutre]=0;
        }
      }

      let stateChange = {
        encreLocatBtOn,
        pinLocatBtEtat,
      };
      

      let idxPoint = leQuel=='source'?this.state.idxPointSelectedMoi:(leQuel=='cible'?this.state.idxPointSelected:0);
      var pointFixeIci = null;

      var setPointFixeIci = true;
      if(pointFixe != null) pointFixeIci = pointFixe;
      else if(pinStateAvant == 2 && pinState == 0) pointFixeIci = null;
      else if(setPointFixeCarte){
        if(encreState)pointFixeIci = this.listePointCarte[idxPoint].getPoint();
        else pointFixeIci = null; 
      } 
      else setPointFixeIci = false;

      if(setPointFixeIci){
        console.log(`setPointFixeIci - Date : ${new Date()}`);

        pointFixeCarte[leQuel] = pointFixeIci;
        stateChange.pointFixeCarte = pointFixeCarte;
      }

      return {stateInit: stateInitIci, stateChange}

        
    }

    this.locatGlobFct = ({leQuel, setThisOrSuper=true, setStateOrDirect = true,
      encreState, pinState, 
      setPointFixeCarte = false, pointFixe = null, stateInit = {}, canSaveStateAndDirect = true})=>{

      let {stateChange, stateInit: stateInitIci} = this.locatGlobBeforeState({leQuel, 
        encreState, pinState, setPointFixeCarte, 
        pointFixe, stateInit});
      
      if(setStateOrDirect){
        if(setThisOrSuper) this.setState(stateChange);
        else {
          //if(stateChange.pointFixeCarte) this.state.pointFixeCarte = stateChange.pointFixeCarte;
          super.setState(stateChange);
          //this.saveState(stateChange);
          if(canSaveStateAndDirect) this.saveStateAndDirect(stateChange);

          this.retraceMoiToLogo({sourceIdx: this.state.idxPointSelectedMoi, 
            cibleIdx: this.state.idxPointSelected, show: this.state.moiLocatBtOn});
          
          
        }
      }else{
        let stateFinal = {...stateInitIci, ...stateChange};
        if(canSaveStateAndDirect) this.saveStateAndDirect(stateFinal);
        else this.saveJustDirectState(stateFinal);
        /*
        let {encreLocatBtOn, pinLocatBtEtat, pointFixeCarte} = stateFinal;

        this.state.encreLocatBtOn = encreLocatBtOn;
        this.state.pinLocatBtEtat = pinLocatBtEtat;
        this.state.pointFixeCarte = pointFixeCarte;

        this.saveState(stateFinal);
        */
        
      }
      
      console.log({stateChange});
      
    }

    this.encreLocatClickGlob = ({leQuel})=>{
      let encreState = !this.state.encreLocatBtOn[leQuel];
      let pinState = 0;
      
      this.locatGlobFct({leQuel, encreState, pinState, setPointFixeCarte: true});
  
    }

    this.pinLocatClickGlob = ({leQuel})=>{
      let encreState = false;
      let pinState = this.state.pinLocatBtEtat[leQuel]==0?1:0;
      if(pinState==1){
        setTimeout(()=>this.init_detail_arrow_deg(), 100);
      }
      this.locatGlobFct({leQuel, encreState, pinState});

    } 

    /*
    this.collimCibleClick = (e)=>{
      if(this.state.collimCibleBtOn){
        this.setState({collimCibleBtOn: false});
      }else{
        setTimeout(()=>this.init_detail_arrow_deg(), 100);
        this.setState({collimCibleBtOn: true});
      }
    }
    */

    this.writePinCoords = (e)=>{
      let leQuel = this.getPinStateReady();
      var coords = this.state.pinCoords;
      coords = [parseFloat(coords[0]), parseFloat(coords[1])];
      
      let willWrite = leQuel !=null && !isNaN(coords[0]) && !isNaN(coords[1]);
      
      if(willWrite){
        this.locatGlobFct({leQuel, setThisOrSuper: true, 
          encreState: false, pinState: 2, 
          pointFixe: fromLonLat(coords),
        })
      } 
    }

    this.itineraire = (e)=>{
      let idItineraire = 'itineraire';
      if(this.state.isItineraire){
        try{
          this.removeTraceOnMap({id: idItineraire, deleteVector: true, setTraceBtOn:false});
        }catch(err){console.log({err})}

        this.superSetState({isItineraire: false});
      }else{
        let fctFail = (error)=>{
          let msg = <><strong>L'itinéraire</strong> n'a pas pu être <strong>calculé</strong>. 
          {error !=null?(<><br/>Echec - {error.code} : {error.message}</>):<></>}</>;
          alertMessage({
            titre: "Itinéraire",
            message: msg, 
            boutons :[{titre: "Ok", color:"primary" ,fct: (e)=>{}}]
          });
          console.log(msg);
        }

        let fctFailNull = ()=>fctFail({code: -19, message: "L'une des deux coordonnées (entre le départ et l'arrivée) est nulle"});

        let startPoint = this.getPointS();//this.listePointCarte[this.state.idxPointSelectedMoi];
        let endPoint = this.getPointC();//this.listePointCarte[this.state.idxPointSelected];

        if(startPoint == null || endPoint == null) fctFailNull();
        else if(startPoint[0]==null || startPoint[1]==null || endPoint[0]==null || endPoint[1]==null ) fctFailNull();
        else{
          let [lng1, lat1] = ol_transform(startPoint, 'EPSG:3857', 'EPSG:4326');
          let [lng2, lat2] = ol_transform(endPoint, 'EPSG:3857', 'EPSG:4326');

          
          
          if(lng1 != null && lat1 !=null && lng2 != null && lat2 !=null ){
            myFetch({url: '/itineraire', method: 'POST',
              body: JSON.stringify({lng1, lat1, lng2, lat2}), 
              cibleElt: document.getElementById('itineraireBt'), 
              timeout: 300_000,
              fctSuccess: (jsn)=>{
                this.superSetState({isItineraire: true});
                
                let routeLatLng = [[lat1, lng1], ...jsn.route, [lat2, lng2]];
                let pointsTrace = routeLatLng.map((val, i) =>{
                  return fromLonLat([val[1], val[0]]);
                });
                this.traceOnMap({id: idItineraire, pointsTrace, showOnMap: true, color: "#135671"});
              },
              fctFail : (jsn)=>fctFail(jsn.error),
              fctCatch : (error)=>fctFail(error),
            })
          }else{
            fctFailNull();
          }
        }
        
      }
      
    }

    this.removePointFixeCarte = ({setThisOrSuper= false, source= false, cible= false, canSaveStateAndDirect = true})=>{
      let nbrSC = (source?1:0) + (cible?1:0);
      if(source) {
        this.locatGlobFct({leQuel: 'source', encreState: false, pinState: 0, setThisOrSuper, setStateOrDirect: (nbrSC>1)?false:true, setPointFixeCarte:true, canSaveStateAndDirect});
        delete this.state.pointFixeCarte.source;
      }
      if(cible){
        this.locatGlobFct({leQuel: 'cible', encreState: false, pinState: 0, setThisOrSuper, setPointFixeCarte:true, canSaveStateAndDirect});
        delete this.state.pointFixeCarte.cible;
      }
      
      
      if(!setThisOrSuper) {
        if(source) this.drawPointFixeCarte({leQuel: 'source', pointFixeCarte: {}, canSaveStateAndDirect});
        if(cible) this.drawPointFixeCarte({leQuel: 'cible', pointFixeCarte: {}, canSaveStateAndDirect});
      }
    }

    this.playbackCalendarClick = ({bodyEvent = false})=>{

      let laDate = (this.state.playback?.date)??new Date();

      this.superSetState({calendar: {
        ...this.state.calendar, 
        annee: laDate.getFullYear(),
        mois: laDate.getMonth(),
        dateEncours: new Date(laDate.getTime()),
        //jour: laDate.getDate(),
      }});

      this.playbackCalendarShow_ref.click();
      if(bodyEvent){
        session.bodyClickEvent = (e)=>{
          session.bodyClickEvent = (e)=>{};
          this.playbackBtClickRetour();
          
        }
      }
        
    }

    this.playbackBtClickFct = (idxPointSelected)=>{
      let laDate = new Date();
      let lePointActu = this.listePointCarte[idxPointSelected];
      
      this.leMap.getOverlays().forEach(item=>{
        item.getElement().style.display = "none"
      });

      this.state.playback = {
        date: laDate,
        time:0,
      };
      this.superSetState({playback: this.state.playback})

      this.calendarEtatDesJoursAnticipe({annee: laDate.getFullYear(), mois: laDate.getMonth(), fctAfter: ()=>{
        this.playbackCalendarOkClick = (e)=>{
          this.playbackCalendarOkClickInit(e);
        }

        this.playbackCalendarClick({bodyEvent: true});// .click(); 
        
        
        
      }});
      

      this.pointPlayback = lePointActu.getPoint();//fromLonLat([15.3279, -4.32080]);//
      [this.overlayPlayback, this.eltPlayback] = this.addLogo({point: this.pointPlayback, faIcon: lePointActu.getLogo(), color: lePointActu.getCouleur(), execLogo:(id)=>{
        this.centerPointCarteIfLocat(100);
      }})
      
      this.centerPointCarteIfLocat(100);
      //console.log(this.resizeIcons_ref?.value);
      if(this.resizeIcons_ref) this.resizeIconFctSingleElement(this.resizeIcons_ref?.value, this.eltPlayback);
      //this.eltPlayback.style.display='initial';
      //console.log(this.eltPlayback);
      this.animeLogoActuLast("playback");
      
      session.onGpsChange = (don)=>{};

      this.removeTraceOnMap({id:'playback', inTab:true});
      this.removePointFixeCarte({setThisOrSuper: true, source: true, cible: true});
      
    }

    this.playbackBtClick = (e)=>{
      e.preventDefault();
      e.stopPropagation();
      
      this.playbackBtClickFct(this.state.idxPointSelected);
    }

    this.playbackBtClickRetour = (e=null)=>{
      this.stopPlayback();
      
      this.leMap.getOverlays().pop();
      this.eltPlayback = null;

      this.superSetState({playback: null, playbackLonglat: null});
      this.leMap.getOverlays().forEach(item=>{
        item.getElement().style.display = "block"
      });
      this.center(this.listePointCarte[this.state.idxPointSelected].getPoint());

      this.animeLogoActu();

      session.onGpsChange = this.onGpsChangeInit;
      this.removeTraceOnMap({id:'playback', inTab:true});
      
      this.removePointFixeCarte({setThisOrSuper: true, source: true, cible: true});
    }

    this.removeTraceOnMap = ({inTab = false, id, deleteVector = true, setTraceBtOn = true})=>{
      session.last_build_hms_long_lat = null;
      if(inTab){
        for(let i in session.vectorLineLayerTrace[id]){
          this.leMap.removeLayer(session.vectorLineLayerTrace[id][i]);
        }
        
      }else{
        this.leMap.removeLayer(session.vectorLineLayerTrace[id]);
        
      }
      if(deleteVector) delete session.vectorLineLayerTrace[id];
      if(setTraceBtOn) this.superSetState({playbackTraceBtOn: false});

    }

    this.showTraceOnMap = ({inTab=false, id, setTraceBtOn=true})=>{
      if(inTab){
        for(let i in session.vectorLineLayerTrace[id]){
          this.leMap.addLayer(session.vectorLineLayerTrace[id][i]);
        }
      }else {
        this.leMap.addLayer(session.vectorLineLayerTrace[id]);
      }

      
      if(setTraceBtOn) this.superSetState({playbackTraceBtOn: true});
    }

    this.getVectorLine = (pointsTrace)=>{
      var featureLine = new Feature({
          geometry: new LineString(pointsTrace)
      });

      var vectorLine = new ol_source_Vector({});
      vectorLine.addFeature(featureLine);

      return vectorLine;
      
    }

    this.traceOnMap = ({id, pointsTrace, showOnMap=false, color = "#008FFD", inTab =false})=>{

      var vectorLine =this.getVectorLine(pointsTrace);
      
      //console.log({pointsTrace});

      //if(session.vectorLineLayerTrace[id] == null) session.vectorLineLayerTrace[id] = [];
      let newLayerVector = new ol_layer_Vector({
          source: vectorLine,
          style: new ol_style_Style({
              fill: new ol_style_Fill({ color: '#00FF00', weight: 4 }),
              stroke: new ol_style_Stroke({ color, width: this.widthVectorFromZoom({actuZ: this.leMap.getView().getZoom()}), lineCap: "butt" })
          })
      });
      if(inTab){
        if(session.vectorLineLayerTrace[id] == null) session.vectorLineLayerTrace[id] = [];
        session.vectorLineLayerTrace[id].push(newLayerVector);
      }else{
        session.vectorLineLayerTrace[id] = newLayerVector;
      }
      

      if(showOnMap){
        this.leMap.addLayer(session.vectorLineLayerTrace[id]);
      }
      
    }

    this.buildPlaybackLecture = (jsn, heure)=>{
      try{

        
        let gpsA = jsn.gpsAch.gps_long_lat;
        let dt=Math.floor(60*(60/session.g_minute)); //60 sec * 3(repartition de g_minute(20) valeurs par heure)
        var t=0;

        var long = jsn.gpsAch.long_debut, 
            lat = jsn.gpsAch.lat_debut, 
            hms; // hms: heure, min, sec en une seule variable = heure*3600+min_sec

        let longLatAvant = [long, lat];
        var longLatTrace;
          
        let tab_hms_long_lat=[]; //tab_hms_long_lat: tableau heure, minute, seconde -> long lat
        let pointsTrace = [];
        if(session.last_build_hms_long_lat != null){
          let hll = session.last_build_hms_long_lat;
          if(hll[3]) {
            longLatTrace = this.pointsTraceDistTest({pointsTrace, longLat: [hll[1], hll[2]]});
            if(longLatTrace != null) pointsTrace.push(fromLonLat(longLatTrace));
            //longLatAvant= this.pointsTracePush({pointsTrace, longLat: [hll[1], hll[2]]})??longLatAvant;
          }
        }

        

        for(let v of gpsA){
          for(; v[0]>=t; t+=dt){
            tab_hms_long_lat.push([[heure*3600+t, long, lat, false]]); //4e argument indique si le long lat est reel. ici non. c du collage
            if(pointsTrace.length >=2){
              this.traceOnMap({id: 'playback', pointsTrace, inTab:true});
            }
            pointsTrace = [];
          }
          
          [hms, long, lat] = [heure*3600+v[0], v[1], v[2]];
          tab_hms_long_lat[tab_hms_long_lat.length-1].push([hms, long, lat, true]) //4e argument indique si le long lat est reel. ici oui. 
          //longLatAvant= this.pointsTracePush({pointsTrace, longLat: [long, lat], longLatAvant})??longLatAvant;
          longLatTrace = this.pointsTraceDistTest({pointsTrace, longLat: [long, lat], longLatAvant});
          
          if(longLatTrace != null){
            pointsTrace.push(fromLonLat(longLatTrace));
            longLatAvant = longLatTrace;
          }else{
            this.traceOnMap({id: 'playback', pointsTrace, inTab:true});
            pointsTrace = [];
          }
        }

        for(; 3600-dt>=t; t+=dt){
          tab_hms_long_lat.push([[heure*3600+t, long, lat, false]]);
        }

        jsn.gpsAch.gps_long_lat = tab_hms_long_lat;
        session.playbackGps[heure] = jsn.gpsAch;

        if(!session.playbackGpsDebut){
          session.playbackGpsDebut = {
            heure,
            long_debut : jsn.gpsAch.long_debut,
            lat_debut : jsn.gpsAch.lat_debut,
            minsec_long_lat: gpsA[0]
          }

          this.playbackGpsOnCarte({t0:0});
          //console.log("session.playbackGpsDebut : ", session.playbackGpsDebut);
        }
        let lthll = tab_hms_long_lat[tab_hms_long_lat.length-1];
        session.last_build_hms_long_lat = lthll[lthll.length-1];
        this.traceOnMap({id: 'playback', pointsTrace, inTab:true});

      }catch(err){}
      //console.log("session.playbackGps -> ", session.playbackGps);
    }

    this.pointsTraceDistTest = ({pointsTrace, longLat, longLatAvant = null})=>{
      
      let longLatIci = [parseFloat(longLat[0]), parseFloat(longLat[1])];
      if(longLatAvant !=null){
        let longLatAvantIci = [parseFloat(longLatAvant[0]), parseFloat(longLatAvant[1])];
        if(pointsTrace.length==0){
          return longLatIci;
          
        }else{
          
          let dLong = this.degreEnMetre*Math.abs(longLatIci[0]-longLatAvantIci[0]);
          let dLat = this.degreEnMetre*Math.abs(longLatIci[1]-longLatAvantIci[1]);
          

          if(dLong<500 && dLat<500){
            
            return longLatIci;
          }else{
            //return longLatIci;
            return null;
          }
        }


      }else{
        return longLatIci;
      }
      
    }

    this.logoPlaybackDisparaitreInit = ()=>{
      
      this.eltPlayback.classList.add("disparu");

      this.logoPlaybackDisparaitre = ()=>{};
      this.logoPlaybackApparaitre = ()=>{this.logoPlaybackApparaitreInit();}
    }
    this.logoPlaybackApparaitreInit = ()=>{
      
      this.eltPlayback.classList.remove("disparu");

      this.logoPlaybackApparaitre = ()=>{};
      this.logoPlaybackDisparaitre = ()=>{this.logoPlaybackDisparaitreInit();}
    }

    this.logoPlaybackApparaitre = ()=>{
      this.logoPlaybackApparaitreInit();
    }

    this.logoPlaybackDisparaitre = ()=>{
      this.logoPlaybackDisparaitreInit();
    }
/*
    if(!this.logoPlaybackDisparu){
        
      //this.logoPlaybackDisparu = true;
    }
    */
/*
    this.hmsToPlaybackTime = (heure, min_g, sec_g)=>{
      return heure*3600+min_g*(3600/session.g_minute)+sec_g;
    }
*/
    this.playbackTimeToHMS = (time)=>{
      var rest = 0;
      let h = Math.floor(time/3600); rest = time%3600; //console.log(rest)
      let mg = Math.floor(rest*session.g_minute/3600); rest = rest%(3600/session.g_minute); //console.log(rest)
      let sg = Math.floor(rest);
      return [h, mg, sg];
    }

    this.playbackPointeurDebut = ()=>{
    
      let heureDebut = session.playbackGpsDebut?.heure;
      let minsec = session.playbackGpsDebut.minsec_long_lat[0];
      if(heureDebut!= null && minsec != null){
        let timeDebut = parseInt(heureDebut)*3600 + parseInt(minsec);
        return {heureDebut, timeDebut, long: session.playbackGpsDebut.long_debut, lat: session.playbackGpsDebut.lat_debut, reel: timeDebut == 0};
      }else{
        return null;
      }
      
    }
    
    this.playbackGpsSetPosition_LongLat_Reel = ({time, longLat, reel, hmi})=>{
      if(reel){
        setTimeout(()=>this.logoPlaybackApparaitre(), 500);
        this.superSetState({
          logoGoPointVisible : {gauche: false, droite: false},
        });
      } 
      else {
        this.logoPlaybackDisparaitre();
        this.superSetState({
          logoGoPointVisible : {gauche: time>0, droite: time<86399},
        });
      }

      if(longLat){
        this.pointPlayback = fromLonLat(longLat);
        this.superSetState({playbackLonglat: longLat});
        this.overlayPlayback.setPosition(this.pointPlayback);

        this.retraceMoiToLogo({sourceIdx: this.state.idxPointSelectedMoi, 
          cibleIdx: null, show: this.state.moiLocatBtOn});
        //this.centerIfNotRefresh(this.pointPlayback);
        if(!this.state.locatAllwaysToMoi) this.centerPointCarteIfLocat(100);
      }
      
      session.playbackGpsPointeur = hmi;

      return {time, longLat, reel, hmi};
    }

    this.playbackGoPointVisible = (positif)=>{
      this.playbackRangeChange = (e)=>{}

      if(positif && this.state.onPlayingNegatif){
        this.pausePlayback(false);
      }else if(!positif && this.state.onPlayingPositif){
        this.pausePlayback(true);
      }
      
      ((positif)=>{

        try{

        
          var longLat = null; var reel = false;
          var [heure, min, idx] = session.playbackGpsPointeur;
          
          let inc = positif?(varia)=>{return ++varia;}:(varia)=>{return --varia;};
          let inf = positif?(varia, comp)=>{return varia<comp}:(varia, comp)=>{return varia>comp;};

          let compHeure = positif?24:-1;
          let compMinute = positif?(espaceBloc)=>{return espaceBloc.length;}:(espaceBloc)=>{return -1;};
          let compI = positif?(bloc)=>{return bloc.length;}:(bloc)=>{return -1;};

          let minInitAfter = positif?(espaceBloc)=>{return 0;}: (espaceBloc)=>{return espaceBloc.length-1;}
          var minInit = (espaceBloc)=>{minInit = minInitAfter; return min;};

          let iAfter = positif?(bloc)=>{return 0;}: (bloc)=>{return bloc.length-1;};
          let iInit = (bloc)=>{iInit = iAfter; return idx;};
          
          let firstPass = ()=>{minInit([]); iInit([]);};

          for(; inf(heure, compHeure); heure = inc(heure)){
            //testeIterThrow({id: 12, taille: 50, fct: ()=>{console.log("heure : ", heure);}});
            
            let espaceBlocHeure = session.playbackGps[heure];

            if(espaceBlocHeure){
              let espaceBloc = espaceBlocHeure.gps_long_lat;

              if(espaceBloc){
                min = minInit(espaceBloc);
                for(; inf(min, compMinute(espaceBloc)); min = inc(min)){
                  //testeIterThrow({id: 12, taille: 50, fct: ()=>{console.log("min : ", min);}});

                  let bloc = espaceBloc[min];
            
                  if(bloc){
                    idx = iInit(bloc);
                    for(; inf(idx, compI(bloc)) ; idx = inc(idx)){
                      
                      let v = bloc[idx];
                      if(v){
                        longLat = [v[1], v[2]];
                        reel = v[3];
                        
                        if(reel){
                          this.superSetState({playback : {...this.state.playback, time: v[0]}});
                          return this.playbackGpsSetPosition_LongLat_Reel({time: v[0], longLat, reel, hmi: [heure, min, idx]});
                        }
                      }
                        
                    }

                    firstPass();
                    
                  }
                }
                
                firstPass();

              }
            }

            firstPass();
          }

          this.superSetState({playback : {...this.state.playback, time: positif?86399:0}});
          return this.playbackGpsSetPosition_LongLat_Reel({time : positif?86399:0, longLat: null, reel: false, hmi: [heure, min, idx]});
        
        }catch(err){
          console.log(err);
        }

      })(positif);
      

      this.playbackRangeChange = (e)=>{
        this.playbackRangeChangeInit(e);
      }
    }

    this.playbackGpsGetBloc = (time)=>{
      let [heure, min ,sec] = this.playbackTimeToHMS(time);

      var longLat = null; var reel = false; var idx=null;
      let bloc = session.playbackGps[heure]?.gps_long_lat[min];
      
      if(bloc){
        for(let i = 0; i<bloc.length; i++){
          let v = bloc[i];
          if(v[0]>time)break;
          longLat = [v[1], v[2]];
          reel = v[3];
          idx=i;
          //session.playbackGpsPointeur = [heure, min, i];
        }
        
      }

      return {longLat, reel, heure, min, idx}
    }


    this.playbackGpsSetPointeur = (time, saut = false)=>{
     
      var {longLat, reel, heure, min, idx} = this.playbackGpsGetBloc(time);
    
      if(saut && !longLat){
        let fctEspaceBloc = (espaceBloc, minIci)=>{
          var longLat = null, bloc= null;
          if(minIci>=0){
            let bloc = espaceBloc[minIci];
            let v = bloc[bloc.length-1];
            longLat = [v[1], v[2]];
            
          }

          let idx = (bloc !=null)?bloc.length-1: null;

          return {longLat, idx};
        }

        
        //verification de : si la plus petite valeur heure min sec est suppérieur à notre heure min
        let pointeurDebut = this.playbackPointeurDebut();
        
        if(((pointeurDebut?.timeDebut)??(-1))>=time){
          console.log({time, timeDebut: pointeurDebut?.timeDebut})
          longLat = [pointeurDebut.long, pointeurDebut.lat];
          reel = pointeurDebut.reel;

          [heure, min, idx] = [pointeurDebut.heureDebut, 0, 0];
          
        }else{

          var heureIci = heure, minIci = min;
          var espaceBlocHeure = session.playbackGps[heureIci];
          var espaceBloc = espaceBlocHeure?.gps_long_lat;
          
          if(espaceBloc){
            if(minIci>espaceBloc.length-1) minIci = espaceBloc.length-1;
            let resultBloc = fctEspaceBloc(espaceBloc, minIci);
            [longLat, idx] = [resultBloc.longLat, resultBloc.idx];
          } 
          
          if(longLat){
            [heure, min, idx] = [heureIci, minIci, idx];
          }else{
            for(; heureIci>=0; heureIci--){
              espaceBlocHeure = session.playbackGps[heureIci];
              if(espaceBlocHeure){
                espaceBloc = espaceBlocHeure.gps_long_lat;
                
                if(espaceBloc){
                  minIci = espaceBloc.length-1;
                  let resultBloc = fctEspaceBloc(espaceBloc, minIci);
                  [longLat, idx] = [resultBloc.longLat, resultBloc.idx];
                  if(longLat) break;
                }
              } 
    
            }

            if(longLat) [heure, min, idx] = [heureIci, minIci, idx];
          }
        }
 
      }

      
      this.playbackGpsSetPosition_LongLat_Reel({time, longLat, reel, hmi: [heure, min, idx]});
      
      return longLat;
    }

    this.playbackGpsOnCarte = ({t0=null, dt=0})=>{
      if(t0 != null){
        let time = t0+dt;

        //session.playbackGpsPointeur = [0, 0, 0];
        this.playbackGpsSetPointeur(time, dt==0);
 
      
      }else{
        

        let time = this.state.playback.time + dt;
        let hms = this.playbackTimeToHMS(time);
        let pointeurHmi = session.playbackGpsPointeur;

        let dansLesTemps = false;
        if(pointeurHmi)if(hms[0]==pointeurHmi[0] && hms[1]==pointeurHmi[1]) dansLesTemps = true;
        
        if(dansLesTemps){
          this.playbackGpsSetPointeur(time);
 
        }
        else this.playbackGpsOnCarte({t0:time});
        
      }
    }
    this.degreEnMetre = 111_319;

    this.playbackCalendarOkClickFetch = (heure)=>{
      
      if(heure==0) {
        session.playbackGps = {};
        session.playbackGpsDebut = null;
        session.setProgress(document.getElementById('root'));
        this.removeTraceOnMap({id:'playback', inTab:true});
      }
      if(heure<24){
        session.setProgressValue(Math.ceil(heure/24*100));

        let dateEncours = this.state.calendar.dateEncours;
        let date = new Date(dateEncours.getFullYear(), 
          dateEncours.getMonth(), 
          dateEncours.getDate(),
          heure);

        //console.log("playbackCalendarOkClickFetch(", heure,")");
        myFetch({url:`/gps_archive/${session.code_emetteur_actuel}/${date}`, 
          fctSuccess:(jsn)=>{
              (async ()=>{
                this.buildPlaybackLecture(jsn, heure);
              })();
              this.playbackCalendarOkClickFetch(heure+1);
          },
          fctCatch : (err)=>{
            session.setProgressValue(100);
            session.setProgressEtat({text: "Echec...", color: "danger"});
            session.progressAddExitOnBodyBaseClick();
            //setTimeout(()=>session.removeProgress(), 2500);
          },
    
        });
      }else{
        this.superSetState({
          playback: {
            ... this.state.playback,
            date : new Date(this.state.calendar.dateEncours.getTime())
          }
        });

        session.setProgressValue(100);
        session.setProgressEtat({text: "Terminé", color: "success"});
        setTimeout(()=>session.removeProgress(), 1000);
      }
    }

    this.playbackCalendarOkClickInit = (e)=>{
      session.bodyClickEvent = (e)=>{};
      this.playbackCalendarOkClick = (e)=>{
        this.stopPlayback();
        this.playbackCalendarOkClickFetch(0);
      }
      this.playbackCalendarOkClick(e);
    }

    this.playbackCalendarOkClick = (e)=>{
      this.playbackCalendarOkClickInit(e);
    }

    this.details_arrow_click = (e)=>{
      document.getElementById("detail_arrow_deg_bt_exec").click();
      this.superSetState({detail_arrow_deg: this.state.detail_arrow_deg==0?180:0});
    };

    this.pin_arrow_click = (e)=>{
      document.getElementById("pin_arrow_deg_bt_exec").click();
      this.superSetState({pin_arrow_deg: this.state.pin_arrow_deg==0?180:0});
    };

    this.gps_menu_arrow_click = (e)=>{
      this.superSetState({gps_menu_arrow_deg:this.state.gps_menu_arrow_deg==0?180:0});
    }

    this.str = {
      source: "source",
      cible: "cible"
    }

   
    this.getBtn = {
      encre: (sourceCible)=>{
        let strSC = sourceCible?this.str.source:this.str.cible;
        return  <div className={`btn py-0 px-1 ms-2 btn-${this.state.encreLocatBtOn[strSC]?"primary border border-white":"secondary"} `} onClick={(e)=>this.encreLocatClickGlob({leQuel: strSC})} > 
                  <i className='fa fa-anchor text-white'></i>
                </div>
      },
      pin: (sourceCible)=>{
        let strSC = sourceCible?this.str.source:this.str.cible;
        return  <div className={`btn py-0 px-1 ms-2 btn-${this.colorBootFromPinState({leQuel: strSC})} `} onClick={(e)=>this.pinLocatClickGlob({leQuel: strSC})} > 
                  <i className='fas fa-map-pin text-white'></i>
                </div>
      },

      pen: (sourceCible)=>{
        let strSC = sourceCible?this.str.source:this.str.cible;
        return  <div className={`btn border border-white py-0 px-1 ms-2 btn-success ${(this.state.pinLocatBtEtat[strSC]==1)?'':'d-none'}`} data-bs-toggle="modal" data-bs-target="#writePinModal" onClick={(e)=>{this.superSetState({pinCoords : ["", ""]})}} >
                  <i className='fa fa-pen text-white'></i>
                </div>
      }


    }

    this.getSelect = {
      pointOpt : (sourceCible)=>{
        //let strSC = sourceCible?this.str.source:this.str.cible;
        return  <select className="form-select m-0" name="select-vehicule" onChange={sourceCible?this.selectPointOptMoiLocat:this.selectPointOpt} value={sourceCible?this.state.idxPointSelectedMoi:this.state.idxPointSelected} >
                  {this.state.listePointOpt.map((v,i)=>{
                    if(i==0 && sourceCible) return this.optionPointOpt({nom: "Moi", value:0});
                    return this.optionPointOpt(v);
                  })}
                  
                </select>
      }
    }

    this.setState = (props)=>{
      this.superSetState(props, true);
    }

    this.superSetState = (props, save = false)=>{
      let lesProps = {...props};
      let {force_idxPointSelected, force_idxPointSelectedMoi, locatAllwaysToMoi, pointFixeCarte, moiLocatBtOn, idxPointSelected, idxPointSelectedMoi, overlaysNotVisible, overlaysNotVisibleItem} = lesProps;
      
      var retraceMoi = false, retraceMoiShow = this.state.moiLocatBtOn??false,
          sourceRetraceIdx = this.state.idxPointSelectedMoi, 
          cibleRetraceIdx = this.state.idxPointSelected;

      if(idxPointSelected !== undefined){
        if(this.state.idxPointSelected != idxPointSelected || force_idxPointSelected === true){
          //this.locatGlobFct({leQuel: 'cible', encreState: false, pinState: 0, setThisOrSuper: false, setPointFixeCarte:true});
          this.removePointFixeCarte({setThisOrSuper: false, cible: true, canSaveStateAndDirect: false});

          let lePoint = this.listePointCarte[idxPointSelected];
          this.logo_exec_pure(this.state.idxPointSelected, idxPointSelected);
          lesProps.fantomeActu = lePoint.getFantome();

          delete lesProps.force_idxPointSelected;
        }
        
        retraceMoi = retraceMoi || true;
        cibleRetraceIdx = idxPointSelected;
      }

      if(idxPointSelectedMoi !== undefined){
        if(this.state.idxPointSelectedMoi != idxPointSelectedMoi || force_idxPointSelectedMoi === true){
          if(idxPointSelectedMoi == 0) this.setMoiGps({});
          //this.locatGlobFct({leQuel: 'source', encreState: false, pinState: 0, setThisOrSuper: false, setPointFixeCarte:true});
          this.removePointFixeCarte({setThisOrSuper: false, source: true, canSaveStateAndDirect:false});

          this.logo_exec_pure_source(this.state.idxPointSelectedMoi, idxPointSelectedMoi);

          delete lesProps.force_idxPointSelectedMoi;
        }
        
        retraceMoi = retraceMoi || true;
        sourceRetraceIdx = idxPointSelectedMoi;
      }

      if(moiLocatBtOn !== undefined){
        if(moiLocatBtOn) this.setMoiGps({});
        else if(this.watchPositionId !=null) navigator.geolocation.clearWatch(this.watchPositionId);

        this.removePointFixeCarte({source: true, cible: true, canSaveStateAndDirect: false});//this.state.pointFixeCarte = {};

        if(moiLocatBtOn) this.logo_exec_pure_source(null, this.state.idxPointSelectedMoi);
        else {
          this.logo_exec_pure_source(this.state.idxPointSelectedMoi, null);
          this.logo_exec_pure(null, this.state.idxPointSelected, true);
        }

        retraceMoi = retraceMoi || true;
        retraceMoiShow = moiLocatBtOn;
      }

      if(pointFixeCarte){
        retraceMoi = retraceMoi || true;
        
        this.drawPointFixeCarte({leQuel: 'source', pointFixeCarte});
        this.drawPointFixeCarte({leQuel: 'cible', pointFixeCarte});
        
        this.state.pointFixeCarte = pointFixeCarte;
        if(this.state.moiLocatBtOn && (moiLocatBtOn == undefined) ){
          if(pointFixeCarte.source != null) this.logo_exec_pure_source(this.state.idxPointSelectedMoi, null);
          else this.logo_exec_pure_source(null, this.state.idxPointSelectedMoi);
        }
        
        //exec_point_fixe_carte(pointFixeCarte);
      }
      
      if(retraceMoi){
        this.retraceMoiToLogo({sourceIdx : sourceRetraceIdx, 
          cibleIdx: cibleRetraceIdx, 
          show: retraceMoiShow});
      }

      if(overlaysNotVisible !== undefined || overlaysNotVisibleItem !== undefined ){
        let fct_ToInvisible = (overlaysNotVisibleIci, id)=>{
          console.log("fct_ToInvisible : ", overlaysNotVisibleIci, " -> id : ", id)
          let state = overlaysNotVisibleIci[id];
          var toInvisible = false;
          for(let st in state){
            if(state[st]==true) {
              toInvisible = true;
              console.log("state[st] : ", state[st]);
              break;
              
            }

          }
          
          if(toInvisible){
            this.leMap.getOverlays().item(id).getElement().classList.add("d-none");//.style.display="none";
            
          }else{
            this.leMap.getOverlays().item(id).getElement().classList.remove("d-none");//style.display="initial";
          }
          
          console.log("toInvisible Item : ", this.leMap.getOverlays().item(id).getElement().attributes);

        }

        let overlaysNotVisibleIci = null;

        if(overlaysNotVisible !== undefined){
          overlaysNotVisibleIci = overlaysNotVisible;
          for(let id in overlaysNotVisible){
            fct_ToInvisible(overlaysNotVisible, id);
          }
        }

        if(overlaysNotVisibleItem !== undefined){
          let id = overlaysNotVisibleItem.id;
          if(overlaysNotVisibleIci == null) overlaysNotVisibleIci = this.state.overlaysNotVisible??{};

          overlaysNotVisibleIci[id] = {...overlaysNotVisibleIci[id], ...(overlaysNotVisibleItem.state??{})};
          
          
          fct_ToInvisible(overlaysNotVisibleIci, id);

          delete lesProps.overlaysNotVisibleItem;
          lesProps.overlaysNotVisible = overlaysNotVisibleIci;
        }

      }

      if(locatAllwaysToMoi !== undefined){
        if(this.state.locatAllwaysToMoi != locatAllwaysToMoi){
          this.state.locatAllwaysToMoi = locatAllwaysToMoi;
          this.centerPointCarteIfLocat();

          //this.checkAllSelectFilter();
          //this.checkAllSelectFilterTrace();
          
          for(let key in this.state.pinLocatBtEtat){
            if(this.state.pinLocatBtEtat[key]==1){
              this.pinLocatClickGlob({leQuel: key});
            }
          }
        }
      }

      

      if(save) this.saveState(lesProps);
      console.log("lesProps : ", lesProps);
      super.setState(lesProps);

    }
  }

  saveState(props){
    session.stateOfCarteshow = {...session.stateOfCarteshow, ...props};
  }

  saveJustDirectState(props){
    this.state = {...this.state, ...props};
  }

  saveStateAndDirect(props){
    this.saveJustDirectState(props);
    this.saveState(props);
  }
               
  resizeIconFctSingleElement(value, elt){
    let x = value;

    if(elt){
      let a = [ 0.00024, 0.004, 0.2 ];
    
      let y = a[0]*x*x+a[1]*x+a[2];
      y = 0.01*Math.floor(100*y+0.5);
      if(y>0.6 && elt.classList.contains("logo-circle")) y = 0.5; 

      elt.style.fontSize = `${y}em`;

      //console.log("y = ", y);
      //elt.style.transform = `scale(${y})`;
      /*
      var liste_marges = [...Array(6).keys()].map((v, i)=>{
        return `m-${i}`;
      });
      
      
      let eltMargCl = elt.firstChild?.firstChild?.classList;
      if(eltMargCl){
        //eltMargCl.remove(...liste_marges);
        //eltMargCl.add(`m-${Math.floor(y)}`);
        console.log("MARGE -> ", `m-${Math.floor(y)}`);
      } 
*/
      
    }
  }
    
  resizeIconsFct(value){
      //console.log('value -> ', value);
      

      this.leMap.getOverlays().forEach(ov=>{
        /*
        document.getEqSlide = ([y0, y1, y2])=>{
          return [(y0+y2-2*y1)/5000, (4*y1-3*y0-y2)/100, y0];
        }
*/      
        //let a = [0.0003, -0.005, 0.5];

        this.resizeIconFctSingleElement(value, ov.getElement());
        //console.log("y(", y, "), floor-y(",Math.floor(y),")");
      })
      session.sizeIconMap = value;
      //this.switchTailleIconsChangeFct(false);
      
    }

  /*
  switchTailleIconsChangeFct(switched){
    if(this.switchTailleIconsLogoCadena_ref.classList.contains('fa-lock-open')){
      if(switched){
        this.switchTailleIconsLogoCadena_ref.classList.remove('fa-lock-open');
        this.switchTailleIconsLogoCadena_ref.classList.add('fa-lock');

        session.sizeIconMap = this.resizeIcons_ref.value;
      }else{
        if(session.sizeIconMap) session.sizeIconMap = null;
      }
    }else{
      if(switched){
        this.switchTailleIconsLogoCadena_ref.classList.remove('fa-lock');
        this.switchTailleIconsLogoCadena_ref.classList.add('fa-lock-open');
        
        if(session.sizeIconMap) session.sizeIconMap = null;
      }else{
        session.sizeIconMap = this.resizeIcons_ref.value;
      }
    } 
  }
*/
  deleteEmetteurId(id){
    let code_emetteur = this.listePointCarte[id].getCodeEmetteur();

    if(code_emetteur != null){
      myFetch({url: '/unusedEmetteur', method: 'POST', body: JSON.stringify({
        code_emetteur, idx: id
      }), cibleElt: document.getElementById('root'), 
        fctSuccess: (jsn)=>{
          //console.log('idx -> ', jsn.idx);
          //if(this.filtre_ref)  this.filtre_ref.disabled=false;
  
          const id = parseInt(jsn.idx);
  
          //console.log('this.state.listePointFiltre[id] -> ', this.state.listePointFiltre[id]);
  
          this.state.listePointOpt[id] = {...this.state.listePointOpt[id], visible: false};//<span value={id} style={{display: "none"}}></span>;
  
          this.state.listePointFiltre[id] = {...this.state.listePointFiltre[id], visible: false}; //<span value={id} style={{display: "none"}}></span>;
          this.setState({
            listePointOpt: this.state.listePointOpt,
            listePointFiltre: this.state.listePointFiltre
          })
  
          //this.filtreSelectListe_ref[id] = <span></span>;
          this.leMap.getOverlays().item(id).setElement(document.createElement('span'));
        },
      
      });
    }
    
    
  }

  

  filtreSelectRemove(id){
   
    this.state.listePointOpt[id] = {...this.state.listePointOpt[id], visible: false};//= React.cloneElement(this.state.listePointOpt[id], {style: {display: "none"}});
    this.state.listePointFiltre[id] = {...this.state.listePointFiltre[id], active: false};//React.cloneElement(this.state.listePointFiltre[id], {className: tg.className});
    this.closeTraceEmetteurNoSet({e:null, id, activeTraceState: false});

    this.setState({overlaysNotVisibleItem:{id, state: {filtre: true}}, listePointOpt: this.state.listePointOpt, listePointFiltre: this.state.listePointFiltre});
    
  }

  filtreSelectAdd(id){
    //alert("filtreSelectAdd");
    this.state.listePointOpt[id] = {...this.state.listePointOpt[id], visible: true};//= React.cloneElement(this.state.listePointOpt[id], {style: {display: "none"}});
    this.state.listePointFiltre[id] = {...this.state.listePointFiltre[id], active: true};//React.cloneElement(this.state.listePointFiltre[id], {className: tg.className});
    
    this.setState({overlaysNotVisibleItem:{id, state: {filtre: false}}, listePointOpt: this.state.listePointOpt, listePointFiltre: this.state.listePointFiltre});

    //this.checkAllSelectFilterTrace();
  }

  filtreSelectTraceRemove(id){
    this.activeTrace({id, state: false});
    //this.state.listePointFiltre[id] = {...this.state.listePointFiltre[id], activeTrace: false};
    this.setState({listePointFiltre: this.state.listePointFiltre});
    
  }

  filtreSelectTraceAdd(id){
    this.activeTrace({id, state: true});
    //this.state.listePointFiltre[id] = {...this.state.listePointFiltre[id], activeTrace: true};
    this.setState({listePointFiltre: this.state.listePointFiltre});

  }

  animeLogoActu(idNum = null, cat=0){
    
    try{
      let oldElt = this.oldEltAnimeLogo[cat];
      if(oldElt) oldElt.classList.remove("logo_actu_anim");
    }catch(err){}
    
    try{
      let newElt = this.leMap.getOverlays().item((idNum)??this.state.idxPointSelected).getElement();
      newElt.classList.add("logo_actu_anim");
      this.oldEltAnimeLogo[cat] = newElt;
    }catch(err){}
  }

  animeLogoActuLast(cat =0){
    let taille = this.leMap.getOverlays().getLength();
    this.animeLogoActu(taille-1, cat);
  }

  logo_goTo(idNum, cat=0){
    this.animeLogoActu(idNum, cat);
    
    let lePoint = this.listePointCarte[idNum];
    
    if(!lePoint.getFantome()){
      if(this.state.locatAllways) this.center(this.leMap.getOverlays().item(idNum).getPosition());
      
    }

    return lePoint;

  }

  logo_goDestinTo(idNum){
    let lePoint = this.listePointCarte[idNum];
    this.animeLogoActu(idNum);
    return lePoint;
  }

  getPointS(sourceIdx = this.state.idxPointSelectedMoi){
    return this.state.pointFixeCarte.source??(this.listePointCarte[sourceIdx??0].getPoint());
  }
  getPointC(cibleIdx = this.state.idxPointSelected){
    return this.state.pointFixeCarte.cible??(this.state.playback?this.pointPlayback:this.listePointCarte[cibleIdx??0].getPoint());
  }

  retraceMoiToLogo({sourceIdx, cibleIdx, show = true}){
    console.log("retraceMoiToLogo");

    let fct_else = ()=>{
      if(session.traceMoiToLogoLayer != null){
        this.leMap.removeLayer(session.traceMoiToLogoLayer);//.setVisible(false);
        delete session.traceMoiToLogoLayer;// = null;
      }
    }

    if(show){
      let pointS = this.getPointS(sourceIdx);
      let pointC = this.getPointC(cibleIdx);
      /*
      console.log({
        pointS, 
        pointC, 
        pointFixe: this.state.pointFixeCarte,

        sourceV: this.listePointCarte[sourceIdx??0].getPoint(),
        cibleV : this.state.playback?this.pointPlayback:this.listePointCarte[cibleIdx??0].getPoint(),
      });
      */
      if(pointS!= null && pointC !=null){
        var vectorLine =this.getVectorLine([pointS, pointC]);
        
        if(session.traceMoiToLogoLayer !=null && this.leMap != null){
          session.traceMoiToLogoLayer.setSource(vectorLine);
        }else{
          session.traceMoiToLogoLayer=new ol_layer_Vector({
            source: vectorLine,
            style: new ol_style_Style({
                fill: new ol_style_Fill({ color: '#00FF00', weight: 4 }),
                stroke: new ol_style_Stroke({color: 'rgba(0, 204, 255, 0.3)', width:4, lineCap: "butt"/*lineDash: [7, 9]*/}) 
            })
          });
          this.leMap.addLayer(session.traceMoiToLogoLayer);
          
        }

        //session.traceMoiToLogoLayer.setVisible(true);
      }else{
        fct_else();
      }
      
    }else{
      fct_else();
    }

  }
  
  logo_exec_pure(idFrom, idTo, strictGoTo = false){
    var lePoint;
    if(!strictGoTo && this.state.locatAllwaysToMoi){
      lePoint = this.logo_goDestinTo(idTo);
    }else{
      lePoint = this.logo_goTo(idTo);
    }

    if(idFrom == this.state.idxPointSelectedMoi){
      this.animeLogoActu(idFrom, 1);
    }

    session.conxAsideNavEtat.reset({
      etat: lePoint.getEtat(),
      code_emetteur: lePoint.getCodeEmetteur()
    })

    session.code_emetteur_actuel = lePoint.getCodeEmetteur();
    
  }

  logo_exec_pure_source(idFrom, idTo){
    if(idFrom != null) {
      let lePointFrom = this.listePointCarte[idFrom];
      let [overlay, eltSimple] = this.createLogoCarte({
        id: idFrom,
        point: lePointFrom.getPoint(), 
        faIcon: lePointFrom.getLogo(), 
        color: lePointFrom.getCouleur(), 
        circle: false, visible: !this.state.playback});
      this.leMap.getOverlays().item(idFrom).setElement(eltSimple);
      
      if( idFrom == this.state.idxPointSelected) this.animeLogoActu(idFrom);

      
    }
    
    if(idTo != null){
      let lePointTo = this.listePointCarte[idTo];
      let [overlay, eltCircle] = this.createLogoCarte({
        id: idTo,
        point: lePointTo.getPoint(), 
        faIcon: lePointTo.getLogo(), 
        color: lePointTo.getCouleur(), 
        circle: true, visible:true});
      this.leMap.getOverlays().item(idTo).setElement(eltCircle);
      
      this.logo_goTo(idTo, 1);
    }
    
    
  }

  createLogoCarte({id, point, faIcon, color, execLogo=null, circle = false, visible=true}){
    let elt = document.createElement('div');
    if(circle) elt.classList.add('logo-circle');
    
    elt.style.display = visible?"initial":"none";
    if(session.sizeIconMap) this.resizeIconFctSingleElement(session.sizeIconMap, elt);
    elt.addEventListener("pointerdown", (e)=>{e.stopPropagation();});
    elt.addEventListener("pointerup", (e)=>{e.stopPropagation();});
    //elt.textContent = "Le Meme";
    let rootElt = ReactDOM.createRoot(elt);
    

    console.log(`logo point : ${point}`);
    
    rootElt.render(<LogoFaIconCarte id={"logo_carte_"+id} logo_exec_carte = {execLogo??this.logo_exec_carte} faIcon={faIcon} color={color} circle={circle} kCenter />);
    let overlay = new Overlay({
      position: point,
      element: elt,
      //autoPan: true,
      
    });

    return [overlay, elt];
  }

  addLogo({point, faIcon, color, execLogo=null, circle = false, visible=true}){
    let [overlay, elt] = this.createLogoCarte({id: this.leMap.getOverlays().getLength(), point, faIcon, color, execLogo, circle, visible});
    this.leMap.getOverlays().push(overlay);

    return [overlay, elt];
  }

  optionPointOpt({nom, value, visible=true}){
    return <option value={value} style = {{display: visible?"initial":"none"}} >{nom}</option>
  }

  addPointOpt({stateEnreg= true, nom, visible = true}){
    //this.state.listePointOpt.push(this.optionPointOpt({nom, visible, value: this.state.listePointOpt.length}));
    this.state.listePointOpt.push({nom, visible, value: this.state.listePointOpt.length});
    let __setState = stateEnreg?this.setState:this.superSetState;
    __setState({listePointOpt: this.state.listePointOpt});

  }

  filtrePointFiltreFct({onClick, traceElement=false, visibleByActive=false, activeByTracer=false, canTrash=true}, {nom, faIcon, color, value, visible = true, active = true}, id){
    let fctVisible = ()=>{
      return (visibleByActive?this.state.listePointFiltre[id].active:true)&&this.state.listePointFiltre[id].visible && (!this.state.listePointFiltre[id].moiGps || (this.state.listePointFiltre[id].moiGps && this.state.moiLocatBtOn));
    }

    let fctActive = ()=>{
      return (activeByTracer?this.state.listePointFiltre[id].activeTrace:this.state.listePointFiltre[id].active);
    }

    let fctTrashVisible = ()=>{
      return canTrash && this.state.deleteEmetteurShowedBtOn && !this.state.listePointFiltre[id].moiGps;
    }

    return <button type="button" className={`list-group-item list-group-item-light list-group-item-action p-0 ${fctActive()?"active":""} ${fctVisible()?"":"d-none"} `} value={value} onClick={onClick} 
           >
        <div className='d-flex justify-content-between align-items-center'>
          <div className='p-2'>
            <span>{nom}</span> 
            <div className={`logo_create logo_createFilter text-white p-1 ${faIcon}`} style={{backgroundColor: color}}></div>
          </div>
          <div className={`logo_create logo_trashFilter text-white fa fa-trash bg-danger p-2 btDeleteEmetteur ${fctTrashVisible()?'':'d-none'}`} onClick={this.deleteEmetteur}></div>
          {traceElement?<>
          <span>
            <span className={`btn btn-danger  ${this.state.listePointFiltre[id].closedTrace?'d-none':''} logo_create me-1`} onClick={this.closeTraceEmetteur}>
              <i className='fa fa-close'></i>
            </span>

            <span className={`btn btn-${this.state.listePointFiltre[id].playingTrace?'warning':'success'} logo_create me-1`} onClick={this.playStopTraceEmetteur}>
              <i className={`fa fa-${this.state.listePointFiltre[id].playingTrace?'stop':'play'}`}></i>
            </span>
            
          </span>
          </>:<></>}
          
        </div>
      </button>;
  }

  filtrePointFiltre(props, id){
    return this.filtrePointFiltreFct({onClick: this.selectFilterCM}, props, id);
    
  }

  filtreElementTracer(props, id){
    return this.filtrePointFiltreFct({
        onClick: this.selectFilterTrace,
        traceElement: true,
        visibleByActive: true,
        activeByTracer: true,
        canTrash: false,
      }, props, id);
  }

  addPointFiltre({stateEnreg= true, nom, faIcon, color, visible = true, moiGps = false}){
    
    this.state.listePointFiltre.push({nom, faIcon, color, visible, moiGps, active: true, closedTrace: true, value: this.state.listePointFiltre.length});

    let __setState = stateEnreg?this.setState:this.superSetState;
    __setState({listePointFiltre: this.state.listePointFiltre});
  }

  addLogoPCart({stateEnreg= true, pointCarte, circle = false, visible = true, moiGps = false}){
    this.addLogo({point: pointCarte.getPoint(), faIcon: pointCarte.getLogo(), color: pointCarte.getCouleur(), circle, visible});
    this.addPointOpt({stateEnreg, nom: pointCarte.getNom(), visible});
    this.addPointFiltre({stateEnreg, nom: pointCarte.getNom(), faIcon: pointCarte.getLogo(), color: pointCarte.getCouleur(), visible: visible || moiGps, moiGps});

    this.listePointCarte.push(pointCarte);
    //console.log(this.listePointCarte)
    
  }

  center(point, duration=400){
    if(this.dejaCentreUneFois){
      this.leMap.getView().animate(
        {
          center: point,
          duration
        })
    }else{
      this.leMap.setView(new View({
        center: point,
        zoom: 18
      }));
      this.dejaCentreUneFois = true;
    }

  }

  centerPointCarte(duration = 400){
    var lePointActu;

    if(this.state.locatAllwaysToMoi){
      lePointActu= this.state.pointFixeCarte.source??this.listePointCarte[this.state.idxPointSelectedMoi]?.getPoint();
    }else{
      if(this.state.pointFixeCarte.cible !=null){
        lePointActu= this.state.pointFixeCarte.cible;
      }else{
        if(this.state.playback){
          lePointActu= this.pointPlayback;
        }else{
          lePointActu= this.listePointCarte[this.state.idxPointSelected]?.getPoint();
        }
      }
      
    }
    
    
    if(lePointActu) this.center(lePointActu, duration);
  }

  centerPointCarteIfLocat(duration = 400){
    if(this.state.locatAllways) this.centerPointCarte(duration);
  }

  centerIfNotRefresh(point, millis = 200){
    if(this.idTimeoutCenter) clearTimeout(this.idTimeoutCenter);
    this.idTimeoutCenter = setTimeout(()=>{
      this.center(point);
    }, millis)
  }
  
  widthVectorFromZoom({actuZ, x = [[0.5, 0], [0.002, 3]] }){
    let t = actuZ;
    var res = 0;
    for(let i in x){
      res += x[i]==0?0:(x[i][0]*Math.pow(t, x[i][1]));
    }
    return res;
  }

  init_detail_arrow_deg(){
    if(this.state.detail_arrow_deg==180) this.details_arrow_click();
  }

  init_pin_arrow_deg(){
    if(this.state.pin_arrow_deg==180) this.pin_arrow_click();
  }

  componentDidMount(){
    
    this.leMap = new Map({
      target: 'map',
      layers: [
        this.osm,
    
      ],
      
    });

    let mapElt = this.leMap.getViewport();
    mapElt.querySelectorAll("button").forEach(item=>{
      item.addEventListener("pointerdown", (e)=>{e.stopPropagation();});
      item.addEventListener("pointerup", (e)=>{e.stopPropagation();});
    });

    this.getPinStateReady = ()=>{
      var leQuel = null;
      if(this.state.pinLocatBtEtat['source']==1) leQuel = "source";
      else if(this.state.pinLocatBtEtat['cible']==1) leQuel = "cible";
      
      return leQuel;
    }

    this.leMap.on('click', (event)=>{
      let leQuel = this.getPinStateReady();
      if(leQuel !=null){
        this.locatGlobFct({leQuel, setThisOrSuper: true, 
          encreState: false, pinState: 2, 
          pointFixe: event.coordinate})
      }
      this.init_detail_arrow_deg();
      
      /*
      document.getElementById("map").scrollIntoView({
        behavior: 'smooth',
        block: "center",
        inline: "center",
      });
      */
    });

    this.leMap.on('moveend', (event)=>{
      let actuZoom = this.leMap.getView().getZoom();
      let precZoom = session.currentMapZoom??actuZoom;
      if(precZoom !== actuZoom){
        
        console.log({actuZoom});
        for(let leQuel in this.layerPointFixeCarte){
          if(this.layerPointFixeCarte[leQuel] != null){
            console.log({leQuel, pointFixeCarte :this.state.pointFixeCarte});
            
            this.drawPointFixeCarte({leQuel, refresh: true,
              pointFixeCarte: this.state.pointFixeCarte,
              actuZoom,
            });
            
            
          }
          
        }

        let fct_ol_style = ({color})=>{
          return new ol_style_Style({
            fill: new ol_style_Fill({ color: '#00FF00', weight: 4 }),
            stroke: new ol_style_Stroke({ color, width: this.widthVectorFromZoom({actuZ: actuZoom}), lineCap: "butt" })
          });
        };
        


        for(let id_str in session.vectorLineLayerTrace){
          let id = parseInt(id_str);
          let color = isNaN(id)?'#008FFD':this.listePointCarte[id].getCouleur();

          if(Array.isArray(session.vectorLineLayerTrace[id_str])){
            for(let i in session.vectorLineLayerTrace[id_str]){
              session.vectorLineLayerTrace[id_str][i].setStyle(fct_ol_style({color}));
            }
            
          }else{
            if(!isNaN(id)) session.vectorLineLayerTrace[id_str].setStyle(fct_ol_style({color}));
          }
          
        }

        
        
      }

      session.currentMapZoom = actuZoom;
    });
    
    
    //console.log("CarteShow Did Mount");
    //let listeEmetteurs = 

    //if(this.mapContRef) session.setSpinner(this.mapContRef);
    //setTimeout(()=>{session.removeSpinner()}, 5000);

    this.getMessageFailedGetOut = ({error = null})=> {
      alertMessage({
        titre: "Ma Position",
        message: <>Votre <strong>navigateur web</strong> ne permet pas à l'application de vous Géolocaliser. 
          ceci <strong>empêchera</strong> de suivre vos <strong>émetteurs</strong> à partir de votre position...
          {error !=null?(<><br/>Echec - {error.code} : {error.message}</>):<></>}</>, 
        boutons :[
          {titre: "Arrêter", color:"danger" ,fct: (e)=>{
            //alert("ca marche");
            if(this.watchPositionId !=null) navigator.geolocation.clearWatch(this.watchPositionId);
            this.watchPositionSuccessOrError({});
          }},
          {titre: "Ok", color:"primary" ,fct: (e)=>{
            
          }}
        ]
      });
      
    };

    this.watchPositionSuccessOrError = ({lngLat= [null, null]})=>{//, boucleTimeout= 10_000})=>{
      this.onGpsChangeInitFct({id: 0, lngLat});
      
      /*
      let lePoint = this.listePointCarte[0];
      let getPointAvant = lePoint.getPoint();
      lePoint.setLngLat(lngLat);
      let getPoint = lePoint.getPoint();

      console.log({getPointAvant, getPoint, lngLat})

      let ov = this.leMap.getOverlays().item(0);
      if(getPointAvant != getPoint){
        ov.setPosition(lePoint.getPoint());
        //this.centerPointCarteIfLocat();
        
        if(this.state.moiLocatBtOn){
          if(this.state.pointFixeCarte.source == null 
            
            && getPoint != null){
            
            
            
          } 
        }
        
      }
    */
    }

    this.watchPositionSuccess = (pos)=>{
      const coo = pos.coords;
      //console.log(coo);
      let distMax = 20;

      //if(coo.accuracy<=distMax || rebours==0){
        let distOk = coo.accuracy<=distMax;
        if(distOk){
          this.watchPositionSuccessOrError({
            lngLat: [coo.longitude, coo.latitude], 
          });
        }else{
          this.watchPositionErrorFctTimeout();
        }
        //boucleTimeout: distOk?2_500:10_000});

      //}else if(rebours >0) this.setMoiGps({rebours: (rebours-1)});
      
    }

    this.watchPositionErrorFctTimeout = ()=>{
      if(this.id_watchPositionErrorFctTimeout != null){
        clearTimeout(this.id_watchPositionErrorFctTimeout);
      }

      this.id_watchPositionErrorFctTimeout = setTimeout(()=>{
        this.watchPositionSuccessOrError({});
        clearTimeout(this.id_watchPositionErrorFctTimeout);
      }, 15_000);
      
    }

    this.watchPositionError = (error)=>{
      console.warn(`Erreur Gps -> ${error.code} : ${error.message}`);
      if(parseInt(error.code) ==1) this.getMessageFailedGetOut({error});
      else this.watchPositionErrorFctTimeout();
    }
   
    this.setMoiGps = ({})=>{//({rebours = 0, isBoucle= false, startBoucle =false})=>{
      //console.log("setMoiGps");
      if(this.watchPositionId !=null) navigator.geolocation.clearWatch(this.watchPositionId);

      const options = {
        enableHighAccuracy: true,
        timeout: 60_000,
        maximumAge: 0,
      };

      if(navigator.geolocation) this.watchPositionId =  navigator.geolocation.watchPosition(this.watchPositionSuccess, this.watchPositionError, options);
      else{
        this.getMessageFailedGetOut({});
    
      }
    }

    this.moiGps = (()=>{
      let logo = "fas fa-location";
      let couleur = "#008FFD";
      let propsPointMoi = {nom:"Moi", code_emetteur: null , lngLat: [null, null], logo, couleur, etat: null}

      let pointCarte = new PointCarte(propsPointMoi);
      
      this.addLogoPCart({stateEnreg: false, pointCarte, circle: true, visible: false, moiGps: true});

      //this.setMoiGps({});
      return pointCarte;
    })();

    

    myFetch({url: '/getGpsEmetteurs',cibleElt: this.mapContRef, fctSuccess: (jsn)=>{
      let fctEmetteurEtranger = ()=>{};
          if(this.emetteurEtranger){
            jsn.listeGpsEmetteurs.unshift({
              used: true, nom:"Carburant", ...this.emetteurEtranger,
            });
              
            fctEmetteurEtranger = (emt)=>{
              //console.log('emt -> ', emt);
              //console.log('jsn.listeGpsEmetteurs[0].code_emetteur -> ', jsn.listeGpsEmetteurs[0].code);
              
              if(emt.code == jsn.listeGpsEmetteurs[0].code) {
                let lePoint = this.listePointCarte[1];
                //kinRdCoo
                
                //console.log('this.listePointCarte -> ', this.listePointCarte[0]);
                
                //console.log('lePoint.getEtat() -> ', lePoint.getEtat());
                
                lePoint.setEtat(emt.vol_etat);
                lePoint.nom = `Carb. ${emt.nom} [ ${jsn.listeGpsEmetteurs[0].qteFuel} ]`;
                console.log('lePoint.getEtat() -> ', lePoint.getEtat());
                console.log('lePoint -> ', lePoint);
                console.log('emt -> ', emt);

                session.conxAsideNavEtat.reset({
                  etat: lePoint.getEtat(),
                  code_emetteur: lePoint.getCodeEmetteur()
                });

                //fctEmetteurEtranger = (emt)=>{}

                //jsn.listeGpsEmetteurs[0].etat = emt.etat;
              }
            }
          }

        
          console.log(jsn);

          let fctExecLogo = ()=>{
            this.superSetState({idxPointSelected: 1});
          }

          let fctBaseCentreV = (lePoint, idx)=>{
            this.center(lePoint.getPoint());
              session.conxAsideNavEtat.reset({
                etat: lePoint.getEtat(),
                code_emetteur: lePoint.getCodeEmetteur()
              });

          }

          let fctCentreV = (lePoint, idx)=>{}
          if(this.code_emetteur_initial){ 
            fctCentreV = (lePoint, idx)=>{
              if(this.code_emetteur_initial == lePoint.getCodeEmetteur()){
                let fctPourCentrer = (lePoint, idx)=>{
                  fctBaseCentreV(lePoint, idx);
                  fctCentreV = (lePoint, idx)=>{};

                  fctExecLogo = ()=>{
                    this.superSetState({idxPointSelected: idx});
                    //setTimeout(()=>{this.selectPointOpt_ref.value=idx;}, 100);
                    
                  }
                }
                if(!lePoint.getFantome()){
                  fctPourCentrer(lePoint);
                  fctExecLogo = ()=>{
                    this.superSetState({idxPointSelected: idx});
                    //setTimeout(()=>{this.selectPointOpt_ref.value=idx;}, 100);
                    
                  }
                }else{
                  fctExecLogo = ()=>{
                    (async ()=>{
                      for(var i=1, pt; i<this.listePointCarte; i++){
                        pt = this.listePointCarte[i];
                        if(!pt.getFantome()){
                          this.center(pt.getPoint());
                          break;
                        }
                      }
                    })();
                    this.superSetState({idxPointSelected: idx});
                    //setTimeout(()=>{this.selectPointOpt_ref.value=idx;}, 100);
                    
                  }
                  fctCentreV = (lePoint, idx)=>{
                    fctPourCentrer(lePoint, idx);
                  }
                }
                
              }
              
            }

          }else{
            fctCentreV = (lePoint, idx)=>{
              if(!lePoint.getFantome()){
                fctBaseCentreV(lePoint, idx);
                session.code_emetteur_actuel = lePoint.getCodeEmetteur();
                fctCentreV = (lePoint, idx)=>{};
              }
              
            }
          }
          
          var idx = 1;
          
         
          for(let emt of jsn.listeGpsEmetteurs){
            console.log('emt ext -> ', emt.code, ' : ', emt.nom);
            if(emt.used) if(emt.nom && emt.code && emt.logo_fa && emt.logo_color){
              if(!emt.vol_gp){
                emt.vol_gp={
                  lat:undefined,
                  long:undefined,
                  fantome: true,
                }
              }else{
                emt.vol_gp={
                  lat : parseFloat(emt.vol_gp.lat),
                  long: parseFloat(emt.vol_gp.long)
                }
              }
              console.log('emt int -> ', emt.code, ' : ', emt.nom);
              
              //console.log('etat: ',emt.vol_etat);
              let pointCarte = new PointCarte({fantome: emt.vol_gp?.fantome?true:false, nom:emt.nom, code_emetteur: emt.code , lngLat: [emt.vol_gp.long, emt.vol_gp.lat], logo: emt.logo_fa, couleur: emt.logo_color, etat: emt.vol_etat});
              this.addLogoPCart({stateEnreg: false, pointCarte});
              fctCentreV(pointCarte, idx);

              fctEmetteurEtranger(emt);
              idx++;
            }
          }

          fctExecLogo();
          
          console.log("In stateOfCarteshow : ", session.stateOfCarteshow);
          this.superSetState(session.stateOfCarteshow, false);
          
    }, fctFail: (jsn)=>{
      alertFetchFail(jsn);
      if(jsn.no_token){
        App = ()=>{
          loginShow();
        }

        App();
      }
    }});
    
    //console.log("Out stateOfCarteshow : ", session.stateOfCarteshow)
            
  }

  /*
  componentDidUpdate(prevProps, prevState, snapshot){
    console.log("this.state.playback : ", this.state.playback, " - prevState.playback : ", prevState);
    if(this.state.playback && !prevState.playback) this.playbackCalendarClick();
  }
*/
/* d-md-flex d-none */
  render(){ 
    
    return <div className=' m-0' id="mapCont" onClick={this.initialState} ref={(ref)=>{this.mapContRef = ref}}>
        <div className='mt-md-3'></div>
        
        <div className="row justify-content-center m-0 p-0">
          
       
          <div className="col-md-11 p-0 p-sm-1">
            <div className="card p-md-3 border-3 border-secondary">
                <div className="card-body p-0 p-md-3">
                
                  
                  <div id="collapseContMap" className='collapse d-md-block'>
                    <div className="d-container mx-2 mx-sm-4 mx-md-0" > 
                    
                      <div className='overflow-x-scroll'>
                        <div className='d-flex justify-content-between py-3'>
                          <h5 className="card-title p-0 m-0">Carte{(()=>{
                            let date = this.state.playback?.date;
                            return date?' - '+date.toLocaleDateString():''
                            
                          })()
                            }</h5>
                            <div className="ms-2">
                              <input ref={(ref)=>{this.resizeIcons_ref = ref; if(session.sizeIconMap && this.resizeIcons_ref) this.resizeIcons_ref.value= session.sizeIconMap;}} type="range"  orient="horizontal" id="resizeIcons" onChange={this.resizeIcons} />
                            </div>
                          </div>
                        {(()=>{
                          let pointCarteActu = this.listePointCarte[this.state.idxPointSelected];
                          return pointCarteActu?
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex' ><div><LogoFaIconCarte color={pointCarteActu.getCouleur()} faIcon={pointCarteActu.getLogo()} logo_exec_carte={(id)=>{
                          
                          //let idx = parseInt(this.selectPointOpt_ref[this.selectPointOpt_ref.selectedIndex].value);
                          //let tg = this.leMap.getOverlays().item(idx).getElement()
                          let lePointCarte = this.listePointCarte[this.state.idxPointSelected];
                          
                          setupVehicleShow({
                            createOrModif: false, 
                            nom: lePointCarte.getNom(),
                            code_emetteur: lePointCarte.getCodeEmetteur(),
                            logo_fa: lePointCarte.getLogo(), 
                            logo_color: lePointCarte.getCouleur()});
                          }} /></div>
                          
                            <ul className="overflow-auto ps-3">
                            
                              <li className="list-group-item card-subtitle text-muted">{pointCarteActu.getNom()}</li>
                              <li className="list-group-item card-subtitle text-muted d-none d-sm-inline">{pointCarteActu.getCodeEmetteur()}</li>
                              <li className="list-group-item card-subtitle text-muted">{this.state.fantomeActu?"Fantôme":(this.state.playbackLonglat?this.state.playbackLonglat:pointCarteActu.getLngLat()).map((val, id)=><span>{(id==0?'':', ')+val}</span>)}</li>
                              
                            </ul>
                            </div>
                            
                          </div>
                          
                        :<></>
                        })()
                        }
                        
                        
                      </div>
                      <div className="container p-0">

                      <div id="contBoardMap" className="collapseContIntraGps collapse show container row m-0 p-0 d-md-flex">
                        <div className="d-flex overflow-x-auto col-12 col-sm-auto mt-2 me-sm-2">
                          <button type="button" className={`btn btn-icon btn-success me-2 me-sm-1 ${this.state.playback?"d-none":""}`} data-bs-toggle="modal" data-bs-target="#filtreCarteModal">
                              <span className="fa fa-filter"></span>
                          </button>

                          <button type="button" className={`btn btn-icon btn-${(()=>{
                              let color = [false, false];
                              for(let id in this.state.listePointFiltre){
                                if(this.state.listePointFiltre[id].playingTrace){
                                  color[1] = true;
                                  break;
                                }else if(!this.state.listePointFiltre[id].closedTrace)
                                  color[0] = true;

                              }
                              return color[1]?"primary":(color[0]?"success":"secondary");

                            })()} me-2 me-sm-1`} data-bs-toggle="modal" data-bs-target="#traceFiltreModal">
                              <span className="fa fa-link"></span>
                          </button>

                          <button type="button" className={`btn btn-icon btn-${this.state.playbackTraceBtOn?"primary":"secondary"} ${this.state.playback?'':'d-none'} me-2 me-sm-1`} onClick={this.tracePlaybackClick}>
                            <span className="fa fa-bezier-curve"></span>
                          </button>

                          <button type="button" className={`btn btn-icon btn-${this.state.moiLocatBtOn?"primary":"secondary"} me-2 me-sm-1`} onClick={this.moiLocatClick}>
                            <span className="fas fa-location"></span>
                          </button>
                          <button id="playbackBt" onClick={this.playbackBtClick} type="button" className={`btn btn-icon btn-primary me-2 me-sm-1  ${this.state.playback?"d-none":""}`}>
                            <span className="fa fa-play"></span>
                          </button>
                          <button id="vehicleCreateBt" onClick={(e)=>setupVehicleShow({createOrModif: true})} type="button" className={`btn btn-icon btn-primary me-2 me-sm-1  ${this.state.playback?"d-none":""}`}>
                            <span className="fa fa-plus"></span>
                          </button>


                          <button type="button" className={`btn btn-icon btn-success ms-1 ${this.state.playback?"":"d-none"}`} onClick={e=>{this.playbackCalendarClick({bodyEvent: false});}}>
                            <span className="fa fa-calendar-alt"></span>
                          </button>
                          <button type="button" onClick={this.playbackBtClickRetour} className={`btn btn-icon btn-primary ms-1 me-1 ${this.state.playback?"":"d-none"}`}>
                            <span>Retour Carte</span>
                          </button>
                        </div>
                        
                        <div className="col-12 col-sm d-flex mt-2">
                          {this.getSelect.pointOpt(this.state.locatAllwaysToMoi)}
                          {/*
                          <select className="form-select m-0" name="select-vehicule" onChange={this.selectPointOpt} value={this.state.idxPointSelected} >
                            {this.state.listePointOpt.map((v,i)=>{
                              return this.optionPointOpt(v);
                            })}
                            
                          </select>
                          */}
                        </div>

                        
                      </div>
                      {/*
                      <div id="collapseContGps" className={`row container mb-2 mb-0 mx-0 p-0 ${this.state.moiLocatBtOn?"d-flex":"d-none"}`}>
                        <div class="col-12 mt-2 py-md-1 py-3"  style={{position: "relative"}} >
                            <div style={{height:"0.5px", width:"100%", margin:"auto", backgroundColor: "#0004", position: "relative"}}></div>
                            <div id="arrow-gps-menu-bt" className=" btn btn-primary btn-sm my-1 d-md-none" type="button" style={{position: "absolute", top: "-0.3em", left: "1em"}}  onClick={this.gps_menu_arrow_click} data-bs-toggle="collapse" data-bs-target=".collapseContIntraGps" aria-expanded="false" aria-controls="collapseContIntraGps">
                              <span><i id="arrow-gps-menu" className='fa fa-arrow-down p-0 m-0 arrow-rotate' style={{transform: `rotate(${this.state.gps_menu_arrow_deg}deg)`}}></i></span>
                            </div>
                        </div>
                        <div id="" class="collapseContIntraGps collapse d-md-flex" >

                          <div className='col-12 col-sm-7 row m-0'>
                            
                            <div className="ps-0 mt-2 col-auto d-flex align-items-center">
                              <span className=''>Source :</span>
                            </div>
                          
                            <button type="button" className={`col-auto me-1 mt-2 btn btn-icon btn-${this.state.encreLocatBtOn.source?"primary":"secondary"} me-1`} onClick={(e)=>this.encreLocatClickGlob({leQuel: 'source'})}>
                              <span className="fa fa-anchor"></span>
                            </button>
                            <button type="button" className={`col-auto me-1 mt-2 btn btn-icon btn-${this.colorBootFromPinState({leQuel:'source'})} me-1`} onClick={(e)=>this.pinLocatClickGlob({leQuel: 'source'})}>
                              <span className="fas fa-map-pin"></span>
                            </button>
                            <button type="button" className={`col-auto me-1 mt-2 btn btn-icon btn-${this.state.collimCibleBtOn?"success":"secondary"} me-1`} onClick={this.collimCibleClick}>
                              <span className="fas fa-crosshairs"></span>
                            </button>
                            
                            <select className="col form-select m-0 mt-2" name="select-vehicule" onChange={this.selectPointOptMoiLocat} value={this.state.idxPointSelectedMoi} >
                              {this.state.listePointOpt.map((v,i)=>{
                                if(i==0) return this.optionPointOpt({nom: "Moi", value:0});
                                else return this.optionPointOpt(v);
                              })}
                            </select>
                            
                          </div>
                            
                          
                          <div className='col-auto row m-0 mt-2'>
                            <div className="ps-0 col-auto d-flex align-items-center">
                              <span className=''>Cible :</span>
                            </div>
                            <button type="button" className={`col-auto me-1 btn btn-icon btn-${this.state.encreLocatBtOn.cible?"primary":"secondary"}`} onClick={(e)=>this.encreLocatClickGlob({leQuel: 'cible'})}>
                              <span className="fa fa-anchor"></span>
                            </button>
                            <button type="button" className={`col-auto me-1 btn btn-icon btn-${this.colorBootFromPinState({leQuel:'cible'})}`} onClick={(e)=>this.pinLocatClickGlob({leQuel: 'cible'})}>
                              <span className="fas fa-map-pin"></span>
                            </button>
                            <button id="itineraireBt" type="button" className={`col-auto me-1 btn btn-icon btn-${this.state.isItineraire?"warning":"secondary"}`} onClick={this.itineraire}>
                              <span className="fa fa-route"></span>
                            </button>
                          </div>
                          

                        </div>

                      </div>
                        */}
                      </div>
                    </div>
                  </div>
                    <div className="overflow-x-scroll mt-2 mt-md-0 p-md-3 pb-0" style={{position:"relative"}}>
                      <div id="map" className={`shadow p-1 bg-white rounded ${(this.state.pinLocatBtEtat.source==1 || this.state.pinLocatBtEtat.cible==1)?"cursor-crosshair":""} `} onPointerDown={this.mapMouseDown} onPointerUp={this.mapMouseUp}  >

                      </div>
                      
                      
                      <div className={`mb-md-3 btn btn-${this.state.isItineraire?"warning":"secondary"} ${this.state.moiLocatBtOn?"":"d-none"}`} id="itineraireBt" type="button"  onClick={this.itineraire} style={{position: "absolute", bottom: `${this.state.playback?7.9:5.9}em`, right: "2em"}} >  
                        <i className="fa fa-route"></i>
                      </div>

                      <div className={`mb-md-3 btn bg-secondary ${this.state.moiLocatBtOn?"d-flex":"d-none"}`} style={{position: "absolute", bottom: `${this.state.playback?5.2:3.2}em`, right: "5em"}} >
                        <div className='d-flex px-0' onClick={(e)=>{this.pin_arrow_click()}}>
                          <div className='btn py-0 px-1 btn-secondary'>
                            <i className="fa fa-arrow-left arrow-rotate text-white" style={{transform: `rotate(${this.state.pin_arrow_deg}deg)`}}></i>
                          </div>
                        </div>
                        <div className="collapse collapse-horizontal container px-0" id="collapseSourceCibleOnCarte">
                          <div className='d-flex px-0'>
                            {/*this.getBtn.encre(this.state.locatAllwaysToMoi)*/}
                            {this.getBtn.encre(this.state.locatAllwaysToMoi)}
                            {this.getBtn.pin(this.state.locatAllwaysToMoi)}
                            {this.getBtn.pen(this.state.locatAllwaysToMoi)}
                            
                          </div>
                        </div>
                        
                      </div>


                      <div className={`mb-md-3 btn btn-${this.state.locatAllways?'primary':'secondary'}`} onClick={this.locatAllwaysBt} style={{position: "absolute", bottom: `${this.state.playback?5.2:3.2}em`, right: "2em"}}>
                        <i className={`${this.state.locatAllwaysToMoi?'fas fa-location':'bi bi-geo-alt-fill'}`}></i>
                      </div>

                      <div className={`mb-md-3 form-check form-switch ${this.state.moiLocatBtOn?'':'d-none'}`} style={{position: "absolute", bottom: `${this.state.playback?3.5:1.5}em`, right: "1.9em"}} onClick={(e)=>{this.setState({locatAllwaysToMoi: !this.state.locatAllwaysToMoi})}}  >
                        <input className="form-check-input" type="checkbox" role="switch" checked ={this.state.locatAllwaysToMoi} />
                      </div>

                      {this.state.playback?<div className='mb-md-3' style={{position: "absolute", right: "0em", left: "0em", bottom: "2.3em", margin: "auto", width: "13em"}}>
                        <div className={`mb-1 justify-content-center ${(this.state.logoGoPointVisible?.gauche || this.state.logoGoPointVisible?.droite)?"d-flex":"d-none"}`}>
                          <button className='btn btn-secondary bord-rond-extremite-gauche' onClick={(e)=>{this.playbackGoPointVisible(false);}} type="button" disabled={!this.state.logoGoPointVisible?.gauche}><i className='fa fa-backward'></i></button>
                          <span className="" style={{marginLeft: "1px"}}></span>
                          <button className='btn btn-secondary bord-rond-extremite-droite' onClick={(e)=>{this.playbackGoPointVisible(true);}} type="button" disabled={!this.state.logoGoPointVisible?.droite}><i className='fa fa-forward'></i></button>
                        </div>
                        {/*this.state.logoGoPointVisible{gauche: false, droite: false}*/}
                        <div className='d-flex'>
                          <span className='btn btn-secondary bord-rond-extremite-gauche' onClick={this.playbackPlayOrPauseNegatif} ><i class={`fa fa-${this.state.onPlayingNegatif?"pause":"play"} inverse`}></i></span>
                          <span className="" style={{marginLeft: "1px"}}></span>

                          <span className='btn btn-secondary rounded-0' onClick={this.stopPlaybackEvent} ><i class="fa fa-stop"></i></span>
                          <span className="" style={{marginLeft: "1px"}}></span>
                          
                          <select className="form-select rounded-0 bg-secondary text-white" id="select_vitesse" ref={ref=>{this.select_vitesse_ref = ref}} >
                            <option className="" value="10">10x</option>
                            <option className="" value="30">30x</option>
                            <option className="" value="60" selected>60x</option>
                            <option className="" value="90">90x</option>
                            <option className="" value="150">150x</option>
                            <option className="" value="300">300x</option>
                            <option className="" value="900">900x</option>
                            <option className="" value="1500">1500x</option>
                            <option className="" value="3600">3600x</option>
                          </select>
                                
                          <span style={{marginLeft: "1px"}}></span>
                          <span className='btn btn-secondary bord-rond-extremite-droite' onClick={this.playbackPlayOrPausePositif} ><i class={`fa fa-${this.state.onPlayingPositif?"pause":"play"}`} ></i></span>
                          
                          
                        </div>
                       
                      </div>:<></>}
                      
                      <div className="d-md-none btn btn-primary my-1 rounded-0 rounded-bottom" type="button" style={{position: "absolute", top: "0", left:"0", right: "0", margin:'auto', width:"2.5em"}}  onClick={this.details_arrow_click}>
                        <span><i id="arrow-details" className='fa fa-arrow-down arrow-rotate' style={{transform: `rotate(${this.state.detail_arrow_deg}deg)`}}></i></span>
                      </div>
                      {this.state.playback?<div className='p-1 bg-secondary m-md-3'  style={{position: "absolute", top: "0.2em", right: "0.2em", borderBottomLeftRadius:"0.2em"}}><span><h5 className="card-title text-white rounded-0 p-0 m-0" >Play Back</h5></span></div>:<></>}
                      {this.state.playback?<input type="range" className="pb-0 mb-0" id="customRange1" min="0" max="8639" defaultValue="0"  value={this.state.playback.time/10} data-slider-tooltip="hide" data-slider-handle="round" onChange={
                          this.playbackRangeChange
                        } style={{position:"relative", marginTop:"0", bottom:"0.5em",width:"100%"}} />:<></>
                      }
                    </div>
                    {this.state.playback?<div className='text-center' style={{position:"relative", bottom:"0.8em"}}><span className=' border border-dark p-1'>{toFixedHHMMSS(this.state.playback.time)}</span></div>:<></>}
                    
                </div>
            </div>
          </div>
        </div>
        
<button id="detail_arrow_deg_bt_exec" onClick={e=>{e.stopPropagation();}} type="button" className="d-none" data-bs-toggle="collapse" data-bs-target="#collapseContMap" aria-expanded="false" aria-controls="collapseContMap"></button>        
<button id="pin_arrow_deg_bt_exec" onClick={e=>{e.stopPropagation();}} type="button" className="d-none" data-bs-toggle="collapse" data-bs-target="#collapseSourceCibleOnCarte" aria-expanded="false" aria-controls="collapseSourceCibleOnCarte"></button>        
<button ref={(ref)=>{this.playbackCalendarShow_ref = ref;}} onClick={e=>{e.stopPropagation();}} type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#calendarCarteModal"></button>        
<div className="modal fade pull-left" id="filtreCarteModal" tabIndex="-1" aria-labelledby="filtreCarteModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modalFiltre">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="filtreCarteModalLabel">Filtre des émetteurs</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        
      </div>
      <div className="modal-body" onClick={(e)=>{e.stopPropagation();}}>
        <div className="list-group" >
        {this.state.listePointFiltre.map((v,i)=>{
          return this.filtrePointFiltre(v, i);
        })}
          
        </div>
      </div>
      <div className="modal-footer">
        <div className='container'>
          <div className="d-flex justify-content-center mb-1">
            <span className={`btn ${this.checkAllSelectFilter()?'coche btn-primary':'btn-outline-primary'} m-1 kbtn-selectAll`} onClick={this.filtreSelectAll}>
              <i className='fa fa-check-double'></i>
            </span>
            
            <span className={`btn btn-${this.state.deleteEmetteurShowedBtOn?'':'outline-'}danger m-1 kbtn-trash`} onClick={this.showDeleteButtons}>
              <i className='fa fa-trash'></i>
            </span>
            
          </div>
        </div>
      
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade pull-left" id="traceFiltreModal" tabIndex="-1" aria-labelledby="traceFiltreModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="traceFiltreModalLabel">Elements à tracer</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        
      </div>
      <div className="modal-body" onClick={(e)=>{e.stopPropagation();}}>
        <div className="list-group" >
        {this.state.listePointFiltre.map((v,i)=>{
          return this.filtreElementTracer(v, i);
        })}
  
        </div>
      </div>
      <div className="modal-footer">
        <div className='container'>
          <div className="d-flex justify-content-center mb-1">
            <span className={`btn ${this.checkAllSelectFilterTrace()?'coche btn-primary':'btn-outline-primary'} m-1 kbtn-selectAll`} onClick={this.filtreSelectTraceAll} >
              <i className='fa fa-check-double'></i>
            </span>

            <span className={`btn btn-warning m-1 ${(()=>{
                let visible = false;

                let fctCheck = this.state.locatAllwaysToMoi?
                  (id)=>this.state.listePointFiltre[id].active &&
                    this.state.listePointFiltre[id].playingTrace

                  :(id)=>this.state.listePointFiltre[id].active &&
                    this.state.listePointFiltre[id].playingTrace &&
                    id!=0;

                for(let id in this.state.listePointFiltre){
                  if(fctCheck(id)){
                    visible = true;
                    break;
                  }
                }
                
                return visible?"":"d-none";

              })()}`} onClick={this.stopTraceEmetteurAll} >
              <i className='fa fa-check-double'></i> <i className='fa fa-stop'></i>
            </span>

            <span className={`btn btn-success m-1 ${(()=>{
                let visible = false;
                
                let fctCheck = this.state.locatAllwaysToMoi?
                  (id)=>this.state.listePointFiltre[id].active &&
                    !this.state.listePointFiltre[id].playingTrace

                  :(id)=>this.state.listePointFiltre[id].active &&
                    !this.state.listePointFiltre[id].playingTrace &&
                    id!=0;
                  
                
                for(let id in this.state.listePointFiltre){
                  if(fctCheck(id)){
                    visible = true;
                    break;
                  }
                }
                
                return visible?"":"d-none";

              })()}`} onClick={this.playTraceEmetteurAll} >
              <i className='fa fa-check-double'></i> <i className='fa fa-play'></i>
            </span>

            <span className={`btn btn-success m-1 ${(()=>{
                let visible = false;
                
                let fctCheck = this.state.locatAllwaysToMoi?
                  (id)=>this.state.listePointFiltre[id].active &&
                    this.state.listePointFiltre[id].activeTrace &&
                    !this.state.listePointFiltre[id].playingTrace
                    

                  :(id)=>this.state.listePointFiltre[id].active &&
                    this.state.listePointFiltre[id].activeTrace &&
                    !this.state.listePointFiltre[id].playingTrace &&
                    id!=0;
                  
                
                for(let id in this.state.listePointFiltre){
                  if(fctCheck(id)){
                    visible = true;
                    break;
                  }
                }
                
                return visible?"":"d-none";

              })()}`} onClick={this.playTraceEmetteurAllSelected} >
              <i className='fa fa-check'></i> <i className='fa fa-play'></i>
            </span>
            
            <span className={`btn btn-danger m-1 ${(()=>{
                let visible = false;
                
                let fctCheck = this.state.locatAllwaysToMoi?
                  (id)=>this.state.listePointFiltre[id].active &&
                    !this.state.listePointFiltre[id].closedTrace

                  :(id)=>this.state.listePointFiltre[id].active &&
                    !this.state.listePointFiltre[id].closedTrace &&
                    id!=0;
                  
                
                for(let id in this.state.listePointFiltre){
                  if(fctCheck(id)){
                    visible = true;
                    break;
                  }
                }
                
                return visible?"":"d-none";

              })()}`} onClick={this.closeTraceEmetteurAll}>
            <i className='fa fa-check-double'></i> <i className='fa fa-close'></i>
            </span>
            
          </div>
        </div>
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" >Ok</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade pull-left" id="writePinModal" tabIndex="-1" aria-labelledby="writePinModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content" onClick={(e)=>{e.stopPropagation();}}>
      <div className="modal-header">
        <h5 className="modal-title" id="writePinModalLabel">Ecrire les coordonnées</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e=>document.body.click()} ></button>
        
      </div>
      <div className="modal-body d-flex justify-content-center" onClick={(e)=>{e.stopPropagation();}}>
        <form action="nous-contacter" method="post" className="php-email-form">
          <div className="row gy-4">

            <div className="col-md-6">
            <label htmlFor="nomEmetteur" className="form-label">Long : </label>
              
              <input type="number" value={this.state.pinCoords[0]} name="longitude" className="form-control" placeholder="Longitude" onChange={(e)=>{this.state.pinCoords[0] = e.target.value; this.superSetState({pinCoords: this.state.pinCoords});}} required />
            </div>

            <div className="col-md-6 ">
            <label htmlFor="nomEmetteur" className="form-label">Lat : </label>
             
              <input type="number" value={this.state.pinCoords[1]}  className="form-control" name="latitude" placeholder="Latitude" onChange={(e)=>{this.state.pinCoords[1] = e.target.value; this.superSetState({pinCoords: this.state.pinCoords});}} required />
            </div>
          </div>
        </form>

      </div>
      <div className="modal-footer">
        
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.writePinCoords}>Ok</button>
      </div>
    </div>
  </div>
</div>

<div className="modal fade pull-left" id="calendarCarteModal" tabIndex="-1" aria-labelledby="calendarCarteModalLabel" aria-hidden="true" >
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modalCalendar">
    <div className="modal-content" onClick={(e)=>{e.stopPropagation();}}>
      <div className="modal-header">
        <h5 className="modal-title" id="calendarCarteModalLabel">Choix de la date</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e=>document.body.click()} ></button>
        
      </div>
      <div className="modal-body d-flex justify-content-center" onClick={(e)=>{e.stopPropagation();}}>
        
        <div className="datepicker">
        <div className="datepicker-top">
          <div className="btn-group">
            
          <button className="tag" onClick={(e)=>{
            this.calendarSetDateEncours(new Date());
          }}>Aujourd'hui</button>
          <button className="tag" onClick={(e)=>{
            this.calendarSetDateEncours(new Date(this.state.playback.date.getTime()));
          }}>Encours</button>
            
          </div>
          <div className="month-selector">
            <button className="btn btn-icon btn-primary" onClick={(e)=>{
              this.calendarAjoutMois(-1);
            
            }} disabled={this.calendarMoisPrecedentExistePas()}><i className="fa fa-chevron-left"></i></button>
            <span className="month-name">{getNomMois(this.state?.calendar?.mois)} {this.state?.calendar?.annee}</span>
            <button className="btn btn-icon btn-primary" onClick={(e)=>{
              this.calendarAjoutMois(1);
            }} disabled={this.calendarMoisProchainExistePas()} ><i className="fa fa-chevron-right"></i></button>
          </div>
        </div>
        <div className="datepicker-calendar">
          <span className="day">Lu</span>
          <span className="day">Ma</span>
          <span className="day">Me</span>
          <span className="day">Je</span>
          <span className="day">Ve</span>
          <span className="day">Sa</span>
          <span className="day">Di</span>
          {(({mois=this.state?.calendar?.mois, 
            annee=this.state?.calendar?.annee, 
            dateEncours=this.state?.calendar?.dateEncours})=>{
              if(mois !=null && annee !=null && dateEncours !=null){
              
                let laDate = new Date(annee, mois);
                let jrMois = (laDate.getDay()+6)%7;

                let laDateFinMois = new Date(annee, mois+1, 0);
                let numDays = laDateFinMois.getDate();
                let jrFinMois = (laDateFinMois.getDay()+6)%7;
                
                let numMPasse = new Date(annee, mois, 0).getDate();

                let lstESelectJour = (moisDiff) => {
                  switch(moisDiff){
                    case 0 :
                      return this.calendarSelectJourClick.moisEncours;
                    case -1 :
                      return this.calendarSelectJourClick.moisPrecedent;
                    case 1: 
                      return this.calendarSelectJourClick.moisSuivant;

                    default :
                      return this.calendarSelectJourClick.moisEncours;
                  }
                }

                let phraseCeJourExiste = ((session.calendarGps[session.code_emetteur_actuel]??{})[annee]??{})[mois+1]??""
                var dateJE = new Date(annee, mois-1);
                
                let phraseCeJourExisteMoisPrec = ((session.calendarGps[session.code_emetteur_actuel]??{})[dateJE.getFullYear()]??{})[dateJE.getMonth()+1]??""
                dateJE = new Date(annee, mois+1);
                let phraseCeJourExisteMoisSuiv = ((session.calendarGps[session.code_emetteur_actuel]??{})[dateJE.getFullYear()]??{})[dateJE.getMonth()+1]??""
                //console.log("session.calendarGps["+session.code_emetteur_actuel+"]["+annee+"] -> ", (session.calendarGps[session.code_emetteur_actuel]??{})[annee]??{});
                //console.log("phraseCeJourExisteMoisPrec -> ", phraseCeJourExisteMoisPrec);
                //console.log("phraseCeJourExiste -> ", phraseCeJourExiste);
                //console.log("phraseCeJourExisteMoisSuiv -> ", phraseCeJourExisteMoisSuiv);
                let moisPrecedentExistePas = this.calendarMoisPrecedentExistePas();
                let memeMois_memeAnnee = dateEncours.getMonth()==mois && dateEncours.getFullYear()==annee;

                let currentDay = memeMois_memeAnnee?({jour, faded=false, moisDiff=0, phraseCeJourExiste="", ceJourEstDuMoisPrec=false})=>{
                    let ceJourExiste = parseInt(phraseCeJourExiste[jour-1]??"0")?true:false;
                    let invisible = ceJourEstDuMoisPrec && moisPrecedentExistePas;

                    if(jour==dateEncours.getDate() && !faded) this.jourSelectedButNotExist = !ceJourExiste;
                    //console.log(jour, ' : ', this.jourSelectedButNotExist);


                    return <button className={`date${faded?' faded':''}${jour==dateEncours.getDate() && !faded && ceJourExiste?' current-day':''}${invisible?' invisible':''}`} onClick={invisible?null:lstESelectJour(moisDiff)} disabled={!ceJourExiste}>{jour}</button>;
                  }:({jour, faded = false, moisDiff=0, phraseCeJourExiste="", ceJourEstDuMoisPrec=false})=>{
                    let ceJourExiste = parseInt(phraseCeJourExiste[jour-1]??"0")?true:false;
                    let invisible = ceJourEstDuMoisPrec && moisPrecedentExistePas;
                    return <button className={`date${faded?' faded':''}${invisible?' invisible':''}`} onClick={invisible?null:lstESelectJour(moisDiff)} disabled={!ceJourExiste}>{jour}</button>;
                  };

                //console.log(laDate);
                //console.log("0 : ", parseInt("0")?true:false)
                //console.log("1 : ", parseInt("1")?true:false)
                
                let arrayJrsMoisSuivant = this.calendarMoisProchainExistePas()?[]:[...Array(6-jrFinMois).keys()].map((v,i)=>{
                  return currentDay({jour: i+1, faded: true, moisDiff: +1, phraseCeJourExiste: phraseCeJourExisteMoisSuiv});//<button className="date faded">{i+1}</button>;
                });

                return [
                  ...[...Array(numDays+jrMois).keys()].map((v, i)=>{
                    if(i<jrMois) return currentDay({jour: i-jrMois+1+numMPasse, faded: true, moisDiff:-1, phraseCeJourExiste: phraseCeJourExisteMoisPrec, ceJourEstDuMoisPrec: true});//<button className="date faded">{i-jrMois+1+numMPasse}</button>;
                    else return currentDay({jour: i-jrMois+1,  phraseCeJourExiste: phraseCeJourExiste});//<button className="date">{i-jrMois+1}</button>;
                  }),
                  ...arrayJrsMoisSuivant
                ];
                
                
              }else return <></>
              
            
          })({})}

          
          
        </div>
      </div>
        
      

      </div>
      <div className="modal-footer">
        
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.playbackCalendarOkClick} disabled={this.jourSelectedButNotExist}>Ok</button>
      </div>
    </div>
  </div>
</div>

        
      </div>;
  }
}



class Profil extends React.Component{
  constructor(props){
    super(props)
    this.state={
      user: props.user,
    }

    this.deconnexionInt = (event)=>{
      event.preventDefault();
      deconnexion();
    }

    this.parametre = (e)=>{
      e.preventDefault();
      session.parametreCompte();
      /*
      myFetch({url:'/parametre_compte', cibleElt: document.getElementById('root'), fctSuccess: (dataNet)=>{
        modifierCompteShow({dataCompte:dataNet.dataCompte});
      }});
      */
      
    }

    session.conxProfil.reset = (props)=>{this.reset(props);};
    
  }

  reset(props){
    this.setState({user: props.user});
  }

  render(){
    return <>
    <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
        <span className="border border-dark border-1 rounded-circle p-2 fa fa-user"></span>
        <span className="d-none d-md-block dropdown-toggle ps-2">{this.state.user.pseudo??""}</span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header" key={KKey.newCle()}>
          <h6>{this.state.user.prenom??""} {this.state.user.nom??""}</h6>
          <span>{this.state.user.pseudo??""}</span>
        </li>
        <li key={KKey.newCle()}>
          <hr className="dropdown-divider"/>
        </li>

        <li key={KKey.newCle()}>
          <a className="dropdown-item d-flex align-items-center" href="#" onClick={this.parametre}>
            <i className="bi bi-person"></i>
            <span>Paramètres</span>
          </a>
        </li>
        
        <li key={KKey.newCle()}>
          <hr className="dropdown-divider"/>
        </li>

        <li key={KKey.newCle()}>
          <a className="dropdown-item d-flex align-items-center" href="#" onClick={this.deconnexionInt}>
            <i className="bi bi-box-arrow-right"></i>
            <span>Déconnexion</span>
          </a>
        </li>

      </ul>
    </>
  }
}

function ReadNotification(props){

  let handlerOk = (e)=>{
    e.preventDefault();
    props.fctOk();
  }

  console.log(props);

  return (
    <div className="card">
      
      <div className="card-header text-center">
        <i className={props.propsIn.notif.signe} style={{fontSize: "1.2em", marginRight: "0.5em"}}></i>
        
        <span style={{fontSize: "1.2em"}}>{props.propsIn.notifOrMsg?"Notification":"Message"}</span>
      </div>
      <div className="card-body">
        
        <h5 className="card-title">{props.propsIn.notif.sujet}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{formatDateTime(new Date(props.propsIn.notif.createdAt))}</h6>
        <p></p>
        <p className="card-text">{props.propsIn.notif.message}</p>
        
        <div className='d-flex justify-content-end'><a href="#" className="btn btn-primary" onClick={handlerOk}>Ok</a></div>
      </div>
    </div>
    
  )

}

class ListeNotifications extends React.Component{
  
  constructor(props){
    super(props)

    this.readNotif = (e)=>{
      e.preventDefault();
      e.stopPropagation();
      document.body.click();

      var tg = e.target;
      while(!tg.classList.contains("trReadNotif")){
        tg = tg.parentElement;
      }

      readNotifFct({fctOk: ()=>{
        let propsIn = {notifOrMsg: this.notifOrMsg, numPage: this.state.data.numPage};
        root.render(<ListeNotifications propsIn={propsIn} />);
        //session.conxListeNotifications.reset(propsIn);
        
      }, notifOrMsg: this.notifOrMsg, idNotif: tg.id});
      
    }

    this.refreshPage = (numPage)=>{
      myFetch({url: `/liste_notifications/${this.notifOrMsg}/${numPage}`, cibleElt: document.getElementById('root'),fctSuccess: (jsn)=>{
        jsn.numPage = parseInt(jsn.numPage)
        jsn.nbrPage = parseInt(jsn.nbrPage)
        jsn.donParPage = parseInt(jsn.donParPage)
        //console.log(jsn);
        this.setState({data: jsn});
      }})
        
    }

    this.gotoPage = (e)=>{
      gotoPageGlob(e, this);
    }
    
    session.conxListeNotifications.reset = (propsIn)=>{
      this.reset(propsIn);
    }

    this.reset(props.propsIn);
    
  }

  reset(propsIn){
    //this.state={props}

    this.state = {
      
      data: {
        titre: "Encours de récupération des données..." ,
        donnees: []
      }
    }
    this.notifOrMsg = propsIn.notifOrMsg;

    this.refreshPage(propsIn.numPage??1);
    
    //this.setApres(props);
    
  }
  

  /*
  setState(props){
    //super.setState(props);
    console.log('state : ', props);
  }
  */

  render(){

    const{data} = this.state;
    const leThis = this;

    return <>
    <div className="pagetitle">
      <h5 className='card-title'>{data.titre}</h5>
      
    </div>
    <table className="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Date</th>
          <th scope="col">Sujet</th>
          <th scope="col">Résumé</th>
          

        </tr>
      </thead>
      <tbody>
        { (function() {
          var content = []
          var don
          for(var i=0; i<data.donnees.length; i++){
            don = data.donnees[i]
            content.push(<tr onClick={leThis.readNotif} role="button" className={`trReadNotif${don.vue?"":" table-primary"}`} id={don.idNotif}>
              <th scope="row">{(data.numPage-1)*data.donParPage+i+1}</th>
              <td>{formatDateTime(new Date(don.createdAt))}</td>
              <td><i className={don.signe}></i> {don.sujet}</td>
              <td>{don.resume}</td>
            </tr>)
          }
          return content
        }
        )()
        }
      
        
      </tbody>
    </table>
    <div className="d-flex justify-content-center">
      
        <nav aria-label="Page">
          {pagination(data.numPage, data.nbrPage, this.gotoPage)}
        </nav>
      
      
      </div>
    </>
  }
  
}

function readNotifFct({notifOrMsg, idNotif, fctOk}){
  myFetch({url:`/readNotif/${notifOrMsg}/${idNotif}`, cibleElt: document.getElementById('root'), fctSuccess: (jsn)=>{
    carteHide(()=>{root.render(<ReadNotification propsIn={jsn} fctOk={fctOk} />)});
    document.body.click();
  }, fctFail: (jsn)=>{
    if(jsn.msg == null) jsn.msg = "Fail for markReadNotifications";
    alertFetchFail(jsn);
  }})
  
}

class Notifications extends React.Component{
  constructor(props){
    super(props);
    this.state={
      notifs: props.notifs.data,
      nonVue: props.notifs.nonVue,
    }

    this.notifOrMsg = true;

    this.readNotif = (e)=>{
      e.preventDefault();
      e.stopPropagation();
      document.body.click();

      var tg = e.target;
      while(!tg.classList.contains("notification-item")){
        tg = tg.parentElement;
      }

      readNotifFct({fctOk: ()=>{App();}, notifOrMsg: this.notifOrMsg, idNotif: tg.id});
      
    }

    //this.deroule = false;

    this.markReadNotifications = (e)=>{
      e.preventDefault();
      

      if(this.notifOrMsg){
        

        myFetch({url:'/markReadNotifications', cibleElt: null, fctSuccess: (jsn)=>{
          console.log("Success for markReadNotifications");
          this.setState({nonVue: 0});
        }, fctFail: (jsn)=>{
          if(jsn.msg == null) jsn.msg = "Fail for markReadNotifications";
          alertFetchFail(jsn);
        }})
        
      }

      //this.deroule = !this.deroule;
      
    }

    this.liste_notifications = (e)=>{
      e.preventDefault();
      carteHide(()=>{
        let propsIn = {notifOrMsg: this.notifOrMsg};
        root.render(<ListeNotifications propsIn={propsIn} />);
        session.conxListeNotifications.reset(propsIn);
      });
    }

    session.onNotificationChange.push((don)=>{
      if(this.notifOrMsg){
        console.log("onNotificationChange -> ", don);
        this.setState({
          notifs: don.obj.data,
          nonVue: don.obj.nonVue
        });
      }
      
    });

    session.onNotificationAdd.push((don)=>{
      
      
      if(this.notifOrMsg){
        console.log("onNotificationAdd -> ", don.obj.data);
        
        if(this.state.notifs.length>=3) this.state.notifs.pop();
        this.state.notifs.unshift(don.obj.data);
        //this.state.notifs.pop();
        this.setState({
          notifs: this.state.notifs,
          nonVue: parseInt(this.state.nonVue??0)+1,
        });
        
      }
      
    })

    session.onMsgToUserChange.push((don)=>{
      if(!this.notifOrMsg){
        console.log('msgToUser -> ', don);
        this.setState({
          notifs: don.obj.data,
          nonVue: don.obj.nonVue
        });
      }
      
    });

    session.conxNotifications[this.getThisName()] = (props)=>{this.reset(props);}
    
    
  }

  reset(props){
    //console.log(this.getThisName(), ' : ', props);

    this.setState({
      notifs: props.notifs.data,
      nonVue: props.notifs.nonVue,
    });
    
  }

  getThisName(){return 'Notifications'}

  render(){
    
    return <li className="nav-item dropdown">
      <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown" onClick={this.markReadNotifications}>
      <i className={`${this.notifOrMsg?"bi bi-bell":"bi bi-chat"}`}></i>
      <span className="d-none d-lg-inline badge bg-primary badge-number rounded-pill">{this.state.nonVue}</span>
    </a>

    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
      <li className="dropdown-header">
        Vous avez {this.state.nonVue} {`${this.notifOrMsg?"notifications":"messages"}`}
        <a href="#" onClick={this.liste_notifications}><span className="d-none d-lg-inline badge rounded-pill bg-primary p-2 ms-2">Voir tout</span></a>
      </li>
      <li>
        <hr className="dropdown-divider"/>
      </li>

      {
      this.state.notifs.map((val, i)=>{
        //let val = this.state.
        //console.log('TEST NOTIF VAL : ', val);
        return <>
        <li className="notification-item" onClick={this.readNotif} id={val.idNotif} role="button">
          <i className={val.signe}></i>
          <div>
            <h4>{val.sujet}</h4>
            <p>{val.resume}</p>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>
    </>
        
      })
    }

      <li className="dropdown-footer">
        <a href="#" onClick={this.liste_notifications}>Voir {`${this.notifOrMsg?"toutes les notifications":"tous les messages"}`}</a>
      </li>

    </ul>

  </li>
    
    
    
    
  }
}

class MsgToUsers extends Notifications{
  constructor(props){
    super(props);
    this.notifOrMsg = false;
  }

  getThisName(){return 'MsgToUsers'};
}
//render de create
//const session.user = {nom: ""} 
profilRoot.render(<></>);

function ouvrirCompte(val){
  
  session.changeToken(val.data.token??"");
  session.user = val.data;

  //if(!session.socket){connectionSocket();}
  
  //session.socket?.emit('give_token', session.token);
  

  console.log("user : ", val);

  profilRoot.render(<Profil user={session.user} />);
  notificationsRoot.render(<Notifications notifs={val.notifications} />)
  msgToUserRoot.render(<MsgToUsers notifs={val.msgToUsers} />)

  //resets
  session.conxProfil.reset({user: session.user});
  session.conxNotifications['Notifications']({notifs: val.notifications})
  session.conxNotifications['MsgToUsers']({notifs: val.msgToUsers})

  document.querySelectorAll("#parametreBt, #deconnexionBt, #hrListParamSidebar, #contArrow-hrListParamSidebar").forEach(item=>{
    item.classList.remove('d-none');
  });
  document.querySelector('#contListParamSidebar').classList.remove('d-block');
  

  App = ()=>{
    return carteShow({code_emetteur: session.code_emetteur_actuel});
  }
  App();
}

fonctions.ouvrirCompte = ouvrirCompte;


function deconnexion(){
  console.log('on se deconnecte');
  myFetch({url:'/deconnexion',
        method: 'GET',
        cibleElt: document.getElementById('root'), 
        fctSuccess: (val)=>{
          session.init();
          document.querySelectorAll("#parametreBt, #deconnexionBt, #hrListParamSidebar, #contArrow-hrListParamSidebar").forEach(item=>{
            item.classList.add('d-none');
          });
          document.querySelector('#contListParamSidebar').classList.add('d-block');

          profilRoot.render(<></>);
          App = ()=>{
            loginShow();
          }

          App();
        }
      })
}

function carteShow(props){
  root.render(<CarteShow code_emetteur={props.code_emetteur} emetteur_etranger={props.emetteur_etranger} />);

}

fonctions.carteShow = carteShow;


function carteHide(fct){
  //document.querySelector('#mapCont').style.display = "none";
  fct();
}

fonctions.carteHide = carteHide;


/*
function odometreEvolution(){
  carteHide(()=>{root.render(<Odometre/>)})
}

function fuelEvolution(){
  
  //alert("fuel Evolution");
  carteHide(()=>{root.render(<Fuel/>);})

}
*/
function nousContacterShow(){
  carteHide(()=>{root.render(<NousContacter fonctions={fonctions} session={session} />);})
  
}

function downloadShow(){
  carteHide(()=>{root.render(<Download/>);})
  
}

function setupVehicleShow(propsIn){
  carteHide(()=>{root.render(<SetupVehicle  propsIn={propsIn} />);});
}

function refreshPageGlob(titre, component, numPage){
  
  
  myFetch({url:`/${titre}/${component.donPage.code_emetteur}/${numPage}`,cibleElt: document.getElementById('root'), fctSuccess: (dataNet)=>{
    dataNet.numPage = parseInt(dataNet.numPage)
    dataNet.nbrPage = parseInt(dataNet.nbrPage)
    dataNet.donParPage = parseInt(dataNet.donParPage)
    component.setState({data: dataNet})
  },fctFail: (dataNet)=>{
    
    alertFetchFail(dataNet);

    if(dataNet.no_token){
      App = ()=>{
        loginShow();
      }

      App();
    }
  }})
    
}

fonctions.refreshPageGlob = refreshPageGlob;

function gotoPageGlob(event, component){
  event.preventDefault()
  let numPage = (event?.target?.id).toString().substring(3); 
  console.log("numPage : ", numPage);
  component.refreshPage(numPage);
}

fonctions.gotoPageGlob = gotoPageGlob;

function lignePagination(pg, select, fct){
  return select==pg?(
    <li className="page-item active" aria-current="page" key={KKey.newCle()}>
      <a className="page-link" href="#" onClick={fct} id={`pg_${pg}`}>{pg}</a>
    </li>):
    (<li className="page-item" key={KKey.newCle()}><a className="page-link" href="#"  onClick={fct} id={`pg_${pg}`}>{pg}</a></li>);
}

function pagination3Points(){return <li className="page-item" key={KKey.newCle()}><span className="page-link" href="#">...</span></li>}

function pagination(select, tot, fct){
  console.log(`lignePagination : tot=${tot+1-1}, select=${select+1-1}`)

  if(isNaN(select) || isNaN(tot) || (tot<1)) return <></>
  else
    return <ul className="pagination">
        <li className={`page-item ${(select==1)?"disabled":""}`}  key={KKey.newCle()}>
          <a className="page-link" href="#" onClick={fct} id={`pg_${select-1}`}>Previous</a>
        </li>
        {lignePagination(1, select, fct)}
        {
          (tot<=5)?([... Array(tot-1).keys()].map((i)=>{
            return lignePagination(i+2, select, fct);
        })):(

          (select<=3)?([...[... Array(3).keys()].map((i)=>{
            return lignePagination(i+2, select, fct);
            }), pagination3Points(), 
                lignePagination(tot, select, fct)]):
          
          (select>=(tot-2))?([pagination3Points(),
              ...[... Array(4).keys()].map((i)=>{
                return lignePagination(tot+i-3, select, fct);
            })
                ]):
          
          ([pagination3Points(),
              ...[... Array(3).keys()].map((i)=>{
                return lignePagination(select-1+i, select, fct);
            }), pagination3Points(), 
            lignePagination(tot, select, fct)
                ])

        )}
        
        
        <li className={`page-item ${(select==tot)?"disabled":""}`} key={KKey.newCle()}>
          <a className="page-link" href="#" onClick={fct} id={`pg_${select+1}`}>Next</a>
        </li>
      </ul>
}

fonctions.pagination = pagination;


var App = ()=>{
  return loginShow(true);
  //return setupVehicleShow();
  //return carteShow();

}

fonctions.App = ()=>App();



function Download(){

  return(
    <>
        <div className="pagetitle d-flex mt-3 ms-3 justify-content-center">
          <h5 className='card-title'>Téléchargements</h5>
          
        </div>
        <div className="d-flex justify-content-center">
          <div className="col-md-8">
            <div className="card p-4">
              <ul class="list-group">
                <a class="list-group-item" href="downloads/sunspy.0.1.0.apk">Sun Spy 0.1.0</a>
              </ul>
            </div>

          </div>
        </div>
        
    </>
  )
}

class SetupVehicle extends React.Component{
  constructor(props){
    super(props);
    let propsIn = props.propsIn;
    
    this.state = {
      liste_code_emetteur_dispo: null,
      createOrModif: propsIn.createOrModif,
      logo_fa: propsIn.logo_fa?propsIn.logo_fa:'fa fa-home',
      logo_color : propsIn.logo_color?propsIn.logo_color:'#6c757d',
      code_emetteur_init: propsIn.code_emetteur?propsIn.code_emetteur:null,
    };

    this.state.liste_code_emetteur_dispo = propsIn.code_emetteur?[<option selected value={this.state.code_emetteur_init}>{this.state.code_emetteur_init}</option>]:[];

    this.emetteurSelectionne = this.state.code_emetteur_init??=null;

    this.supprimerEmetteurConfirmer = (e)=>{
      if(this.emetteurSelectionne){
        myFetch({url: '/supprimerEmetteur', method: 'POST', cibleElt: document.getElementById('root'), 
        body: JSON.stringify({
          code_emetteur: this.emetteurSelectionne
        }), fctSuccess: (val)=>{
          console.log('Reponse = ', val);

          console.log(`Emetteur "${val.code_emetteur}" supprimé avec succès`);

          var idx = 0, non_trouve = true;
          while(idx<this.state.liste_code_emetteur_dispo.length && non_trouve){
            if(this.state.liste_code_emetteur_dispo[idx].props.value == val.code_emetteur){
              non_trouve = false;
              this.state.liste_code_emetteur_dispo.splice(idx, 1)
              this.setState({liste_code_emetteur_dispo: this.state.liste_code_emetteur_dispo});
            }

            idx++;
          }
        }, fctFail: (val)=>{

          alertFetchFail(val);

          App = ()=>{
            loginShow();
          }

          App();
        }})
      }
    };



    this.nom_init = propsIn.nom?propsIn.nom:"";

    this.handleSubmit = (e)=>{
      e.preventDefault();
      e.stopPropagation();
      document.body.click();
      console.log(this.emetteurSelectionne);
     //let sel = this.codeEmetteurSelect_ref;
     if(this.emetteurSelectionne){
      let don_emetteur = {
        code_emetteur: this.emetteurSelectionne,
        nom: this.nom_ref.value,
        logo_fa: this.state.logo_fa,
        logo_color: this.state.logo_color,
        create_or_modif: this.state.createOrModif,
        }
    
        myFetch({url:'/enreg_emetteur',
            method: 'POST',
            body: JSON.stringify(don_emetteur),
            cibleElt: document.getElementById('root'), 
            fctSuccess: (val)=>{
              carteShow({code_emetteur: val.don_emetteur.code_emetteur});
              console.log('don_emetteur : ',val.don_emetteur);
            }, fctFail: (val)=>{

              alertFetchFail(val);

              if(val.no_token){
                App = ()=>{
                  loginShow();
                }
      
                App();
              }
            }
          })
     }
     
    }

    this.handleAnnuler = (e)=>{
      e.preventDefault();
      e.stopPropagation();
      document.body.click();
  
      carteShow({code_emetteur: session.code_emetteur_actuel});
    }

    //creation de la liste des emetteurs disponibles à ajouter
    if(propsIn.createOrModif){
      myFetch({url:'/liste_code_emetteur_dispo',
        method: 'GET',
        cibleElt: document.getElementById('root'), 
        fctSuccess: (jnLst)=>{
          let liste = [];
            for(let val of jnLst.liste_code_emetteur_dispo){
              //console.log(val.code);
              liste.push(<option value={val.code}>{val.code}</option>)

            }
            this.setState({liste_code_emetteur_dispo: liste});
            this.emetteurSelectionne = liste[0]?.props?.value;

        }, fctFail: (jnLst)=>{
          
          alertFetchFail(jnLst);

          if(jnLst.no_token){
            App = ()=>{
              loginShow();
            }
  
            App();
          }
        }
      })
        /*
      liste_code_emetteur_dispo
      <option value="MCH-223">MCH-223</option>
              */        
    }
    
  }

  

  selectEmetteurSelectionne(){
    let tg= this.codeEmetteurSelect_ref;
    this.emetteurSelectionne = tg[tg.selectedIndex].value;
  }

  componentDidMount(){
    //this.alreadyMounted();
    /*
    try{
      
    }catch(err){};
    */

    this.nom_ref.value = this.nom_init;
  }

  /*
  componentWillUnmount() {
    alert("finito");
  }
*/

  selectEmetteurOpt = (e)=>{
    e.stopPropagation();
     
    this.selectEmetteurSelectionne();
  }

  render(){


    return <div className="d-flex justify-content-center">
      
      <div className=" card mb-3 col-12">

        <div className="card-body">

        <div className=" card logo_preview mt-3">
          <div className="card-body p-1 mt-0">
            <div className='p-0 m-0 rounded' style={{backgroundColor: this.state.logo_color}}>
              <div className=' p-3'>
                <a className={`${this.state.logo_fa} text-white`} style={{fontSize:"2em"}}></a>
              </div>
            </div>
          </div>
        </div>

          <div className="pt-4 pb-2">
            <h5 className="card-title text-center pb-0 fs-4">{this.state.createOrModif?"Ajout":"Modification"} d'un véhicule</h5>
          </div>

          <form className="row g-3 needs-validation" novalidate>
            <div className="col-12">
              <label htmlFor="codeEmetteurSelect" className="form-label">Code émetteur</label>
                
              <div className='d-flex'>
                <div className="">
                  <select type="text" name="codeEmetteurSelect" className="form-select" id="codeEmetteurSelect" ref={(ref)=>{this.codeEmetteurSelect_ref = ref}} onChange={this.selectEmetteurOpt} required disabled={this.state.code_emetteur_init?true:false}>
                    {<>
                      {this.state.liste_code_emetteur_dispo}
                    </>}
                    
                  </select>
                </div>
                {this.state.code_emetteur_init?<></>:<div className='btn btn-danger mx-2' data-bs-toggle="modal" data-bs-target="#supprimerEmetteurModal"><i className='fa fa-trash'></i></div>}
                
              </div>
              <div className="invalid-feedback">Veuillez sélectionner le code de votre émetteur</div>
            </div>

            
            <div className="col-12">
              <label htmlFor="nomEmetteur" className="form-label">Nom émetteur</label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupPrepend">@</span>
                <input type="text" name="nomEmetteur" className="form-control" id="nomEmetteur"  ref={(ref)=>{this.nom_ref=ref}} required/>
                <div className="invalid-feedback">Veuillez nommer votre émetteur</div>
              </div>
            </div>
            <div className='col-12 mt-3'>
              <span className="d-flex justify-content-center"><label htmlFor="logo" className='form-label'>Logo</label></span>
              <div id="logo" className='py-4  border cont_logo_create' >
                <div className='px-4' style={{maxHeight:"500px", overflow:'scroll'}}>
                  <div className='col-12 cont_choix_logo'>
                    {[...Array(faIcons.length).keys()].map((i)=>{
                      //return <div className='d-flex justify-content-center col-3 my-1 col-sm-2 col-lg-1'><span className='btn btn-secondary m-2 p-2 p-md-3 p-lg-2 logo_create' onClick={(e)=>{this.setState({logo_fa:`${faIcons[i]}`});}}><a className={`${faIcons[i]} text-white`} style={{fontSize:"1.8em"}}></a></span></div>;
                      return <span className='col-3 my-1 col-sm-2 col-lg-1'><span className='btn btn-secondary m-2 p-2 p-md-3 p-lg-2 logo_create' onClick={(e)=>{this.setState({logo_fa:`${faIcons[i]}`});}}><a className={`${faIcons[i]} text-white`} style={{fontSize:"1.8em"}}></a></span></span>;
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 mt-3'>
              <span className="d-flex justify-content-center"><label htmlFor="couleur" className='form-label'>Couleur</label></span>
                            
              <div id="couleur" className='py-4  border cont_logo_create' >
                <div className='px-4' style={{maxHeight:"300px", overflow:'scroll'}}>
                  <div className='col-12 cont_choix_logo'>
                    {[...Array(couleurs.length).keys()].map((i)=>{
                      return <span className='col-3 my-1 col-sm-2 col-lg-1'><span className='btn m-2 p-4 p-md-5 p-lg-4 logo_create'  onClick={(e)=>{this.setState({logo_color: `${couleurs[i]}`})}} style={{backgroundColor: `${couleurs[i]}`}}></span></span>;
                    })}
                  </div>
                </div>
              </div>
            </div>
            

            
            <div className="d-flex justify-content-end col-12 py-3">
              <button className="btn btn-secondary mx-2" onClick={this.handleAnnuler}>Annuler</button>
              <button className="btn btn-primary" type="submit" onClick={this.handleSubmit}>{this.state.createOrModif?'Ajouter':'Modifier'}</button>
            </div>
            
          </form>

        </div>
        </div>

        <div className="modal fade" id="supprimerEmetteurModal" tabIndex="-1" aria-labelledby="supprimerEmetteurModalLabel" aria-hidden="true">

          <div className="modal-dialog">
            
            
            <div className="modal-content">
              <div className="alert alert-danger alert-dismissible fade show m-0 px-3" role="alert">
                Êtes-vous sûr de vouloir <strong>supprimer définitivement cet émetteur</strong> ?
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      
                <div className="mt-3 justify-content-end d-flex p-0 m-0">
                  <button type="button" className="btn btn-danger mx-2" data-bs-dismiss="modal">Non</button>
                  
                  <button id="supprimerEmetteurModalBtConfirmer" onClick={this.supprimerEmetteurConfirmer} type="button" className="btn btn-primary" data-bs-dismiss="modal">Confirmer</button>
                </div>
      
              </div>

            </div>
          
          </div>
    </div>



    </div>;
  }
}

function Login(){
  
    
    const inputPseudo = useRef(null);
    const inputPassword = useRef(null);

    const onCreerCompteHandler = (event)=>{
      event.preventDefault();
      creerCompteShow();
    }

    const onSubmitHandler = (event)=>{
      
      event.preventDefault();
      

      let log = {
        pseudo: inputPseudo?.current?.value || "",
        password: inputPassword?.current?.value || ""
      };

      
      
      myFetch({url:'/connexion',
        method: 'POST',
        body: JSON.stringify(log),
        cibleElt: document.getElementById('root'), 
        fctSuccess: (val)=>{
          console.log(val)
          ouvrirCompte(val);
        }
      })
      
    }
      
  
    return <>
    <div className="row justify-content-center m-0">
      <div className="pt-3 col-sm-7 d-flex col-11 flex-column align-items-center justify-content-center">
        <div className="card mb-3 pt-3">
          <div className="card-body">
            <form method="post" onSubmit={onSubmitHandler} className="row g-3 needs-validation" noValidate>

              <div className="col-12">
                <label htmlFor="tonPseudo" className="form-label">Pseudo</label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">@</span>
                  <input type="text" name="pseudo" className="form-control" id="tonPseudo" ref={inputPseudo} required/>
                  <div className="invalid-feedback">Veuillez insérer votre pseudo.</div>
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="tonPass" className="form-label">Mot de passe</label>
                <div className="input-group has-validation">
                  <span className="input-group-text bi bi-lock-fill" id="inputGroupPrepend"></span>
                  <input type="password" name="password" className="form-control" id="tonPass" ref={inputPassword} required/>
                  <div className="invalid-feedback">Veuillez insérer votre mot de passe!</div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"/>
                  <label className="form-check-label" htmlFor="rememberMe">Se souvenir de moi</label>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary w-100" id="btn_login">Connexion</button>
              </div>
              <div className="col-12">
                <button className="btn btn-secondary w-100" id="btn_creer_compte" onClick={onCreerCompteHandler}>Créer</button>
              </div>
              <div className="col-12">
                <p className="small mb-0">Vous n'avez pas de compte? <a href="#" onClick={onCreerCompteHandler}>Créer un compte</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  
}

function loginShow(byCookie = false){
  

  session.conxAsideNavEtat.reset({
    etat: {},
    code_emetteur: ""
  })
  carteHide(()=>{root.render(<Login/>)});

  if(byCookie){
    myFetch({url:'/connexion_by_cookie',
        method: 'GET',
        //cibleElt: document.getElementById('root'), 
        fctSuccess: (val)=>{
          ouvrirCompte(val);
        }, fctFail: (val)=>{

          alertFetchFail(val);

          if(val.no_token){
            App = ()=>{
              loginShow();
            }
  
            App();

          }
        }
      })
  }
  
  
}



function creerCompteShow(){
  carteHide(()=>(root.render(<CreerCompte fonctions={fonctions} />)))
}

function modifierCompteShow({dataCompte={}}){
  carteHide(()=>(root.render(<CreerCompte fonctions={fonctions} creerModif={false} dataCompte={dataCompte} />)));
}

document.querySelector("#accueilBt").addEventListener('click', function(e){
  e.stopPropagation();
  document.body.click();

  App();
})

/*
document.querySelector("#odometreEvolutionBt").addEventListener('click', function(e){
  odometreEvolution();
});

document.querySelector("#fuelEvolutionBt").addEventListener('click', function(e){
  fuelEvolution();
});

document.querySelector("#carteBt").addEventListener('click', (e)=>{
  App();
})
*/
document.querySelector("#nousContacterBt").addEventListener('click', (e)=>{
  e.stopPropagation();
  document.body.click();

  nousContacterShow();
})

document.querySelector("#parametreBt").addEventListener('click', (e)=>{
  e.stopPropagation();
  document.body.click();

  session.parametreCompte();
  
})

document.querySelectorAll(".fullScreenBt").forEach(item=>item.addEventListener('click', (e)=>{
    e.stopPropagation();
    document.body.click();
    
    if(isOnFullScreen())
      outFullScreen();
    else
      inFullScreen(session.eltFullScreen);
  })
);

document.querySelectorAll(".downloadBt").forEach(item=>item.addEventListener('click', (e)=>{
  e.stopPropagation();
  document.body.click();
  downloadShow();
  
})
);



document.querySelector("#deconnexionBt").addEventListener('click', (e)=>{
  e.stopPropagation();
  document.body.click();

  deconnexion();
})





/*
  var sourceVector = new Vector({
    features: [
        new Feature({
            geometry: new Point(point1)
        }),
        new Feature({
            geometry: new Point(point2)
        }),
        new Feature({
            geometry: new Point(point3)
        })
    ]
});

  
  var layer = new Vector({
    source: sourceVector,
});
*/
//leMap.setCenter(fromLonLat([2.1844, 41.3834]),);
//map.addLayer(layer);

/*

const imageLayer = new ol.layer.VectorImage({
    background: '#1a2b39',
    imageRatio: 2,
    source: new ol.source.Vector({
        url
    })
})
*/
  //map.addLayer(stamen)


  //teste carte show
  

export default App;
export {root};
