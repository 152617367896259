import React from 'react';

class Fuel extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        data: {
          titre: 'Récupération des données Fuel',
          donnees: []
        }
      }

      this.fonctions = props.fonctions;
  
      this.code_emetteur = props.code_emetteur;
  
      this.donPage = {
        code_emetteur : this.code_emetteur,
        //dateDebut : '2023-01-01',
        //dateFin : '2023-12-23',
  
      }
  
      this.refreshPage = (numPage)=>{
        this.fonctions.refreshPageGlob('carburant', this, numPage);
      }
  
      
      this.gotoPage = (e)=>{
        this.fonctions.gotoPageGlob(e, this);
      }
     
      this.refreshPage(1);
  
      this.locationFuel = (e)=>{
        const idx = parseInt(e.target.id.substring(5));
        //console.log('this.state.data.donnees[idx] -> ', this.state.data.donnees[idx]);
        //console.log('idx -> ', parseFloat(this.state.data.donnees[idx].qte));
        let emetteur_etranger = {
          code: this.code_emetteur,
          vol_gp:{
            lat: this.state.data.donnees[idx].lat,
            long: this.state.data.donnees[idx].long,
          },
          logo_fa: "fa fa-gas-pump",
          logo_color: parseFloat(this.state.data.donnees[idx].qte)>=0?"#3B71CA":"#DC4C64",
          qteFuel: this.state.data.donnees[idx].qte,
          //etat: this.emetteurEtranger.etat??{}, //(#3B71CA)
        };
  
        this.fonctions.carteShow({emetteur_etranger: emetteur_etranger});
  
      }
      
    }
    
  
    render(){
  
      const{data} = this.state
      
  
      return <>
      <div className="pagetitle mt-3 ms-3">
        <h5 className="card-title">{data.titre}</h5>
        
        
      </div>
      <table className="table table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">Durée</th>
        <th scope="col">Qte</th>
        <th scope="col">Lat | Long</th>
        
  
      </tr>
    </thead>
    <tbody>
      { (function({locationFuel, fonctions}) {
        var content = []
        var don, floatQte;
        for(var i=0; i<data.donnees.length; i++){
          
          don = data.donnees[i]
          floatQte = parseFloat(don.qte);
          content.push(<tr>
            <th scope="row">{(data.numPage-1)*data.donParPage+i+1}</th>
            <td>{fonctions.formatDateTime(new Date(don.date))}</td>
            <td>{don.duree}</td>
            <td><span className={`badge${floatQte>=0?" bg-primary":" bg-danger"} badge-number rounded-pill`} style={{fontSize: "0.85em"}} >{don.qte}</span></td>
            <td><button className={`btn${floatQte>=0?" btn-primary":" btn-danger"}`} id={`fuel_${i}`} onClick={locationFuel}>{don.lat} | {don.long}</button></td>
          </tr>)
        }
        return content
      }
      )({locationFuel: this.locationFuel, fonctions: this.fonctions})
      }
    
      
    </tbody>
  </table>
  <div className="d-flex justify-content-center">
   
      <nav aria-label="Page">
        {this.fonctions.pagination(data.numPage, data.nbrPage, this.gotoPage)}
      </nav>
    
    
    </div>
  </>
    }
  }

export default Fuel