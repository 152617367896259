import {useRef, useState} from 'react';

function CreerCompte({fonctions, creerModif=true, dataCompte={}}){

    const is = {set:{}};
    [is.pseudo, is.set.pseudo] = useState(dataCompte?.pseudo??"");
    [is.nom, is.set.nom] = useState(dataCompte?.nom??"");
    [is.postnom, is.set.postnom] = useState(dataCompte?.postnom??"");
    [is.prenom, is.set.prenom] = useState(dataCompte?.prenom??"");
    [is.date_naissance, is.set.date_naissance] = useState(dataCompte?.date_naissance??"");
    [is.lieu_naissance, is.set.lieu_naissance] = useState(dataCompte?.lieu_naissance??"");
    [is.adresse, is.set.adresse] = useState(dataCompte?.adresse??"");
    [is.tel, is.set.tel] = useState(dataCompte?.tel??"");
    [is.tel2, is.set.tel2] = useState(dataCompte?.tel2??"");
    [is.mail, is.set.mail] = useState(dataCompte?.mail??"");
    [is.password, is.set.password] = useState(dataCompte?.password??"");
    [is.password_conf, is.set.password_conf] = useState(dataCompte?.password_conf??"");
    [is.password_actu, is.set.password_actu] = useState(dataCompte?.password_actu??"");
  
    const inputRef = {
      pseudo: useRef(null),
      nom: useRef(null),
      postnom: useRef(null),
      prenom: useRef(null),
      date_naissance: useRef(null),
      lieu_naissance: useRef(null),
      adresse: useRef(null),
      tel: useRef(null),
      tel2: useRef(null),
      mail: useRef(null),
      password: useRef(null),
      password_conf: useRef(null),
      password_actu: useRef(null),
    }
  
    const handleOnChangeInput = (event)=>{
      event.preventDefault();
      is.set[event.target.name](event.target.value);
      //setValue({ [event.target.name] : event.target.value })
    };
  
    const writeInput = (target, input)=>{
      
  
      if(input.disabled){
        input.disabled = false;
        input.required = true;
  
        target.classList.remove("bi-three-dots-vertical");
        target.classList.add("bi-x");
  
      }else{
        input.disabled = true;
        input.required = false;
  
        target.classList.remove("bi-x");
        target.classList.add("bi-three-dots-vertical");
  
        is.set[input.name](dataCompte?dataCompte[input.name]??"":"");
        //console.log(input.name, ' : ', dataCompte?dataCompte[input.name]??"":"");
  
        //is.set.password("cola");
      }
    }
    
    const writeInputPrec = (e)=>{
      e.preventDefault();
      const target = e.target;
      const input = target.previousSibling;
      
      writeInput(target, input);
  
      
    }
  
    const writeInputPassword = (e)=>{
      e.preventDefault();
      
      writeInput(e.target, inputRef.password.current);
      writeInput(e.target, inputRef.password_conf.current);
    }
  
    const onSubmitHandler = (event)=>{
      event.preventDefault();
      
      let personne = {};
      if(!inputRef.pseudo?.current?.disabled) personne.pseudo = inputRef.pseudo?.current?.value || "";
      if(!inputRef.nom?.current?.disabled) personne.nom = inputRef.nom?.current?.value || "";
      if(!inputRef.postnom?.current?.disabled) personne.postnom = inputRef.postnom?.current?.value || "";
      if(!inputRef.prenom?.current?.disabled) personne.prenom = inputRef.prenom?.current?.value || "";
      if(!inputRef.date_naissance?.current?.disabled) personne.date_naissance = inputRef.date_naissance?.current?.value || "";
      if(!inputRef.lieu_naissance?.current?.disabled) personne.lieu_naissance = inputRef.lieu_naissance?.current?.value || "";
      if(!inputRef.adresse?.current?.disabled) personne.adresse = inputRef.adresse?.current?.value || "";
      if(!inputRef.tel?.current?.disabled) personne.tel = inputRef.tel?.current?.value || "";
      if(!inputRef.tel2?.current?.disabled) personne.tel2 = inputRef.tel2?.current?.value || "";
      if(!inputRef.mail?.current?.disabled) personne.mail = inputRef.mail?.current?.value || "";
      if(!inputRef.password?.current?.disabled) personne.password = inputRef.password?.current?.value || "";
      if(!inputRef.password_conf?.current?.disabled) personne.password_conf = inputRef.password_conf?.current?.value || "";
      if(!inputRef.password_actu?.current?.disabled) personne.password_actu = inputRef.password_actu?.current?.value || "";
  
      fonctions.myFetch({url:`/${creerModif?"creer":"modifier"}_compte`, 
        method: 'POST',
        cibleElt: document.getElementById('root'), 
        body: JSON.stringify(personne),
        fctSuccess: (val)=>{
            fonctions.ouvrirCompte(val);
        }, fctFail: (val)=>{
          let msg = "";
          for(let key in val.rubrique){
            msg += key + " : " + val.rubrique[key] + "\n";
          }
          val.msg = msg;
          fonctions.alertFetchFail(val);
  
          //console.log(val.rubrique);
        }
      })
      
  
      
    }
  
    return <>
        <div className="pagetitle d-flex justify-content-center">
          <h5 className='card-title'>{creerModif?"Créer":"Modifier"} Compte</h5>
          
        </div>
        <div className="d-flex justify-content-center">
          <div className="col-md-8">
            <div className="card p-4">
              <form onSubmit={onSubmitHandler} method="post" className="php-email-form">
                <div className="row gy-4">
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                    <input value={is.pseudo} onChange={handleOnChangeInput} type="text" name="pseudo" ref={inputRef.pseudo} className="form-control" placeholder="Pseudo" required={creerModif} disabled={!creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-person" id="inputGroupPrepend"></span>
                    <input value={is.nom} onChange={handleOnChangeInput} type="text" name="nom" ref={inputRef.nom} className="form-control" placeholder="Nom" required={creerModif} disabled={!creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-emoji-sunglasses" id="inputGroupPrepend"></span>
                    <input value={is.postnom} onChange={handleOnChangeInput} type="text" name="postnom" ref={inputRef.postnom} className="form-control" placeholder="Postnom" disabled={!creerModif} required={creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-emoji-wink" id="inputGroupPrepend"></span>
                    <input value={is.prenom} onChange={handleOnChangeInput} type="text" name="prenom" ref={inputRef.prenom} className="form-control" placeholder="Prénom" disabled={!creerModif} required={creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-envelope" id="inputGroupPrepend"></span>
                    <input value={is.mail} onChange={handleOnChangeInput} type="email" className="form-control" name="mail" ref={inputRef.mail} placeholder="Email" disabled={!creerModif} required={creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className="col-md-12 my-0 pt-2 px-4">
                        <label htmlFor="date_naissance" className="form-label text-secondary">Date de naissance</label>
                      </div>
                      <div className="col-md-12 input-group my-0 py-0 d-flex justify-content-center">
                        <span className="input-group-text bi bi-calendar" id="inputGroupPrepend"></span>
                        <input value={is.date_naissance} onChange={handleOnChangeInput} type="date" className="form-control" name="date_naissance" ref={inputRef.date_naissance} id="date_naissance" placeholder="Date de naissance" required={creerModif} disabled={!creerModif} />
                        {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                      </div>
                    </div>
                    
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-geo" id="inputGroupPrepend"></span>
                    <input value={is.lieu_naissance} onChange={handleOnChangeInput} type="text" className="form-control" name="lieu_naissance" ref={inputRef.lieu_naissance} placeholder="Lieu de naissance" required={creerModif} disabled={!creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-telephone" id="inputGroupPrepend"></span>
                    <input value={is.tel} onChange={handleOnChangeInput} type="tel" className="form-control" name="tel" ref={inputRef.tel} placeholder="Tel" required={creerModif} disabled={!creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-phone" id="inputGroupPrepend"></span>
                    <input value={is.tel2} onChange={handleOnChangeInput} type="tel" className="form-control" name="tel2" ref={inputRef.tel2} placeholder="Tel 2" disabled={!creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-house" id="inputGroupPrepend"></span>
                    <textarea onChange={handleOnChangeInput} className="form-control" name="adresse" ref={inputRef.adresse} rows="2" placeholder="Adresse" required={creerModif} disabled={!creerModif} >{is.adresse}</textarea>
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPrec} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-lock" id="inputGroupPrepend"></span>
                    <input value={is.password} onChange={handleOnChangeInput} type="password" className="form-control" name="password" ref={inputRef.password} placeholder="Mot de passe" disabled={!creerModif} />
                    {creerModif?<></>:<span className="input-group-text btn btn-secondary bi bi-three-dots-vertical" id="inputGroupPrepend" onClick={writeInputPassword} ></span>}
                  </div>
  
                  <div className="col-md-12 input-group">
                    <span className="input-group-text bi bi-lock-fill" id="inputGroupPrepend"></span>
                    <input value={is.password_conf} onChange={handleOnChangeInput} type="password" className="form-control" name="password_conf" ref={inputRef.password_conf} placeholder="Mot de passe conf." disabled={!creerModif} />
                  </div>
  
                  {creerModif?<></>:<>
                    <div className="my-4"></div>
                    <div className="col-md-12 input-group">
                      <span className="input-group-text bi bi-lock-fill" id="inputGroupPrepend"></span>
                      <input type="password" className="form-control" name="password_actu" ref={inputRef.password_actu} placeholder="Actuel mot de passe"/>
                    </div>
                  </>}
  
                  <div className="col-md-12 text-center">
                    <button className="btn btn-primary" type="submit">{creerModif?"Créer":"Modifier"} Compte</button>
                  </div>
  
                </div>
              </form>
            </div>
  
          </div>
        </div>
        
      </>
  }

  export default CreerCompte